import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { MapObject, MapObjectStorageService } from '../map-object';

@Injectable({
  providedIn: 'root'
})
export class InspectionGuard implements CanActivate {
  constructor(
    private objectStorage: MapObjectStorageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let
      id: string | null = next.queryParamMap.get('objectId'),
      object: MapObject | undefined;

    if(id != null) {
      object = this.objectStorage.getObject(id);
    }

    return !!object;
  }
}
