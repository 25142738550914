import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy, SimpleChanges, OnChanges, EmbeddedViewRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appIfLocation]'
})
export class IfLocationDirective implements OnChanges, OnInit, OnDestroy {
  @Input('appIfLocation')
  public path: string = '';

  private subscription: Subscription;

  private view: EmbeddedViewRef<any> | null = null;

  constructor(
    private container: ViewContainerRef,
    private location: Location,
    private router: Router,
    private template: TemplateRef<any>
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes.path) {
      this.createOrClear();
    }
  }

  ngOnInit() {
    this.subscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.createOrClear());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private createOrClear(): void {
    const negation: boolean = this.path[0] === '!';
    const newPath: string = this.path[0] === '!' ? this.path.slice(1) : this.path;
    let equal: boolean = this.location.isCurrentPathEqualTo(newPath);

    if(negation) {
      equal = !equal;
    }

    if(equal) {
      if(this.view == null) {
        this.view = this.container.createEmbeddedView(this.template);
      }
    }
    else {
      this.view = null;
      this.container.clear();
    }
  }
}
