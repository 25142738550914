import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}

  // https://github.com/jquery/jquery/blob/master/src/core.js
  public extend(...args: any[]): any {
    const isObject = (arg: any): boolean => {
      return arg !== null && Object.prototype.toString.call(arg) === '[object Object]';
    };

    const target: any = args[0];

    for(let i = 1; i < args.length; i++) {
      const source: any = args[i];

      if(source != null) {
        for(let key in source) {
          const
            src: any = target[key],
            copy: any = source[key];

          if(target === copy) {
            continue;
          }

          const copyIsArray: boolean = Array.isArray(copy);

          if(isObject(copy) || copyIsArray) {
            const clone: any = copyIsArray
              ? src && Array.isArray(src) ? src : []
              : isObject(src) ? src : {};

            target[key] = this.extend(clone, copy);
          }
          else if(copy !== undefined) {
            target[key] = copy;
          }
        }
      }
    }

    return target;
  }

  // https://stackoverflow.com/a/2117523
  public getUniqueId(): string {
    const id: string =
      'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0;

        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });

    return id + '-' + Date.now().toString(16);
  }

  public getUUID(): string | undefined {
    if(window.device && window.device.uuid != null) return window.device.uuid;
    else return undefined;
  }
}
