import { Component, OnInit } from '@angular/core';

import { TreeService } from './tree.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [TreeService]
})
export class TreeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
