import { Component, OnDestroy, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/services/map-object/storage.service';

interface CurrentState {
  key: string;
  value: string;
}

@Component({
  selector: '',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class MapComponent implements OnInit, OnDestroy {

  constructor(
    private mapStorageService: StorageService,
  ) {
    this.mapStorageService.objectsUpdated.next();
  } 

  ngOnInit() {}

  ngOnDestroy() {}
}
