import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { MapState } from './map-state';

import { StorageService } from '../../services/storage.service';

interface ChangedProp {
  key: string;
  value: any;
}

interface CurrentState {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class MapStateService {
  private state: MapState =  new MapState();

  private stateSource: Subject<ChangedProp>;

  public state$: Observable<ChangedProp>;

  public currentState: CurrentState;

  public typeLib: Object[];

  public object_types: any[];

  constructor(
    private storageService: StorageService
  ) {
    this.typeLib = this.storageService.get('typeLib');
    this.object_types = this.storageService.get('object_types');
    this.stateSource = new Subject();
    this.state$ = this.stateSource.asObservable();
    this.currentState = {
      key: '',
      value: ''
    }
  }
  
  public changeState(key: string, value: any): void {
    switch(key) {
      case 'plans':
      case 'mapVisible':
      case 'layersVisible':
        if(key === 'plans' || key === 'mapVisible') {
          this.state.mapVisible = value;
          this.stateSource.next({key: 'mapVisible', value});
        }

        if(key === 'plans' || key === 'layersVisible') {
          this.state.layersVisible = value;
          this.stateSource.next({key: 'layersVisible', value});
        }
      break;

      case 'elements':
        this.typeLib.forEach((type:any) => {
          this.state.elementsVisibility[type.id] = value;
          this.stateSource.next({key: type.id, value});
        })
        // if(key === 'elements' || key === 'baes') {
        //   this.state.elementsVisibility.baes = value;
        //   this.stateSource.next({key: 'baes', value});
        // }

        // if(key === 'elements' || key === 'es') {
        //   this.state.elementsVisibility.es = value;
        //   this.stateSource.next({key: 'es', value});
        // }
      break;

      default:
        // if(this.state[key] != null) {
        //   this.state[key] = value;
        //   this.stateSource.next({key, value});
        // }
        //this.state[key] = value;
        this.state.elementsVisibility[key] = value;
        this.stateSource.next({key, value});
    }
  }

  public getState(): MapState {
    return Object.assign({}, this.state);
  }

  public addLayerToCache(type: string, layer: any): void {
    return this.state.cache[type] = layer;
  }

  public setCurrentState(key: string, value: any): void {
    this.currentState = {
      key: key,
      value: value
    }
  }

  public getCurrentState(): CurrentState {
    return this.currentState;
  }
}
