import { Injectable } from '@angular/core';

import { TreeNodeComponent } from './tree-node/tree-node.component';

@Injectable()
export class TreeService {
  constructor() {}

  private isChildrenChecked(node: TreeNodeComponent): boolean {
    return node.children.some((child) => child.checked === true);
  }

  private updateChildren(node: TreeNodeComponent, checked: boolean) {
    node.children.forEach((child) => {
      child.checked = checked;
      this.updateChildren(child, checked);
    });
  }

  private updateParents(node: TreeNodeComponent): void {
    const parent: TreeNodeComponent | null = node.parent;

    if(parent != null) {
      parent.checked = this.isChildrenChecked(parent);

      this.updateParents(parent);
    }
  }

  public updateNodes(node: TreeNodeComponent): void {
    const checked: boolean = node.checked;

    this.updateChildren(node, checked);
    this.updateParents(node);
  }
}
