import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JsonValue } from '../../types/form-field';
import { NotificationService } from '../notification.service';
import { Observable, Observer, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    protected http: HttpClient,
    private notification: NotificationService
  ) {}

  public async getOwnerEmail(appId: string): Promise<JsonValue[]> {
    // this.notification.notify('error', 'DANS LE FILTRE ' + appId);
    return this.http
      .get<JsonValue[]>(
        `https://api.kartes.io/api/getusersbyapp?app_id=${appId}&testmode=1`
      )
      .pipe(
        tap(
          (_event) => {
            // Succeeds when there is a response; ignore other events,
          },
          (error) => {
            console.log(error);
          }
        )
      )
      .toPromise();
  }

//  public getObjectId(hash: string): Observable<any> {
/*    return this.http.get(
      `https://apiv2.kartes.io/kartes/object/aget/{"conditions":{"hash":"${hash}"}}/`
    );*/
//  }
}
