import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';

import {
  Observable,
  Subject
} from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  private _close = new Subject<boolean>();

  private closeEnd = new Subject<void>();

  private confirmed: boolean = false;

  private modalInstance: any;

  public close$: Observable<boolean> = this._close.asObservable();

  public closeEnd$: Observable<void> = this.closeEnd.asObservable();

  public text: string = '';

  constructor(
    private elem: ElementRef
  ) {}

  ngOnInit() {
    const modalElem: HTMLElement =
      this.elem.nativeElement.querySelector('.modal');

    this.modalInstance = M.Modal.init(modalElem, {
      onCloseStart: () => this._close.next(this.confirmed),
      onCloseEnd: () => this.closeEnd.next()
    });

    this.open();
  }

  ngOnDestroy() {
    this.modalInstance.destroy();
  }

  public close(confirmed: boolean = false): void {
    this.confirmed = confirmed;
    this.modalInstance.close();
  }

  public open(): void {
    this.modalInstance.open();
  }
}
