import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LogoService {
  public logoUpdated: Subject<string> = new Subject();

  constructor(private storageService: StorageService) {}

  public updateLogo(url: string): void {
    this.setStorageUrl(url);
    this.logoUpdated.next(url);
  }

  public getLogoUrl(): string | null {
    const url: string = this.storageService.get('userLogo');

    return url ? url : null;
  }

  public setStorageUrl(url: string): void {
    this.storageService.set('userLogo', url);
  }
}
