import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { concatAll } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { FileUploadService } from './file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class ImageTransferService {

  constructor(
    private fileUpload: FileUploadService,
  ) {}

  public uploadImage(file: string): Observable<any> {
    return this.fileUpload.upload(file).pipe();
  }
}
