import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmService } from 'src/app/core/services/confirm/confirm.service';
import { MapStateService } from 'src/app/core/services/map';
import {
  BaesMapObjectFactoryService,
  BaesMapObjectFormData,
  MapObject,
  MapObjectDataTransferService,
  MapObjectStorageService,
} from 'src/app/core/services/map-object';
import { UtilsService } from 'src/app/core/services/utils.service';
import { RoutingHistoryService } from 'src/app/routing-history';
import { I18nService}  from '@i18n';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: '',
  templateUrl: './baes-object-editor.component.html',
  styleUrls: ['./baes-object-editor.component.scss'],
  host: {
    '[className]': '"app-page"',
  },
})
export class BaesObjectEditorComponent implements OnInit {
  public mapObject: MapObject | undefined;

  public backButtonRoute: string | null;

  public typeLibId: any;

  public edit = false;

  public lastId: string | null;

  constructor(
    private confirm: ConfirmService,
    private location: Location,
    private mapStateService: MapStateService,
    private objectDataTransfer: MapObjectDataTransferService,
    private objectFactory: BaesMapObjectFactoryService,
    private objectStorage: MapObjectStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilsService,
    private history: RoutingHistoryService,
    public i18n: I18nService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    const id: string | null = this.route.snapshot.queryParamMap.get('objectId');

    this.typeLibId = this.route.snapshot.queryParamMap.get('typeLibId');

    if (id != null) {
      const mapObject: MapObject | undefined = this.objectStorage.getObject(id);

      if (mapObject != null) {
        this.mapObject = this.utils.extend({}, mapObject);
      }
    }

    this.edit = Boolean(id);

    this.backButtonRoute = this.route.snapshot.queryParamMap.get('back');
  }


  public onSubmitted(data: BaesMapObjectFormData): void {

    if (this.mapObject != null) {
      const obj: MapObject | null = this.objectStorage.updateObject(
        this.mapObject,
        data
      );

      if (obj != null) {
        this.objectDataTransfer.send(obj).subscribe();
        this.lastId = obj.__id;
      }

    } else {
      const coords: string | null =
        this.route.snapshot.queryParamMap.get('coords');
      // const [country, city, building, part, floor] = this.mapStateService.getState().defaultCurrentLayer.split('.');
      const [country, city, building, part, floor] =
        '....'.split('.');

      if (coords != null) {
        const obj: MapObject = this.objectFactory.create(
          data,
          coords,
          country,
          city,
          building,
          part,
          floor
        );
        this.lastId = obj.__id;
        this.typeLibId ? (obj.id_object_type = this.typeLibId) : null;
        this.objectStorage.addObject(obj);
        this.objectDataTransfer.send(obj).subscribe();
      }
    }


    let newRoute = this.storageService.get('newRoute');

    if (this.route.snapshot.queryParamMap.get('backToMap')) {
      this.router.navigate(['/map']);
    } else if( newRoute  && this.lastId){
      this.router.navigate(['/'+newRoute], {
        queryParams: { objectId: this.lastId},
      });
    }
    else{
      this.location.back();
    }
  }

  public onBack(): void {
    this.history.back();
  }

  public getTypeLibId(): string {
    return this.mapObject ? this.mapObject.id_object_type : this.typeLibId;
  }

  public onInterventionClick(): void {
    if (this.mapObject) {
      this.router.navigate(['/baes-intervention'], {
        queryParams: { objectId: this.mapObject.__id },
      });
    }
  }

  public onControlClick(): void {
    if (this.mapObject) {
      this.router.navigate(['/baes-inspection'], {
        queryParams: { objectId: this.mapObject.__id },
      });
    }
  }
}
