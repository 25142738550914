import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from './authorization/authorization.service';
import { UtilsService } from './utils.service';

interface ResponseData {
  filename: string;
  preview: string;
}

@Injectable({
  providedIn: 'root'
})
export class MobileUploaderService {
  constructor(
    private auth: AuthorizationService,
    private utils: UtilsService,
    private zone: NgZone
  ) {}

  private getFileName(filePath: string): string {
    let
      start: number = filePath.lastIndexOf('/') + 1,
      end: number = filePath.lastIndexOf('?');

    return filePath.slice(start, end > -1 ? end : Infinity); 
  }

  public upload(url: string, filePath: string): Observable<ResponseData> {
    return new Observable((observer) => {
      const
        options = new FileUploadOptions(),
        ft = new FileTransfer(),
        token: string | null = this.auth.getAuthorizationToken();

      options.fileKey = 'ufile';
      options.fileName = this.getFileName(filePath);
      options.mimeType = 'multipart/form-data';
      options.params = {uuid: this.utils.getUUID()};
      options.headers = {Authorization: token};

      ft.upload(
        filePath,
        url,
        (result: any) => {
          const response: ResponseData = JSON.parse(result.response);

          this.zone.run(() => {
            observer.next(response);
            observer.complete();
          });
        },
        (error: any) => {
          this.zone.run(() => {
            observer.error(error)
          });
        },
        options
      );
    });
  }
}
