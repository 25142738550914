import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Directive({
  selector: '[matAutocomplete][appSuggestionFilter]'
})
export class SuggestionFilterDirective implements OnInit {
  @Input('appSuggestionFilter')
  public inputSuggestions: any[] = [];

  @Output('appSuggestionFilterChange')
  public filteredSuggestions: EventEmitter<any[]> = new EventEmitter<any[]>();

  private allSuggestions: any[] = [];

  constructor(
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.allSuggestions = this.inputSuggestions.slice();

    fromEvent(this.elem.nativeElement, 'input').pipe(
      map((e: Event) => (<HTMLInputElement>e.target).value),
      debounceTime(300),
      distinctUntilChanged()
    )
    .subscribe({
      next: (value: string) => this.filteredSuggestions.next(this.filterSuggestions(value))
    });
  }

  private filterSuggestions(value: string): any[] {
    const val: string = value.toLowerCase();

    return this.allSuggestions.filter((item) => item.toLowerCase().includes(val));
  }
}
