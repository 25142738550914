export const SERVER_TEST_URL = 'https://api.test.kartes.io';
export const SERVER_PROD_URL = 'https://api.kartes.io';
export const UPDATE_URL = '/api/version';
export const UPDATE_URL_TEST = '/assets/scripts/json/updates-sample.json';
export const VERSION_URL = '/api/version';
export const ERROR_URL = '/api/error';
export const LOGIN_URL = '/api/login';
export const SYNC_FROM_URL = '/api/syncfrom';
export const FORM_URL = '/api/form';
export const FILTERS_URL = '/api/filters';
export const FILTERS_USER_URL = '/api/syncfilters';
export const GET_LANGUAGES= '/api/getlanguages';
export const GET_TRANSLATIONS= '/api/gettranslations';
export const COMMENT_URL = '/api/comments';
export const SEND_GPS_URL= '/api/sendgps';
