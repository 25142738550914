import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[appIfPermission]' 
})
export class IfPermissionDirective {
  @Input()
  public set appIfPermission(permission: string) {
    
    if(this.permissionService.hasPermission(permission)) {
      this.viewContainer.createEmbeddedView(this.template);
    }
    else {
      this.viewContainer.clear();
    }
  }

  constructor(
    private permissionService: PermissionService,
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
