import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector
} from '@angular/core';

import { ConfirmComponent } from './confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {
  private componentRef: ComponentRef<ConfirmComponent> | null = null;

  constructor(
    private appRef: ApplicationRef,
    private factoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  private destroyConfirmComponent(): void {
    if(this.componentRef !== null) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  public open(
    text: string = '', closeCallback?: (confirmed: boolean) => void
  ): void {
    if(this.componentRef === null) {
      const factory =
        this.factoryResolver.resolveComponentFactory(ConfirmComponent);

      this.componentRef = factory.create(this.injector);

      this.componentRef.instance.text = text;
      this.componentRef.instance.close$.subscribe((confirmed) => {
        if(closeCallback !== undefined) {
          closeCallback(confirmed);
        }
      });
      this.componentRef.instance.closeEnd$.subscribe(() => {
        this.destroyConfirmComponent();
      });

      this.appRef.attachView(this.componentRef.hostView);
      document.body.appendChild(this.componentRef.location.nativeElement);
    }
  }

  public close(confirmed: boolean = false): void {
    if(this.componentRef !== null) {
      this.componentRef.instance.close(confirmed);
    }
  }
}
