import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { LatLng } from 'src/app/core/services/gps';
import { MapLayersService } from './map-layers.service';

@Injectable({
  providedIn: 'root',
})
export class MapActionsService {
  public findGeolocation: Subject<void>;

  public findGeolocation$: Observable<void>;

  private watchGeolocation: Subject<void>;

  public watchGeolocation$: Observable<void>;

  private setMapView: Subject<LatLng>;

  public setMapView$: Observable<LatLng>;

  private updateKmls: Subject<void>;

  public updateKmls$: Observable<void>;

  private removeKmls: Subject<void>;

  public removeKmls$: Observable<void>;

  private updateKmlsLocation: Subject<void>;

  public updateKmlsLocation$: Observable<void>;

  constructor(private layersService: MapLayersService) {
    this.findGeolocation = new Subject<void>();
    this.findGeolocation$ = this.findGeolocation.asObservable();

    this.watchGeolocation = new Subject<void>();
    this.watchGeolocation$ = this.watchGeolocation.asObservable();

    this.updateKmlsLocation = new Subject<void>();
    this.updateKmlsLocation$ = this.updateKmlsLocation.asObservable();

    this.setMapView = new Subject<LatLng>();
    this.setMapView$ = this.setMapView.asObservable();

    this.updateKmls = new Subject<void>();
    this.updateKmls$ = this.updateKmls.asObservable();

    this.removeKmls = new Subject<void>();
    this.removeKmls$ = this.removeKmls.asObservable();
  }

  public locate(): void {
     this.watchGeolocation.next();
    this.findGeolocation.next();
    this.updateKmlByGeolocation();
    this.updateKmlsVisibility();
  }

  public updateKmlByGeolocation(): void {
    this.updateKmlsLocation.next();
  }


  public setMapViewCoords(coords: LatLng): void {
	  console.log("centrage carte");
    this.setMapView.next(coords);
  }

  public removeKmlsLayers(): void {
    this.removeKmls.next();
  }

  public updateKmlsVisibility(): void {
    this.updateKmls.next();
  }
}
