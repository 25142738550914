import { Inspection } from './inspection';
import { MapObject } from 'src/app/core/services/map-object';
import { equalParamsAndUrlSegments } from '@angular/router/src/router_state';

export class QualityChecker {
  public typeLib: any | undefined;

  constructor() {
   
  }

  // statuses 
  // 0 - warning (orange square)
  //-1 - ko (red rhomb)
  // 1 - ok (green circle) @default

  public getColor(inspection: Inspection | null): string {
    let color: string = 'orange';

    if(inspection != null) {
      if(inspection.status === 'Conforme') {
        color = 'green';
      }
      else if(inspection.status === 'Non conforme') { 
        color = 'red';
      }
    }

    return color;
  }

  public getPinColor(object: MapObject | null): string {
    let color: string = 'green';
 
    if (object) {
      let status: string | undefined = object.status;

      if (status === '0') {
        color = 'orange';
      } else if (status === '-1') {
        color = 'red';
      } else {
        color = 'green';
      }
    }

    return color;
  }

  public getPinShape(object: MapObject | null): string {
    let shape: string = 'circle';

    if (object) {
      let status: string | undefined = object.status;

      if (status === '0') {
        shape = 'square';
      } else if (status === '-1') {
        shape = 'rhomb';
      } else {
        shape = 'circle';
      }
    }
    return shape;
  }

  public getPinState(object: MapObject | null): string {
    let state: string = 'ok';

    if (object) {
      let status: string | undefined = object.status;

      if (status === '0') {
        state = 'warning';
      } else if (status === '-1') {
        state = 'ko';
      } else {
        state = 'ok';
      }
    }
    return state;
  }

  //[{"id":"1","title":"BAES","id_inspection":"999","fa":"fa fa-sign-out-alt"},{"id":"4","title":"Bloc phare","id_inspection":"999", "fa":"far fa-lightbulb"}]
}

