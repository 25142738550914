import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { JsonValue } from '../types/form-field';
import { NotificationService } from './notification.service';
import { Observable, Observer, of, Subject } from 'rxjs';
import {
  SERVER_PROD_URL,
  SERVER_TEST_URL,
  GET_LANGUAGES,
  GET_TRANSLATIONS,
} from '../../../server-url';




@Injectable({
  providedIn: 'root'
})

export class I18nService {

  public current_lang:string;
  public translations:JsonValue[];
  public languages:any[];

  constructor(
    protected http: HttpClient,
    private notification: NotificationService,
    private storageService: StorageService,
    ) {

    this.getLanguages();
    this.getCurrentLang();
    this.initTranslations();

    console.log("langue courante "+this.current_lang);

  }

  public t(lib: string): string{
    if(!this.current_lang){
      console.log('langue par defaut non definie');
      this.getCurrentLang();
    } 

    if(!this.current_lang){
      return lib;
    }

    if(!this.translations || this.translations[this.current_lang]){
      this.setLang(this.current_lang);
    }

    if(this.translations && this.translations[lib]){
      return this.translations[lib];
    }

    return lib;

  }

  public setLang(lang:string):void{
    console.log("Set Lang to "+lang);
     this.storageService.set('current_lang',lang);
     let translation_collection = this.storageService.get('translations');
     if(!translation_collection) {
        console.log("pas de traduction trouvée");
        return; 
     }
     if(translation_collection[lang]){
       this.translations = translation_collection[lang]; 
     }  
     else{
        console.log('Langue non disponible '+lang);
     }
  }

  public getCurrentLang():string{
     let lang = this.storageService.get('current_lang');
     if(!lang){
      this.current_lang='fr_FR';
     }
     else{
      this.current_lang=lang;
     }
     return this.current_lang;
  }
    
 
  public getLanguages(): any[]{
    /*let languages   = this.storageService.get('languages');
    if(!languages){
      console.log('language vide, on get');
      this.initLanguagesList();
      console.log('fin get');
    }

     setTimeout(function(){
    this.languages = this.storageService.get('languages');
    console.log('apers un get languages:');
    console.log(this.languages);

    },3000);
    */
    /*let languages =  [ 
      {"key":"fr_FR","value":"Français"},
      {"key":"en_EN","value":"English"},
      {"key":"zh_CN","value":"Chinese"},
    ];*/

    let languages = [ 
    { "key":"fr_FR", "value":"Français" },
    { "key":"en_US", "value":"English" },
    { "key":"es_ES", "value":"Spanish" },
    { "key":"ar_AE", "value":"Arabic" },
    { "key":"zh_HK", "value":"Chinese" },
    { "key":"pt_PT", "value":"Portuguese" },
    { "key":"it_IT", "value":"Italian" },
    { "key":"nl_NL", "value":"Dutch" },
    { "key":"sv_SE", "value":"Swedish" },
    { "key":"de_DE", "value":"Germain" },
    { "key":"jp_JP", "value":"Japanese" },
    ] ;
    this.languages = languages; 
    
    return (this.languages);
  }



/*  public initLanguagesList():JsonValue[]{
    this.getLanguagesList()
    .then((data) => {
     this.storageService.set('languages',data);
     this.languages = this.storageService.get('languages');
    console.log("initLanguagesList");
      return this.languages;
    });
    return [];
  }
*/
  public initTranslations():JsonValue[]{
    this.getTranslations()
    .then((data) => {
     this.storageService.set('translations',data);
    });
    this.translations= this.storageService.get('translations');
    return this.translations;
  }


  /*public async getLanguagesList(): Promise<JsonValue[]> {
    return this.http
      .get<JsonValue[]>(SERVER_TEST_URL+GET_LANGUAGES)
      .pipe(
        tap(
          (_event) => {
            // Succeeds when there is a response; ignore other events,
          },
          (error) => {
            console.log(error);
          }
        )
      )
      .toPromise();
  }*/


/*  public async getLanguagesList(): Promise<JsonValue[]> {
    return this.http
      .get<JsonValue[]>(SERVER_TEST_URL+GET_LANGUAGES)
      .pipe(
        tap(
          (_event) => {
            // Succeeds when there is a response; ignore other events,
          },
          (error) => {
            console.log(error);
          }
        )
      )
      .toPromise();
  }
*/

  public async getTranslations(): Promise<JsonValue[]> {
    /*L'utilisateur n'est pas connecté dans on ne sait pas si prod ou preprod */
    return this.http
      .get<JsonValue[]>(SERVER_PROD_URL+GET_TRANSLATIONS)
      .pipe(
        tap(
          (_event) => {
            // Succeeds when there is a response; ignore other events,
          },
          (error) => {
            console.log(error);
          }
        )
      )
      .toPromise();
  }




}




  
