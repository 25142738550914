import { Component, OnInit } from '@angular/core';

import { APP_VERSION } from '../../../version';
declare var require: any;

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})

export class VersionComponent implements OnInit {
  version: string = APP_VERSION;

  constructor() {}

  ngOnInit() {

  }
}



