import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule } from '@angular/core';

import { CollapsibleDirective } from './directives/collapsible.directive';
import { ConfirmComponent } from './services/confirm/confirm.component';
import { IfLocationDirective } from './directives/if-location.directive';
import { InputDirective } from './directives/input.directive';
import { ModalDirective } from './directives/modal.directive';
import { PositiveNumberValidator } from './validators/positive-number.validator'; 
import { SearchWidgetComponent } from './widgets/search/search.component';
import { SelectDirective } from './directives/select.directive';
import { SuggestionFilterDirective } from './directives/suggestion-filter.directive';
import { SwitchComponent } from './widgets/switch/switch.component';
import { TreeComponent } from './widgets/tree/tree.component';
import { TreeNodeComponent } from './widgets/tree/tree-node/tree-node.component';
import { IfPermissionDirective } from './directives/if-permission.directive';
import { CarouselDirective } from './directives/carousel.directive';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
// import { MapActionsComponent } from './services/map/map-actions.service';
@NgModule({
 providers: [
	 	{provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}}
      ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  declarations: [
    CollapsibleDirective,
    ConfirmComponent,
    IfLocationDirective,
    InputDirective,
    ModalDirective,
    PositiveNumberValidator,
    SearchWidgetComponent,
    SelectDirective,
    SuggestionFilterDirective,
    SwitchComponent,
    TreeComponent,
    TreeNodeComponent, 
    IfPermissionDirective, 
    CarouselDirective, 
  ],
  exports: [
    CollapsibleDirective,
    ConfirmComponent,
    IfLocationDirective,
    InputDirective,
    ModalDirective,
    PositiveNumberValidator,
    SearchWidgetComponent,
    SelectDirective,
    SuggestionFilterDirective,
    SwitchComponent,
    TreeComponent,
    TreeNodeComponent,
    IfPermissionDirective,
    CarouselDirective
  ],
  entryComponents: [ConfirmComponent]
})
export class CoreModule { }
