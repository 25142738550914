import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthorizationService } from 'src/app/core/services/authorization';
import { ConfirmService } from 'src/app/core/services/confirm/confirm.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { I18nService } from '@i18n';

@Component({
  selector: 'app-password-management',
  templateUrl: './password-management.component.html',
  styleUrls: ['./password-management.component.scss']
})

export class PasswordManagementComponent implements OnInit {

  @ViewChild('passForm') private passForm: NgForm;

  public confirmPassword: string;

  public newPassword: string;

  public oldPassword: string;


  constructor(
    private auth: AuthorizationService,
    private confirm: ConfirmService,
    private notification: NotificationService,
    public i18n: I18nService,
  ) { }

  ngOnInit() {
    this.confirmPassword = '';
    this.newPassword = '';
    this.oldPassword = '';
  }

  public getCurrentLogin(){
    return this.auth.getCurrentLogin();
  }

  public onSubmit(): void {
    this.auth.changePassword(this.oldPassword, this.newPassword).subscribe(
      (val) => {
        this.notification.notify('primary', this.i18n.t("Votre mot de passe a été changé avec succès !"));
        this.passForm.reset();
      },
      (err) => this.notification.notify('error', err)
    );
  }
}
