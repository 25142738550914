import { Component, OnInit } from '@angular/core';
import { IsOnlineService } from '../../core/services/is-online.service';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss']
})
export class OfflineComponent implements OnInit {

  constructor(
    private isOnlineService: IsOnlineService
  ) { }

  ngOnInit() {}

  public onClick(): void {
    this.isOnlineService.setState(true);
  }
}
