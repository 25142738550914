import { FormField } from './form-field';

export type FilterType = 'object' | 'inspection' | 'intervention';

export interface FilterUserRequest {
  id_user: string;
  filter: any;
}

export interface FilterStructure {
  type: FilterType;
  objects: FilterTypeObject[];
}

export interface FilterTypeObject {
  id_object_type: string;
  fields: FormField[];
}

export const DEFAULT_FILTER_FIELDS: FormField[] = [
  {
    id_object_type_fields: '1',
    id_object_type: '1',
    order_num: 1,
    name: 'startDate',
    lib: 'Créé du',
    input_type: 'date',
    created_at: '22-02-2020',
    mandatory: 1,
    placeholder: 'Placeholder',
    info: 'more info',
    form_name: 'add',
    updated_at: '23-02-2020',
  },
  {
    id_object_type_fields: '1',
    id_object_type: '1',
    order_num: 2,
    name: 'endDate',
    lib: 'Au',
    input_type: 'date',
    created_at: '22-02-2020',
    mandatory: 1,
    placeholder: 'Placeholder',
    info: 'more info',
    form_name: 'add',
    updated_at: '23-02-2020',
  },
  {
    id_object_type_fields: '1',
    id_object_type: '1',
    order_num: 3,
    name: 'lastDays',
    lib: 'Ou n’afficher que les',
    input_type: 'number',
    created_at: '22-02-2020',
    mandatory: 1,
    placeholder: 'N',
    info: 'more info',
    form_name: 'add',
    updated_at: '23-02-2020',
  },
/*  {
    id_object_type_fields: '1',
    id_object_type: '1',
    order_num: 4,
    name: 'dueDate',
    lib: 'Échéance',
    input_type: 'date',
    created_at: '22-02-2020',
    mandatory: 1,
    placeholder: 'Placeholder',
    info: 'more info',
    form_name: 'add',
    updated_at: '23-02-2020',
  },*/
];
