import { UserPosition } from './map-object/map-object';
import { Injectable } from '@angular/core';
import { DataTransferService } from './map-object/data-transfer.service';
import { StorageService as StorageServiceMap } from './map-object/storage.service';
import { StorageService as StorageServiceInspection } from './inspection/storage.service';
import { StorageService as StorageServiceIntervention } from './intervention/storage.service';
//import { StorageService as StorageServiceHistory } from './history/storage.service';
import { StorageService as StorageServiceHistoryIntervention } from './history-intervention/storage.service';
import { StorageService as StorageServiceHistoryInspection} from './history-inspection/storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Observable, of, Observer, Subject, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SYNC_FROM_URL, UPDATE_URL } from '../../../server-url';
import { SyncService } from 'src/app/core/services/sync.service';
//import { Inspection } from './inspection';
import { MapObject } from './map-object';
import { StorageService } from './storage.service';
import { MapLayersService } from './map';
import { LoaderService } from './loader.service';
import { LogoService } from './logo.service';
import { Intervention } from './intervention/intervention';
import { Inspection} from './inspection/inspection';
import { AuthorizationService } from './authorization/authorization.service';
import { GPSService } from './gps/gps.service';
import {I18nService}  from '@i18n';

interface SyncInfo {
  kml?: {
    date: Date;
    elements: number;
  };
  inspections?: {
    date: Date;
    elements: number;
  };
  interventions?: {
    date: Date;
    elements: number;
  };
  mapObjects?: {
    date: Date;
    elements: number;
  };
  userPosition?: {
    lat: number;
    lng: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SyncServerService {
  private readonly uuid: any = window.device && window.device.uuid;

  public syncFinished = new Subject();

  public appId: string;

  public serverURL: string;

  public currentLatitude: number;
  public currentLongitude: number;
  public currentAltitude: number|null;
  public currentAccuracy: number|null;

  constructor(
    private transferService: DataTransferService,
    private storageServiceMap: StorageServiceMap,
    private storageServiceInspection: StorageServiceInspection,
    private storageServiceIntervention: StorageServiceIntervention,
    private notification: NotificationService,
    //private storageServiceHistory: StorageServiceHistory,
    private storageServiceHistoryIntervention: StorageServiceHistoryIntervention,
    private storageServiceHistoryInspection: StorageServiceHistoryInspection,
    private http: HttpClient,
    private sync: SyncService,
    private storageService: StorageService,
    private mapLayersService: MapLayersService,
    private loaderService: LoaderService,
    private logoService: LogoService,
    private gps: GPSService,
    private localStorageService: StorageService,
    private auth: AuthorizationService,
    public i18n: I18nService,
  ) {
    this.appId = this.storageService.get('appId');
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });
  }

  public updateObjectsFromServer(): Observable<any> {
    this.loaderService.setSubscription('Vérifier les mises à jour');

    this.sync.synchronize().subscribe();
    this.loaderService.setSubscription(
      this.i18n.t("Mise à jour de la base de données des utilisateurs")
    );

    return Observable.create((observer: Observer<any>) => {
      this.updateObjects().subscribe(() => {
        this.storageServiceMap.objectsUpdated.next();

        this.synchronizeRequest().subscribe(
          (data) => {
            this.updateUserLogo(data.logo);
            this.syncMapLayers(data).subscribe(
              () => {
                this.setStorageSyncInfo();
                observer.next('Données synchronisées');
              },
              () => {
                alert('kml error');
                //observer.error('Erreur de synchronisation kml');
                observer.complete();
                this.setStorageSyncInfo();
              }
            );
          },
          (error) => {
            //observer.error('Erreur de synchronisation kml');
            observer.complete();
          }
        );
      });
    });
  }

  public syncMapLayers(data: any): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.mapLayersService.synchronizeLayers(data).subscribe(
        (res) => {
          observer.next(true);
          observer.complete();
        },
        (error) => {
          this.notification.notify('error', error);
          observer.next(true);
          observer.complete();
        }
      );
    });
  }

  public updateObjects(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.loaderService.setSubscription(
        // tslint:disable-next-line:quotemark
        this.i18n.t("Mise à jour de la base de données d'objets")
      );

  	  this.notification.wait(
	        this.i18n.t("Mise à jour en cours")
	  );


      this.getObjectsRequest().subscribe(
        (data) => {
          if (data.objects.length !== 0) {
		  
            // XXX ?
            data.objects.forEach((object) => {
              this.storageServiceMap.addObject(object);
            });

	    this.notification.notify(
   	      'error',
	        this.i18n.t("Mise à jour en terminée")
  	    );

          } else {
    		//this.localStorageService.remove('mapObjects');
           	 this.localStorageService.set('mapObjects', []);
          }

          if (data.inspections && data.inspections.length !== 0) {
            data.inspections.forEach((inspection) => {
              inspection.date = new Date(inspection.date * 1000);
              this.storageServiceInspection.addInspection(inspection);
            });
          } else {
            this.localStorageService.set('inspections', []);
          }

          if (data.interventions && data.interventions.length !== 0) {
            data.interventions.forEach((intervention) => {
              intervention.date = new Date(intervention.date * 1000);
              this.storageServiceIntervention.addIntervention(intervention);
            });
          } else {
            this.localStorageService.set('interventions', []);
          }

        /*  for (const h in data.history) {
            if (h.length > 10) {
              this.storageServiceHistory.updateInspection(h, data.history[h]);
            }
          }*/
        for (const h in data.history_inspection) {
            if (h.length > 10) {
              this.storageServiceHistoryInspection.updateInspection(
                h,
                data.history_inspection[h]
              );
            }
          }


          for (const h in data.history_intervention) {
            if (h.length > 10) {
              this.storageServiceHistoryIntervention.updateIntervention(
                h,
                data.history_intervention[h]
              );
            }
          }
          this.storageServiceInspection.refreshInspections();
          this.storageServiceIntervention.refreshInterventions();
          this.storageServiceMap.refreshObjects();
          this.setStorageSyncInfo();
          observer.next(true);
          observer.complete();
        },
        (err) => {
          this.notification.notify(
            'error',
            'Erreur de synchronisation mapper des objets'
          );
          observer.next(true);
          observer.complete();
        }
      );
    });
  }

  public getObjectsRequest(): Observable<any> {
    this.appId = this.storageService.get('appId');

    this.gps.getCoords().subscribe((pos) => {
      this.currentLatitude = pos.latitude;
      this.currentLongitude = pos.longitude;
      this.currentAltitude = pos.altitude;
      if(pos.accuracy){
	      this.currentAccuracy= Math.floor(pos.accuracy);
      }
	 /* this.notification.notify(
	    'primary',
	    'Geo:'+pos.latitude+';'+pos.longitude+' - Alt:'+pos.altitude+'m - Pre:'+this.currentAccuracy+'m'
	  );*/

    });


    // NMT SEND POSITION

    return this.http.post(`${this.serverURL}/${SYNC_FROM_URL}`, {
      app_id: this.appId,
      uuid: this.uuid,
      userPosition: {
        lat: this.currentLatitude,
        lng: this.currentLongitude,
        alt: this.currentAltitude,
        acc: this.currentAccuracy,
      },
    });
  }

  public setStorageSyncInfo(): void {
    const inspections: Inspection[] =
      this.storageServiceInspection.getAllInspections();

    const interventions: Intervention[] =
      this.storageServiceIntervention.getAllInterventions();

    const mapObjects: MapObject[] = this.storageServiceMap.getAllObjectsClean();

    const layers: any = this.mapLayersService.getLayers();

    const info: SyncInfo = {};

    if (layers && layers.length) {
      info.kml = {
        date: new Date(),
        elements: layers.length,
      };
    } else {
      info.kml = {
        date: new Date(),
        elements: 0,
      };
    }

    if (inspections && inspections.length) {
      info.inspections = {
        date: new Date(),
        elements: inspections.length,
      };
    } else {
      info.inspections = {
        date: new Date(),
        elements: 0,
      };
    }

    if (interventions && interventions.length) {
      info.interventions = {
        date: new Date(),
        elements: interventions.length,
      };
    } else {
      info.interventions = {
        date: new Date(),
        elements: 0,
      };
    }

    if (mapObjects && mapObjects.length) {
      info.mapObjects = {
        date: new Date(),
        elements: mapObjects.length,
      };
    } else {
      info.mapObjects = {
        date: new Date(),
        elements: 0,
      };
    }

    this.storageService.merge('syncinfo', info);
    this.syncFinished.next();
  }

  public updateUserLogo(url: string): void {
    this.logoService.updateLogo(url);
  }

  public synchronizeRequest(): Observable<any> {
    this.appId = this.storageService.get('appId');

    return this.appId
      ? this.http.get(`${this.serverURL}${UPDATE_URL}?app_id=${this.appId}`)
      : this.http.get(`${this.serverURL}${UPDATE_URL}`);
  }
}
