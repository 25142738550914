import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RoutingHistoryService } from './services/routing-history.service';
import { HistoryBackDirective } from './directives/history-back.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HistoryBackDirective],
  exports: [HistoryBackDirective]
})
export class RoutingHistoryModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RoutingHistoryModule,
      providers: [
        RoutingHistoryService
      ]
    };
  }
}
