import { Injectable } from '@angular/core';

import { HistoryItem } from './history';
import { HistoryIterator } from './iterator';
import { Inspection, InspectionStorageService } from '../inspection';
import { MapObject } from '../map-object';
import { StorageService as LocalStorageService } from '../storage.service';
import { FiltersService } from '../filters.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private history: Map<string, HistoryItem>;

  private key = 'history_inspection';

  constructor(
    private inspectionStorage: InspectionStorageService,
    private localStorage: LocalStorageService,
    private filterService: FiltersService
  ) {
    const history: [string, HistoryItem][] | null = this.localStorage.get(
      this.key
    );

    this.history =
      history == null ? new Map<string, HistoryItem>() : new Map(history);
  }

  private getId(object: { __id: string } | string): string {
    return typeof object === 'string' ? object : object.__id;
  }

  private getInspections(inspectionIds: string[]): Inspection[] {
    return inspectionIds
      .map((id: string) => this.inspectionStorage.getInspection(id)!)
      .filter((inspection: Inspection | undefined) => !!inspection)
      .reverse();
  }

  private getObjectHistory(object: MapObject | string): HistoryItem | null {
    const id: string = this.getId(object),
      history: HistoryItem | undefined = this.history.get(id);
    return history || null;
  }

  private save(): void {
    this.localStorage.set(this.key, [...this.history]);
  }

  public addInspection(inspection: Inspection): void {
    let historyItem: HistoryItem | undefined = this.history.get(
      inspection.objectId
    );

    if (historyItem == null) {
      historyItem = new HistoryItem(inspection.objectId);
    }

    historyItem.inspections.push(inspection.__id);
    this.history.set(inspection.objectId, historyItem);
    this.save();
  }

  public updateInspection(id: string, inspectionsId: string[]): void {
    let historyItem: HistoryItem | undefined = this.history.get(id);
    let flag: boolean = true;
    if (historyItem == null) {
      historyItem = new HistoryItem(id);
    }

    for (let i in inspectionsId) {
      if (historyItem.inspections.length === 0) {
        historyItem.inspections.push(inspectionsId[i]);
        this.history.set(id, historyItem);
        this.save();
      } else {
        for (let a in historyItem.inspections) {
          if (historyItem.inspections[a] == inspectionsId[i]) {
            flag = false;
            break;
          }
        }
        if (flag) {
          historyItem.inspections.push(inspectionsId[i]);
          this.history.set(id, historyItem);
          this.save();
        }
      }
    }
  }

  public getHistory<T extends Inspection>(object: any): HistoryIterator<T> {
    let history: HistoryItem | null = this.getObjectHistory(object),
      inspections: T[] =
        history != null
          ? <T[]>this.getInspections(history.inspections)
          : [];
    inspections = inspections.filter((inspection) => {
      return this.filterService.filter(
        'inspection',
        inspection,
        object.id_object_type
      );
    });
    return new HistoryIterator<T>(inspections);
  }

  public getLastInspection(object: MapObject | string): Inspection | null {
    const history: HistoryItem | null = this.getObjectHistory(object);

    let result: Inspection | null = null;
    if (history != null && history.inspections.length > 0) {
      const id: string =
          history.inspections[history.inspections.length - 1],
        inspection: Inspection | undefined =
          this.inspectionStorage.getInspection(id);

      result = inspection || null;
    }

    return result;
  }
}
