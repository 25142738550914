import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from 'src/app/core/core.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { RouterModule } from '@angular/router';

import { AboutComponent } from './about/about.component';
import { AccountComponent } from './account/account.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { BaesInspectionListComponent } from './baes-inspection-list/baes-inspection-list.component';
import { BaesInspectionComponent } from './baes-inspection/baes-inspection.component';
import { BaesObjectEditorComponent } from './baes-object-editor/baes-object-editor.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { ReportEndComponent } from './report-end/report-end.component';
import { ReportingComponent } from './reporting/reporting.component';
import { OfflineComponent } from './offline/offline.component';
import { BaesInterventionComponent } from './baes-intervention/baes-intervention.component';
import { BaesHistoryInterventionComponent } from './baes-history-intervention/baes-history-intervention.component';
import { BaesHistoryInspectionComponent } from './baes-history-inspection/baes-history-inspection.component';
import { FiltersComponent } from './filters/filters.component';
import { BaesInterventionListComponent } from './baes-intervention-list/baes-intervention-list.component';

@NgModule({
  declarations: [
    AboutComponent,
    AccountComponent,
    FeedbackComponent,
    BaesInspectionListComponent,
    BaesInspectionComponent,
    BaesObjectEditorComponent,
    LoginComponent,
    MapComponent,
    ReportEndComponent,
    ReportingComponent,
    OfflineComponent,
    BaesInterventionComponent,
    BaesHistoryInterventionComponent,
    BaesHistoryInspectionComponent,
    FiltersComponent,
    BaesInterventionListComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CoreModule,
    RouterModule
  ]
})
export class PagesModule { }

