import { Injectable } from '@angular/core';

import { AuthorizationService } from '../../core/services/authorization';
// import { Permission, PermissionScope, PermissionName } from '../../permissions';

interface PermissionScope {  
  [key: string]: number;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(
    private auth: AuthorizationService
  ) {}

  
  // permissions - can_showmap, can_listing, can_signalement, can_action, can_edit, can_control, can_viewhistory, can_move, can_delete, 
  public hasPermission(permission: string): boolean {
    const scope: PermissionScope = this.auth.getScope();

    if(!scope) {  
      return false;
    }

    return !!+scope[permission];
  }
}
