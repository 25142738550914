import {
    Component,
    Input,
    ElementRef,
    AfterViewInit,
    Output,
    Inject,
    EventEmitter,
    ViewChild,
    Renderer2
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { switchMap, takeUntil, pairwise } from 'rxjs/operators';
import {BaesMapObjectFormData} from "../../core/services/map-object";
import { DOCUMENT } from '@angular/common';
import { I18nService} from '@i18n';

@Component({
    selector: 'app-canvas',
    templateUrl: './sign.component.html',
    // template: '<canvas #canvas></canvas>',
    styles: ['canvas { border: 1px solid #000; }']
})
export class CanvasComponent implements AfterViewInit {
    @ViewChild('canvas') public canvas: ElementRef | undefined;

    @Input() public width = 300;
    @Input() public height = 300;
    @Input('sign') currentSign? :string;

    @Output()
    public signed: EventEmitter<string>  = new EventEmitter<string>();

    private cx: CanvasRenderingContext2D | null | undefined;

    public signSaved = false;
    public signImg = '';

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public i18n: I18nService,
    ){

        this.renderer.listen('window', 'touchstart',(e:Event)=>{
            /**
             * Only run when toggleButton is not clicked
             * If we don't check this, all clicks (even on the toggle button) gets into this
             * section which in the result we might never see the menu open!
             * And the menu itself is checked here, and it's where we check just outside of
             * the menu and button the condition abbove must close the menu
             */
            if(!this.canvas!.nativeElement.contains(e.target) ) {
                this.renderer.removeClass(this.document.body, 'lock-screen');
            }
        });
    }
    ngOnInit() {
        if (this.currentSign != null && typeof this.currentSign != 'undefined' && this.currentSign.length) {
            this.signImg = this.currentSign;
            this.signSaved = true;
        }


    }



    public ngAfterViewInit() {


        const canvasEl: HTMLCanvasElement = this.canvas!.nativeElement;

        this.cx = canvasEl.getContext('2d');

        canvasEl.width = this.width;
        canvasEl.height = this.height;

        if (!this.cx) throw 'Cannot get context';

        this.cx.lineWidth = 3;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#000';

        this.captureEvents(canvasEl);

    }

    private captureEvents(canvasEl: HTMLCanvasElement) {
        // this will capture all mousedown events from the canvas element
        fromEvent(canvasEl, 'touchstart')//''
            .pipe(
                switchMap(e => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'touchmove').pipe(
                        // we'll stop (and unsubscribe) once the user releases the mouse
                        // this will trigger a 'mouseup' event
                        takeUntil(fromEvent(canvasEl, 'touchend')),
                        // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                        takeUntil(fromEvent(canvasEl, 'touchend')),
                        // pairwise lets us get the previous value to draw a line from
                        // the previous point to the current point
                        pairwise()
                    );
                })
            )
            .subscribe((res) => {
                this.renderer.addClass(this.document.body, 'lock-screen');
                const rect = canvasEl.getBoundingClientRect();
                    const prevMouseEvent = (res[0] as TouchEvent).touches[0];
                    const currMouseEvent = (res[1] as TouchEvent).touches[0];
                    // previous and current position with the offset
                    const prevPos = {
                        x: prevMouseEvent.clientX - rect.left,
                        y: prevMouseEvent.clientY - rect.top
                    };

                    const currentPos = {
                        x: currMouseEvent.clientX - rect.left,
                        y: currMouseEvent.clientY - rect.top
                    };
                    // this method we'll implement soon to do the actual drawing
                    this.drawOnCanvas(prevPos, currentPos);
            });
        //
        fromEvent(canvasEl, 'mousedown')//''
            .pipe(
                switchMap(e => {
                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvasEl, 'mousemove').pipe(
                        // we'll stop (and unsubscribe) once the user releases the mouse
                        // this will trigger a 'mouseup' event
                        takeUntil(fromEvent(canvasEl, 'mouseup')),
                        // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                        takeUntil(fromEvent(canvasEl, 'mouseleave')),
                        // pairwise lets us get the previous value to draw a line from
                        // the previous point to the current point
                        pairwise()
                    );
                })
            )
            .subscribe((res) => {
                const rect = canvasEl.getBoundingClientRect();
                const prevMouseEvent = res[0] as MouseEvent;
                const currMouseEvent = res[1] as MouseEvent;
                // previous and current position with the offset
                const prevPos = {
                    x: prevMouseEvent.clientX - rect.left,
                    y: prevMouseEvent.clientY - rect.top
                };

                const currentPos = {
                    x: currMouseEvent.clientX - rect.left,
                    y: currMouseEvent.clientY - rect.top
                };
                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);
            });
    }

    private drawOnCanvas(
        prevPos: { x: number; y: number },
        currentPos: { x: number; y: number }
    ) {
        if (!this.cx) {
            return;
        }

        this.cx.beginPath();

        if (prevPos) {
            this.cx.moveTo(prevPos.x, prevPos.y); // from
            this.cx.lineTo(currentPos.x, currentPos.y);
            this.cx.stroke();
        }
    }

    public onClear = ()=>{
        if(this.cx) {
            this.cx.beginPath();
            this.cx.clearRect(0, 0, this.width, this.height);
            this.cx.stroke();
        }
        this.renderer.removeClass(this.document.body, 'lock-screen');
    }

    public onSave = ()=>{
        if(this.canvas && typeof this.canvas.nativeElement != 'undefined') {
            var dataURL: string = this.canvas.nativeElement.toDataURL("image/png");
            this.signImg = dataURL;
            this.signSaved = true;
            this.signed.emit(dataURL);
        }
        this.renderer.removeClass(this.document.body, 'lock-screen');
    }

    public removeSign = ()=>{
        this.signImg = '';
        this.signSaved = false;
    }

}
