import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderHidden: Subject<boolean>;

  public loaderHidden$: Observable<boolean>

  private loaderAddText: Subject<string>;

  public loaderAddText$: Observable<string>

  constructor() {
    this.loaderHidden = new Subject<boolean>();
    this.loaderHidden$ = this.loaderHidden.asObservable();

    this.loaderAddText = new Subject<string>();
    this.loaderAddText$ = this.loaderAddText.asObservable();
  }

  public hideLoader(): void {
    this.loaderHidden.next(true);
    setTimeout(() => {
      this.loaderAddText.next('');
    }, 500);
    
  }

  public showLoader(): void {
    this.loaderHidden.next(false);
  }

  public setSubscription(text: string): void {
    this.loaderAddText.next(text);
  }
}
