import { Component, OnInit ,ElementRef,ViewChild  

} from '@angular/core';
import { Router } from '@angular/router';
import { JsonValue } from 'src/app/core/types/form-field';
import { AuthorizationService } from 'src/app/core/services/authorization';
import { NotificationService } from 'src/app/core/services/notification.service';
import { MapLayersService } from 'src/app/core/services/map';
import { fromEvent, merge, of, Subscription, Observable } from 'rxjs';
import {KeyValue} from '@angular/common';
import {I18nService}  from '@i18n';
import { GPSService, LatLng } from 'src/app/core/services/gps';

import { map } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public login: string;
  
  public password: string;

  public permissionsError: boolean = false;

  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;

  gpsStatus: any;
  gpsStatus$: Subscription = Subscription.EMPTY;
  public info: String;


//  public languages: JsonValue[];

//  public current_lang: string;

//  public translations: JsonValue[];


  public formSelects: any;


  constructor(
    private authorization: AuthorizationService,
    private router: Router,
    private notification: NotificationService,
    private mapLayerService: MapLayersService,
    public i18n: I18nService,
    private host: ElementRef,
    private gps: GPSService,
  ) { }

    ngOnInit() {
        this.login = '';
        this.password = '';

        this.checkGpsStatus();
        this.checkNetworkStatus();
        
        this.info = 'testing connexion...';
        this.notification.notify('primary', this.i18n.t("Récupération des données en cours"));

        setTimeout(() => {
           this.checkGpsStatus();
           this.checkNetworkStatus();
           this.info = 'GPS :'+((this.gpsStatus) ? 'OK' : 'KO' )+' - NETWORK : '+((this.networkStatus) ? 'OK' : 'NOK');
        }, "1000");

        this.mapLayerService.permissionError$.subscribe(
          (state) => {
            this.permissionsError = state;
          }
        )
    }

  public demo(): void{
    console.log("acces demo");
    this.login="test.demo@kartes";
    this.password="demo";
  }

  public onSubmit(): void {

    //this.notification.notify('primary', this.i18n.t("Connexion en cours"));
    this.authorization
      .login(this.login, this.password)
      .subscribe(
        (val) => {
          this.router.navigate(['/map'])
        },
        (err) => this.notification.notify('error', err)
      );
  }

   public checkGpsStatus() {
    this.gps.getCoords().subscribe({
        next: (coords) => {
          if( coords.latitude && coords.longitude ){
            this.gpsStatus = true; 
          }
        },
        error: (error) => {
          this.gpsStatus = false; 
        }
     });
  }


   public checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
    if(!this.networkStatus){
        this.notification.notify('error',"Connexion internet non trouvée");
    }
  }




}
