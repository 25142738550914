import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthorizationService } from 'src/app/core/services/authorization'; 
import { MapActionsService } from 'src/app/core/services/map/map-actions.service';  
import { LogoService } from 'src/app/core/services/logo.service';

@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss']
})
export class HeaderMainComponent implements OnInit {
  
  public logoUrl: string | null;

  constructor(
    private auth: AuthorizationService,
    private router: Router,
    private mapActions: MapActionsService,
    private logoService: LogoService
  ) {}

  ngOnInit() {
    if (window.cordova) {
      if (this.isOnline()) {
        this.logoUrl = this.logoService.getLogoUrl();
      } else {
        this.logoUrl = null;
      }
    } else {
      this.logoUrl = this.logoService.getLogoUrl();
    }
    
    this.logoService.logoUpdated.subscribe( 
      (url) => {
        if (window.cordova) {
          if (this.isOnline()) {
            this.logoUrl = url;
          } else {
            this.logoUrl = null;
          }
        } else {
          this.logoUrl = url;
        }
      }
    )
    this.auth.userLoggedOut$.subscribe(
      () => this.logoUrl = this.logoService.getLogoUrl()
    )
  }

  public isEditor(): boolean {
    return this.router.url.startsWith('/baes-object-editor');
  }

  public isHistory(): boolean {
    return this.router.url.startsWith('/baes-history');
  }

  public isMap(): boolean {
    return this.router.url.startsWith('/map');
  }

  public logout(): void {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  public findGeolocation(): void {
    this.mapActions.locate();
  }

  private isOnline(): boolean {
    return window.navigator.connection && window.Connection
      ? window.navigator.connection.type !== window.Connection.NONE
      : false;
  }
}
