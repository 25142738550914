import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ComponentsModule } from './components/components.module';
import { PagesModule } from './pages/pages.module';

import { StorageService } from './core/services/storage.service';
import { httpInterceptorProviders } from './core/http-interceptors';

import { AppComponent } from './app.component';

import { MatButtonModule, MatSnackBarModule } from '@angular/material';
import { RoutingHistoryModule } from './routing-history/routing-history.module';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    CoreModule,
    PagesModule,
    MatButtonModule,
    MatSnackBarModule,
    RoutingHistoryModule.forRoot(),
  ],
  providers: [
    httpInterceptorProviders,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: StorageService,
      useFactory: () => new StorageService('KEOBAES'),
    },
     {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
