import { Component, OnInit, ViewChild } from '@angular/core';
import {I18nService} from '@i18n';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: '',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  host: {
    '[className]': "'app-page'"
  }
})

export class FeedbackComponent implements OnInit {

  constructor(
    public i18n: I18nService, 
    private notification: NotificationService,
  ) { }

  ngOnInit() {

  }

}
