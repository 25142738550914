import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'src/app/core/core.module';
import { BaesObjectFormComponent } from './baes-object-form/baes-object-form.component';
import { HeaderComponent } from './header/header.component';
import { HeaderMainComponent } from './header-main/header-main.component';
import { LoginComponent } from './login/login.component';
import { PasswordManagementComponent } from './password-management/password-management.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ReportingComponent } from './reporting/reporting.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { CanvasComponent } from './sign/sign.component';
import { VersionComponent } from './version/version.component';
import { SynchronizationInfoComponent } from './synchronization-info/synchronization-info.component';
import { MapComponent } from './map/map.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { BaesInterventionFormComponent } from './baes-intervention-form/baes-intervention-form.component';
import { BaesInspectionFormComponent } from './baes-inspection-form/baes-inspection-form.component';
import { BaesHistoryInterventionComponent } from './baes-history-intervention/baes-history-intervention.component';
import { BaesHistoryInspectionComponent} from './baes-history-inspection/baes-history-inspection.component';
import { FiltersFormComponent } from './filters-form/filters-form.component';
import { MatNativeDateModule } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { InterventionListComponent } from './intervention-list/intervention-list.component';
import { InspectionListComponent } from './inspection-list/inspection-list.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY'
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'mm YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({
  declarations: [
    BaesInspectionFormComponent,
    BaesObjectFormComponent,
    HeaderComponent,
    HeaderMainComponent,
    InspectionListComponent,
    LoginComponent,
    PasswordManagementComponent,
    FeedbackFormComponent,
    ReportingComponent,
    SidenavComponent,
    CanvasComponent,
    VersionComponent,
    SynchronizationInfoComponent,
    MapComponent,
    AutocompleteComponent,
    BaesInterventionFormComponent,
    BaesHistoryInterventionComponent,
    BaesHistoryInspectionComponent,
    FiltersFormComponent,
    InterventionListComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatPaginatorModule,
    RouterModule,
    MatDatepickerModule
  ],
  exports: [
    BaesInspectionFormComponent,
    BaesObjectFormComponent,
    HeaderComponent,
    HeaderMainComponent,
    InspectionListComponent,
    LoginComponent,
    PasswordManagementComponent,
    FeedbackFormComponent,
    ReportingComponent,
    VersionComponent,
    SidenavComponent,
    CanvasComponent,
    SynchronizationInfoComponent,
    MapComponent,
    AutocompleteComponent,
    BaesInterventionFormComponent,
    BaesHistoryInterventionComponent,
    BaesHistoryInspectionComponent,
    FiltersFormComponent,
    InterventionListComponent
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
   ,{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' }
  ]
})
export class ComponentsModule { }


