export interface MapElements {
  baes: boolean;
  es: boolean;
}

export class MapState {
  public cache: { [key: string]: any } = {};

  public currentLayer = 'fra.lyon.poudrette.UMEQ_HALL_GARAGE.floor0';

  public elementsVisibility: any = {};

  public firstTime = true;

  public layersVisible = true;

  public mapVisible = true;

  public zoom = 18;

  constructor() {}
}
