import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { } from '@types/googlemaps';
import { GoogleGeocodeService } from '../../core/services/google-geocode.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {
  @Input() adressType: string;

  @Input() autocompleteInput: string = '';

  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  @ViewChild('addresstext') addresstext: any;

  @ViewChild('addresstextHidden') addresstextHidden: any;

  public autocompleteInputHidden: string;

  public queryWait: boolean;

  public autocomplete: any;

  public gpsChangedSubscription: Subscription

  public choosenPlace: any;

  constructor(
    private googleGeocodeservice: GoogleGeocodeService
  ) {
    this.gpsChangedSubscription = this.googleGeocodeservice.gpsChanged.subscribe(
      (address)=> {
        this.autocompleteInput = address;
        setTimeout(() => {
          // this.addresstext.nativeElement.focus();
          this.autocompleteInput = address;
          this.onInputBlur();
        }, 0);
      }
    )
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.gpsChangedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement);
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.choosenPlace = place;
      this.autocompleteInput = place.formatted_address;
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    place['formatted_address'] = this.autocompleteInput;
    setTimeout(() => {
      this.setAddress.emit(place);
    }, 100);
  }

  public onInputBlur(): void {
    const data: any = {
      formatted_address: this.autocompleteInput,
      geometry: {
        location: {
          'lat': () => {
            return this.choosenPlace ? this.choosenPlace.lan : 0;
          },
          'lng': ()=> {
            return this.choosenPlace ? this.choosenPlace.lan : 0;
          }
        }
      }
    }
    setTimeout(() => {
      this.setAddress.emit(data);
    }, 100);

  }
}
