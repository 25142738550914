import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { catchError, concatAll, map, switchMap, tap } from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MapObject } from './map-object';
import { StorageService } from './storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService as LocalStorageService } from '../storage.service';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  public serverURL: string;

  constructor(
    private appConfig: AppConfigService,
    private http: HttpClient,
    private storage: StorageService,
    private notification: NotificationService,
    private localStorage: LocalStorageService,
    private auth: AuthorizationService
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });
  }

  private _send(object: MapObject): Observable<any> {
    return this.http.post(`${this.serverURL}/api/sync`, {
      app_id: this.localStorage.get('appId'),
      user_id: this.localStorage.get('userId'),
      object: {
        [object.type]: object,
      },
    });
  }

  public send(object: MapObject): Observable<any> {
    return of(null).pipe(
      map(() => {
        try {
          this.storage.addUnsentObject(object);

          return true;
        } catch (error) {
          return false;
        }
      }),
      switchMap((saved: boolean) => {
        return this._send(object).pipe(
          map(() => true),
          catchError((error) => (!saved ? throwError(error) : of(false))),
          tap(
            (sent: boolean) => sent && this.storage.removeUnsentObject(object)
          )
        );
      })
    );
  }

  public synchronize(): Observable<any> {
    const objects: MapObject[] = this.storage.getAllUnsentObjects();

    if (objects.length > 0) {
      const observables: Observable<any>[] = objects.map(
        (object: MapObject) => {
          return this._send(object).pipe(
            tap({
              complete: () => this.storage.removeUnsentObject(object),
            })
          );
        }
      );

      return from(observables).pipe(concatAll());
    } else {
      return of(null);
    }
  }
}
