import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { Md5 } from 'ts-md5/dist/md5';
import {
  LOGIN_URL,
  SERVER_PROD_URL,
  SERVER_TEST_URL,
} from '../../../../server-url';
import { APP_VERSION } from '../../../../version';
import { AppKey } from '../../globals';
import { UserType } from './user/user-type';
import {I18nService}  from '@i18n';

interface PermissionScope {
  [key: string]: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private token: string | null;

  private userType: UserType | null;

  private userLogin: string | null;

  private userLoggedIn: Subject<UserType>;

  private userLoggedOut: Subject<void>;

  public userLoggedIn$: Observable<UserType>;

  public userLoggedOut$: Observable<void>;

  public loginResponse: Observable<any>;

  private userId: number | null;

  private readonly uuid: any = window.device && window.device.uuid;

  private scope: PermissionScope;

  private serverTestUrl: string = SERVER_TEST_URL;

  private serverProdUrl: string = SERVER_PROD_URL;

  public serverURL: string;


  constructor(
    private http: HttpClient,
    private storage: StorageService,
    public i18n: I18nService,

    ) {
      this.userLoggedIn = new Subject<UserType>();
    this.userLoggedOut = new Subject<void>();
    this.userLoggedIn$ = this.userLoggedIn.asObservable();
    this.userLoggedOut$ = this.userLoggedOut.asObservable();
    this.scope = this.storage.get('scope') || [];

    if (this.isSessionExpired()) {
      this.logout();
    } else {
      this.serverURL = this.getServerURL();
      this.token = this.storage.get('token');
      this.userId = this.storage.get('userId');
      this.userType = this.storage.get('userType');
      this.userLogin = this.storage.get('userLogin');
    }
  }

  private isSessionExpired(): boolean {
    const sessionDuration: number = 1000 * 60 * 60 * 12;
    const loginTime: number | null = this.storage.get('lastLoginTime');

    if (loginTime != null) {
      return Date.now() - loginTime > sessionDuration;
    }

    return false;
  }

  public getCurrentLogin() {
    return this.userLogin;
  }

  public changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<string> {
    return Observable.create((observer: Observer<any>) => {
      if (this.isAuthorized()) {
        const currPassword: string = this.storage.get('password');
        oldPassword = this.getMd5(oldPassword);

        if (oldPassword === currPassword) {
          const reqData: {
            login: string;
            passwordOld: string;
            passwordNew: string;
            uuid: string;
          } = {
            login: this.userLogin as string,
            passwordOld: currPassword,
            passwordNew: this.getMd5(newPassword),
            uuid: this.uuid,
          };

          this.getChangePasswordRequest(reqData).subscribe(
            (resData) => {
              if (resData.status === 'ok') {
                this.storage.set('password', reqData.passwordNew);
                observer.next(true);
                observer.complete();
              } else {
                observer.error(this.i18n.t("Votre mot de passe n'a pas été modifié"));
              }
            },
            (error) => observer.next(this.i18n.t("Utilisateur ou mot de passe incorrect !"))
          );
        } else {
          console.error(this.i18n.t("Votre ancien mot de passe n'est pas correct"));
          observer.error(this.i18n.t( "Votre mot de passe n'a pas été changé car votre ancien mot de passe est invalide"));
        }
      } else {
        console.error(this.i18n.t('Vous devez être authentifié pour changer votre mot de passe'));
        observer.error(this.i18n.t("L'utilisateur n'est pas connecté"));
      }
    });
  }

  public getChangePasswordRequest(reqData): Observable<any> {
    return this.http.post(`${this.serverURL}${LOGIN_URL}`, reqData);
  }

  public getAuthorizationToken(): string | null {
    return this.token;
  }

  public getUserType(): UserType | null {
    return this.userType;
  }

  public getUserTypeName(): string | null {
    if (this.userType) {
      return UserType[this.userType];
    } else {
      return null;
    }
  }

  public getUserLogin(): string | null {
    return this.userLogin;
  }

  public getUserId(): number | null {
    return this.userId;
  }

  public isAuthorized(): boolean {
    return !!this.token;
  }

  public login(login: string, password: string): Observable<any> {
    let data: any;
    let id: any = '';
    let os: string = '';
    let userLogin: string = login.trim();

    if (window.cordova) {
      id = AppVersion.version;

      os = window.device.platform + window.device.version;
    }

    const reqData: {
      login: string;
      password: string;
      uuid: string;
      version: string;
      id: string;
      os: string;
    } = {
      login: login.trim().replace('test.', ''),
      password: this.getMd5(password.trim()),
      uuid: this.uuid,
      version: APP_VERSION,
      id: id,
      os: os,
    };

    const userInfo: {
      login: string;
      type: UserType | null;
      password: string;
    } = {
      login: login,
      type: 1,
      password: this.getMd5(password.trim()),
    };
    return Observable.create((observer: Observer<any>) => {
      this.getLoginRequest(reqData, userLogin).subscribe(
        (resData) => {
          if (this.checkLogin(resData, userInfo)) {
            observer.next(userInfo.type);
            observer.complete();
          } else {
            observer.error(this.i18n.t("Utilisateur ou mot de passe incorrect !"));
          }
        },
        (error) => observer.error(this.i18n.t("Utilisateur ou mot de passe incorrect !"))
      );
    });
  }

  public getLoginRequest(reqData, userLogin): Observable<any> {
    this.serverURL = this.getServerURL(userLogin);

    return this.http.post(`${this.serverURL}${LOGIN_URL}`, reqData);
  }

  public checkLogin(resData: any, userInfo: any): boolean {
    let result: boolean = false;

    if (resData.status === 'false') {
      result = false;
    } else if (resData.status === 'ok') {
      if (this.getLoginHash(resData)) {
        this.token = resData.hash;
        this.userType = userInfo.type;
        this.userLogin = userInfo.login;
        this.userId = resData.id;
        this.scope = resData.scope || [];
        this.storage.set('scope', this.scope);
        this.storage.set('token', this.token);
        this.storage.set('userType', this.userType);
        this.storage.set('password', userInfo.password);
        this.storage.set('userLogin', this.userLogin);
        this.storage.set('lastLoginTime', Date.now());
        this.storage.set('appId', resData.app_id);
        this.storage.set('userId', resData.id);
        this.storage.set('emails', resData.emails);
        this.storage.set('typeLib', resData.type_lib || []);
        this.storage.set('i18n', resData.i18n|| []);
        this.storage.set('object_types', resData.object_types || []);
        this.storage.set('allFormStructure', resData.all_form_structure || []);
        this.storage.set('syncInterval', resData.sync_interval || 30);
        this.storage.set('gps_interval', resData.gps_interval*1000 || 30*1000);
        if (resData.filter !== null) {
          this.storage.set('filters', resData.filter);
        } else {
          this.storage.set('filters', {});
        }

        this.userType ? this.userLoggedIn.next(this.userType) : null;

        result = true;
      } else {
        result = false;
      }
    }

    return result;
  }

  private getLoginHash(data): boolean {
    return (
      this.getMd5(data.status + data.date + AppKey) === data.hash.toLowerCase()
    );
  }

  public getScope(): PermissionScope {
    return this.scope;
  }

  private getMd5(val): string {
    const md5 = new Md5();
    return md5.appendStr(val).end() as string;
  }

  public logout(): void {

    this.token = null;
    this.userType = null;
    this.userLogin = null;
    this.userId = null;

    this.clearStorage();

    this.userLoggedOut.next();

    // return this.http.post('/api/logout', {token: this.token});
  }

  public clearStorage(): void {
    this.storage.remove('token');
    this.storage.remove('userType');
    this.storage.remove('userLogin');
    this.storage.remove('lastLoginTime');
    this.storage.remove('password');
    this.storage.remove('appId');
    this.storage.remove('userLogo');
    this.storage.remove('typeLib');
   // this.storage.remove('i18n');
    this.storage.remove('object_types');
    this.storage.remove('userLib');
    this.storage.remove('mapObjects');
    this.storage.remove('history');
    this.storage.remove('inspections');
    this.storage.remove('history_intervention');
    this.storage.remove('history_inspection');
    this.storage.remove('interventions');
    this.storage.remove('syncinfo');
    this.storage.remove('userId');
    this.storage.remove('scope');
    this.storage.remove('syncInterval');
    this.storage.remove('filters');
    this.storage.remove('emails');
    this.storage.remove('allFormStructure');
    this.storage.remove('kml');
  }

  public getActualServerURL(): string {
    return this.serverURL;
  }

  public getServerURL(login_str?: string): string {
    let url: string = '';

    if (login_str || this.storage.get('userLogin')) {
      url = this.isTestAccount(login_str)
        ? this.serverTestUrl
        : this.serverProdUrl;
    }

    return url;
  }

  public isTestAccount(login_str?: string): boolean {
    const login: string = login_str || this.storage.get('userLogin');
    return login.startsWith('test.');
  }
}
