import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output
} from '@angular/core';


import {
  BaesMapObjectFormData,
  MapObjectStorageService,
} from '../../core/services/map-object';
import { UtilsService } from '../../core/services/utils.service';
import { FormDataService } from '../../core/services/form-data.service';
import { NotificationService } from '../../core/services/notification.service';
import { CameraService } from '../../core/services/camera.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MobileUploaderService } from '../../core/services/mobile-uploader.service';
import { ImageTransferService } from '../../core/services/image-transfer.service';
import { GoogleGeocodeService } from '../../core/services/google-geocode.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../core/services/storage.service';
import {SYNC_FROM_URL} from "../../../server-url";
import {AuthorizationService} from "../../core/services/authorization/authorization.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { I18nService} from '@i18n';


interface FormAddress {
  name: string;
  coords: {
    lat: number;
    lon: number;
  };
}


@Component({
  selector: 'app-baes-object-form',
  templateUrl: './baes-object-form.component.html',
  styleUrls: ['./baes-object-form.component.scss'],
})
export class BaesObjectFormComponent implements AfterViewInit, OnInit {
  @Input('data')
  public inputData?: BaesMapObjectFormData;

  @Input()
  public typeLibId: string;

  @Output()
  public submitted: EventEmitter<BaesMapObjectFormData> = new EventEmitter<BaesMapObjectFormData>();

  public data: BaesMapObjectFormData = new BaesMapObjectFormData();

  public marks: string[] = [];

  public models: string[] = [];

  public formStructure: any = [];

  private imagesLimit: number;

  public images: string[] = [];

  public activeImageName: string;

  public activePictureName: string;
  public activePicture: string;

  public signName : string;
  public htmlName : string;

  public pdfName : string;


  public get imagesLimitReached(): boolean {
    return this.images.length >= this.imagesLimit;
  }

  public places: any = [];

  public place: string = '';

  public placesNames: string[] = [];

  public filteredPlaces: string[] = [];

  public address: any;

  public formAddress: FormAddress | undefined;

  public loading: boolean = false;

  public serverURL: string;

  public showModal: boolean = false;

  public gpsFormItem: any = {
    order_num: 1,
    name: 'address',
    lib: 'address',
    input_type: 'gps',
    mandatory: 0,
    placeholder: this.i18n.t("Adresse"),
    info: '',
    form_name: 'add',
  };

  public dueDateFormItem: any = {
    order_num: 2,
    name: 'due_date',
    lib: 'Échéance',
    input_type: 'date',
    mandatory: 0,
    placeholder: 'Échéance',
    info: '',
    form_name: 'add',
  };


  public allFormStructure: any = [];

  public newRoute : string | null;


  constructor(
    private mapObjectStorage: MapObjectStorageService,
    private utils: UtilsService,
    private formDataService: FormDataService,
    private notification: NotificationService,
    private camera: CameraService,
    private mobileUploader: MobileUploaderService,
    private imageTransfer: ImageTransferService,
    private zone: NgZone,
    private googleGeocodeservice: GoogleGeocodeService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private auth: AuthorizationService,
    private sanitizer: DomSanitizer,
    public  i18n: I18nService,
  ) {
      this.serverURL = this.auth.getActualServerURL();

      this.auth.userLoggedIn$.subscribe(() => {
          this.serverURL = this.auth.getActualServerURL();
      });

  }

  ngOnInit() {
    if (this.inputData != null) {
      this.data = this.utils.extend({}, this.inputData);
    }
    this.marks = this.mapObjectStorage.getMarks();
    this.models = this.mapObjectStorage.getModels();
    this.getStructure();
  }

 public getStructure(){
    this.allFormStructure = this.storageService.get('allFormStructure');
    let formType = 'add';
    if(!this.allFormStructure){
	    this.allFormStructure = [];
    }
    if(this.allFormStructure && this.allFormStructure[formType] && this.allFormStructure[formType][this.typeLibId]){
	    this.formStructure = this.allFormStructure[formType][this.typeLibId];
    }
    else{
	    console.log("Erreur: impossible de trouver le cache object structure add"); 
/*        this.formDataService.getFormStructure(this.typeLibId, 'add').subscribe(
        (data) => {
  	  this.formStructure = data;
	  this.allFormStructure[formType][this.typeLibId]=data;
        });*/
    }

    this.addStaticFormFields();
    this.inputData == null ? this.createDefaultValues() : null;
    this.createImagesData();
    this.updateTextFields();
    const coords: string | null = this.route.snapshot.queryParamMap.get('coords');
    if (coords) {
      this.setInitAddress(coords);
    }

 }


  ngAfterViewInit() {
    this.updateTextFields();

  }

  public createImagesData(): void {
    this.formStructure.forEach((el) => {
        // console.log(el.input);
      if (el.input_type === 'image' || el.input_type === 'picture') {//the same for sign
        this.data[el.name] =
          this.data[el.name] !== undefined ? this.data[el.name] : [];
        if(el.input_type === 'picture'){
            this.data[el.name] = el.placeholder;
            this.activePicture = el.placeholder;
        }
      }
      if(el.input_type === 'sign'){
          this.data[el.name] =
              this.data[el.name] !== undefined ? this.data[el.name] : '';
          this.signName = el.name;
      }
        if(el.input_type === 'pdf'){

            this.data[el.name] =
                el.placeholder;
               // this.data[el.name] !== undefined ? this.data[el.name] : '';
            this.pdfName = el.name;
        }
        if(el.input_type === 'html'){
            this.data[el.name] =
                el.placeholder.replace(/”/g, '');
            this.htmlName = el.name;
        }
    });
  }

  get sanitizedHtml() {
      return this.sanitizer.bypassSecurityTrustHtml(this.data[this.htmlName]);
  }

  public createDefaultValues(): void {
    this.formStructure.forEach((el) => {
      if (el.default_value) {
        this.data[el.name] = el.default_value;
      }
    });
  }

  public updateTextFields(): void {
    setTimeout(() => M.updateTextFields(), 0);
  }

   public addObjectAndIntervention():void{
      this.newRoute = '/baes-intervention';
      this.storageService.set('newRoute', this.newRoute);
      this.onSubmit();
   }


   public addObject():void{
      this.storageService.set('newRoute', '');
      this.onSubmit();
   }


  public onSubmit(): void {
    if (this.formAddress) {
      this.data.address = this.formAddress.name;
    }
    this.data.email = this.storageService.get('userId');

    const data: BaesMapObjectFormData = this.utils.extend({}, this.data);

    this.submitted.emit(data);
    this.notification.notify('primary', this.i18n.t("Sauvegarde en cours"));
  }

  public addImage(imgSrc: string): void {
    this.zone.run(() => {
      if(this.activePictureName)
      {
          this.data[this.activePictureName].push(imgSrc);
      }
      else {
          this.data[this.activeImageName].push(imgSrc);
      }
    });
  }


  // public addImageFromCamera(): void {
  //   this.camera
  //     .getPictureFromCamera()
  //     .then((src) => this.addImage(src))
  //     .catch(window.alert);
  // }

  // public addImageFromGallery(): void {
  //   this.camera
  //     .getPictureFromGallery()
  //     .then((src) => this.addImage(src))
  //     .catch(window.alert);
  // }

  public addImageFromCamera(): void {
    this.mobileUpload('camera');
  }

  public addImageFromGallery(): void {
    this.mobileUpload('gallery');
  }

  private mobileUpload(source: string): void {
    const method: keyof CameraService =
      source === 'camera' ? 'getPictureFromCamera' : 'getPictureFromGallery';

    this.toggleLoader('show');

    this.notification.notify('primary', this.i18n.t("Sauvegarde de l'image en cours"));
    from(this.camera[method]())
      .pipe(
        switchMap((filePath) => {
          return this.imageTransfer.uploadImage(filePath);
        })
      )
      .subscribe(
        (data) => {
          this.addImage(data);
          this.toggleLoader('hide');
        },
        (err: any) => {
          this.toggleLoader('hide');
          alert(this.i18n.t("Pas de connexion internet, impossible de télécharger l'image"));
        }
      );
  }

  public removeImage(name: string, index: number): void {
    this.data[name].splice(index, 1);
  }

  public toggleLoader(action: string): void {
    const loader: any = document.querySelector('.loader-section');

    if (loader) {
      loader.style.display = action === 'hide' ? 'none' : 'flex';
    }
  }

  public addStaticFormFields(): void {
    if (this.formStructure.length) {
      //this.formStructure.unshift(this.dueDateFormItem); // add gps item in form structure
      this.formStructure.unshift(this.gpsFormItem); // add gps item in form structure
    }
  }

  public searchLocations(): void {

    this.notification.notify('primary', this.i18n.t("Recherche GPS en cours"));
    this.googleGeocodeservice.getPlacesByCoords().subscribe(
      (data) => {
        this.convertPlacesGpsData(data.results);
        this.notification.notify('primary', this.i18n.t("Localisation trouvée"));
      },
      (error) => {
        this.notification.notify('error', this.i18n.t("Impossible de définir votre localisation GPS"));
      }
    );
  }

  public convertPlacesGpsData(data: any): void {
    this.places = [];

    this.setPlaceValue(data);
  }

  public setPlaceValue(places): void {
    this.place = places[0] ? `${places[0].formatted_address}` : '';

    this.googleGeocodeservice.changeGpsCoords(this.place);
  }

  public getAddress(place: any | undefined) {
    if (place !== undefined) {
      this.formAddress = {
        name: place.formatted_address,
        coords: {
          lat: +place.geometry.location.lat(),
          lon: +place.geometry.location.lng(),
        },
      };
    } else {
      this.formAddress = undefined;
    }
  }

  public setInitAddress(coords): void {
    const cCoords: any = {
      latitude: coords.split(',')[0],
      longitude: coords.split(',')[1],
    };
    this.googleGeocodeservice.getPlaceByExistCoords(cCoords).subscribe(
      (data) => {
        this.convertPlacesGpsData(data.results);
      },
      (error) => {
        this.notification.notify('error', this.i18n.t("Erreur lors de la géolocalisation"));
      }
    );
  }

  public openExternal(loc: string): void{
      window.location.href =loc;
  }

  public toggleModal(): void{
      this.showModal =  this.showModal ? false : true;
  }

  public removePdf() : void{
    this.data[this.pdfName] = '';
  }


    public onSignCreated(sign : string): void{
      this.data[this.signName] = sign;
    }


}
