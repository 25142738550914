import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer, of, Subject } from 'rxjs';
import { GPSService } from './gps/gps.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleGeocodeService {
  public gpsChanged: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient, private gps: GPSService) {}

  public changeGpsCoords(address: string): void {
    this.gpsChanged.next(address);
  }

  public getPlaceByExistCoords(coords: any): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.getPlacesRequest(coords).subscribe(
        (data) => {
          observer.next(data);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  public getPlacesByCoords(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.gps.getCoords().subscribe({
        next: (coords) => {
          console.info(
            'Localisation OK : ' + coords.latitude + ',' + coords.longitude
          );
          this.getPlacesRequest(coords).subscribe(
            (data) => {
              observer.next(data);
              observer.complete();
            },
            (error) => {
              observer.error(error);
            }
          );
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  public getPlacesByText(val: any): Observable<any> {
    return this.http
      .get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=1600+Amphitheatre&key=AIzaSyB0a0047UGFRW9Ejm9boarXPy6ta9HkI0s&sessiontoken=1234567890
    `);
  }

  public getPlacesRequest(coords: any): Observable<any> {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=AIzaSyB0a0047UGFRW9Ejm9boarXPy6ta9HkI0s&language=fr`
    );
  }
}
