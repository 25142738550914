export class BaesMapObjectFormData {
  public cabinet: string = '';

  public departure: string = '';

  public features: string = '';

  public id: string = '';

  public address: string | undefined = undefined;

  public created_at: string = "" ;//moment().format('YYYY-MM-DD HH:mm:ss');

  public due_date: string = "";

  public email: string = '';

  //public lampType: 'led' | 'lamp' = 'led';

  //public lampPower: number = 0;

 // public mark: string = '';

  //public model: string = '';

  //public permanent: boolean = false;

  //public pictogram: string = '';

  //public power: string = '';

  //public sati: boolean = false;

 // public standard: boolean = false;

 // public tension: number = 0;
}

