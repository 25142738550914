import { Injectable } from '@angular/core';

import { Intervention } from './intervention';
import { StorageService as LocalStorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private interventions: Map<string, Intervention>;

  private interventionsKey: string = 'interventions';

  private unsentInterventions: Map<string, any>;

  private unsentInterventionsKey: string = 'interventionsUnsent';

  constructor(
    private storage: LocalStorageService
  ) {
    const
      interventions: [string, Intervention][] | null = this.storage.get(this.interventionsKey),
      unsentInterventions: [string, any][] | null = this.storage.get(this.unsentInterventionsKey);

    this.interventions =
      interventions == null ? new Map<string, Intervention>() : new Map(interventions);
    this.unsentInterventions =
      unsentInterventions == null ? new Map<string, any>() : new Map(unsentInterventions);
  }

  public refreshInterventions(): void {
    const
      interventions: [string, Intervention][] | null = this.storage.get(this.interventionsKey),
      unsentInterventions: [string, any][] | null = this.storage.get(this.unsentInterventionsKey);

    this.interventions =
      interventions == null ? new Map<string, Intervention>() : new Map(interventions);
    this.unsentInterventions =
      unsentInterventions == null ? new Map<string, any>() : new Map(unsentInterventions);
  }

  private getId(intervention: Intervention | string): string {
    return typeof intervention === 'string' ? intervention : intervention.__id;
  }

  private saveInterventions(): void {
    this.storage.set(this.interventionsKey, [...this.interventions]);
  }

  private saveUnsentInterventions(): void {
    this.storage.set(this.unsentInterventionsKey, [...this.unsentInterventions]);
  }

  public addIntervention(intervention: Intervention): void {
    this.interventions.set(intervention.__id, intervention);
    this.saveInterventions();
  }

  public addUnsentIntervention(intervention: Intervention | string): void {
    this.unsentInterventions.set(this.getId(intervention), '');
    this.saveUnsentInterventions();
  }

  public getIntervention(id: string): Intervention | undefined {
    return this.interventions.get(id);
  }

  public getAllInterventions(): Intervention[] {
    return [...this.interventions.values()];
  }

  public getAllUnsentInterventions(): Intervention[] {
    const keys: string[] = [...this.unsentInterventions.keys()];

    return keys
      .map((key: string) => this.interventions.get(key)!)
      .filter((intervention: Intervention | undefined) => !!intervention);
  }

  public getLastIntervention(): Intervention | undefined {
    return this.getAllInterventions()[this.interventions.size - 1];
  }

  public removeIntervention(intervention: Intervention | string): void {
    if(this.interventions.delete(this.getId(intervention)) === true) {
      this.saveInterventions();
    }
  }

  public removeUnsentIntervention(intervention: Intervention | string): void {
    if(this.unsentInterventions.delete(this.getId(intervention)) === true) {
      this.saveUnsentInterventions();
    }
  }

  public updateIntervention(intervention: Intervention | string, params: Object): Intervention | null {
    const
      id: string = this.getId(intervention),
      int: Intervention | undefined = this.interventions.get(id);

    if(int != null) {
      const newInsp: Intervention = Object.assign({}, int, params);

      this.interventions.set(id, newInsp);
      this.saveInterventions();

      return newInsp;
    }
    else {
      return null;
    }
  }
}
