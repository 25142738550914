import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[appModal]',
  exportAs: 'appModal'
})
export class ModalDirective implements OnInit, OnDestroy {
  public instance: any;

  constructor(
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.instance = M.Modal.init(this.elem.nativeElement);
  }

  ngOnDestroy() {
    this.instance.close();
    this.instance.destroy();
  }
}
