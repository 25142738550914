import { AnyObject } from '../globals';

export class StorageService {
  private namespace: string;

  constructor(namespace?: string) {
    this.namespace = namespace || '';
  }

  private getNS(namespace?: string): string {
    let ns: string = namespace == null ? this.namespace : namespace;

    if (ns !== '') {
      ns += '.';
    }

    return ns;
  }

  public clear(): void {
    window.localStorage.clear();
  }

  public get(key: string | string[], namespace?: string): any | any[] {
    const ns: string = this.getNS(namespace);

    let result: any[] = [],
      singleResult: boolean = false;

    if (typeof key === 'string') {
      singleResult = true;
      key = [key];
    }

    result = key.map((k) => {
      let result: any;

      try {
        const value: string | null = window.localStorage.getItem(ns + k);

        result = typeof value === 'string' ? JSON.parse(value) : value;
      } catch (e) {
        result = null;
      }

      return result;
    });

    return singleResult ? result[0] : result;
  }

  public getNamespace(): string {
    return this.namespace;
  }

  public merge(key: string | AnyObject, val?: any, namespace?: string): void {
    let ns: string, source: AnyObject;

    if (typeof key === 'string') {
      source = {};
      source[key] = val;
      ns = this.getNS(namespace);
    } else {
      source = key;
      ns = this.getNS(val);
    }

    for (const k in source) {
      if (source.hasOwnProperty(k)) {
        const storageKey: string = ns + k,
          s: any = source[k];
        let t: any;

        // const storageKey: string = ns + k;
        // const s: any = source[k];
        // let t: any = '';

        try {
          t = JSON.parse(window.localStorage.getItem(storageKey) as string);
        } catch (e) {}

        if (
          t != null &&
          typeof t === 'object' &&
          s != null &&
          typeof s === 'object'
        ) {
          Object.assign(t, s);
          window.localStorage.setItem(storageKey, JSON.stringify(t));
        } else {
          window.localStorage.setItem(storageKey, JSON.stringify(s));
        }
      }
    }
  }

  public remove(key: string | string[], namespace?: string): void {
    const ns: string = this.getNS(namespace);

    if (typeof key === 'string') {
      key = [key];
    }

    key.forEach((k) => window.localStorage.removeItem(ns + k));
  }

  public set(key: string | AnyObject, val?: any, namespace?: string): void {
    let ns: string, source: AnyObject;
    if (typeof key === 'string') {
      source = {};
      source[key] = val;
      ns = this.getNS(namespace);
    } else {
      source = key;
      ns = this.getNS(val);
    }

    for (let k in source) {
      if (source.hasOwnProperty(k)) {
        window.localStorage.setItem(ns + k, JSON.stringify(source[k]));
      }
    }
  }

  public setNamespace(ns: string): void {
    this.namespace = ns;
  }
}
