import { Injectable, NgZone } from '@angular/core';

import { from, Observable } from 'rxjs';

import { concatAll } from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AuthorizationService } from './authorization';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  public serverURL: string;

  private get attachmentsUrl(): string {
    return `${this.serverURL}/storage/attachments/${this.uuid}`;
  }

  private get uploadUrl(): string {
    return `${this.serverURL}/api/upload`;
  }

  public imagePlaceholderUrl: string = '';

  private readonly uuid: any = window.device && window.device.uuid;

  constructor(
    private appConfig: AppConfigService,
    private auth: AuthorizationService,
    private zone: NgZone
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.imagePlaceholderUrl = this.appConfig.getImagePlaceholderUrl();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });
  }

  public upload(filePath: string): Observable<any> {
    return new Observable((observer) => {
      if (!window.cordova) {
        observer.next(this.imagePlaceholderUrl);
        observer.complete();
      } else {
        const options = new FileUploadOptions(),
          ft = new FileTransfer();

        options.fileKey = 'file';
        options.fileName = filePath.slice(filePath.lastIndexOf('/') + 1);
        options.mimeType = 'multipart/form-data';
        options.params = { uuid: this.uuid };
        ft.upload(
          filePath,
          this.uploadUrl,
          (result) => {
            const url: string = JSON.parse(result.response).status;

            this.zone.run(() => {
              observer.next(url);
              observer.complete();
            });
          },
          (error) => {
            this.zone.run(() => {
              observer.error(error);
            });
          },
          options
        );
      }
    });
  }

  public uploadFiles(files: string[]): Observable<any> {
    const observables: Observable<any>[] = files.map((file) =>
      this.upload(file)
    );

    return from(observables).pipe(concatAll());
  }
}
