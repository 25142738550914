import { Intervention } from 'src/app/core/services/intervention';

export interface HistoryIteratorItem<T> {
  hasNext: boolean;
  hasPrev: boolean;
  value: T | null;
}

export class HistoryIterator<T extends Intervention> {
  private index: number = -1;

  private items: T[] = [];

  constructor(items: T[]) {
    this.items = items;
  }

  private getItem(): HistoryIteratorItem<T> {
    return {
      hasNext: this.index < this.items.length - 1,
      hasPrev: this.index > 0,
      value: this.items[this.index] || null
    };
  }

  public next(): HistoryIteratorItem<T> {
    this.index = ++this.index % this.items.length;

    return this.getItem();
  }

  public prev(): HistoryIteratorItem<T> {
    if(--this.index < 0) {
      this.index = this.items.length - 1;
    }

    return this.getItem();
  }
}
