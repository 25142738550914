import { Component, OnInit } from '@angular/core';

@Component({
  selector: '',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  host: {
    '[className]': "'app-page'"
  }
})
export class ReportingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
