import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { Observable } from 'rxjs';

export const AppKey: string = 'keo-baes';

export const KmlRadius: number = 1.0;

export const Info: any = {
  contact_email: 'contact@kartes.io',
}

export interface AnyObject {
  [key: string]: any;
}

export interface CanComponentDeactivate {
  canDeactivate(
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ):  Observable<boolean> | Promise<boolean> | boolean;
}
