import { Directive, AfterViewInit, NgZone } from '@angular/core';

@Directive({
  selector: '[appInput]'
})
export class InputDirective implements AfterViewInit {
  constructor(
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => setTimeout(() => M.updateTextFields(), 0));
  }
}
