import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';

import { Observable, Observer, Subject } from 'rxjs';
import { I18nService } from '@i18n';
import { NotificationService } from 'src/app/core/services/notification.service';
import { HttpClient } from '@angular/common/http';
import { StorageService } from 'src/app/core/services/storage.service';
import {
  LOGIN_URL,
  SERVER_PROD_URL,
  SERVER_TEST_URL,
  COMMENT_URL,
} from '../../../server-url';


@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})

export class FeedbackFormComponent{

  private serverTestUrl: string = SERVER_TEST_URL;

  private serverProdUrl: string = SERVER_PROD_URL;

  public serverURL: string;

  
  constructor(
    public i18n: I18nService,
    private notification: NotificationService,
    private http: HttpClient,
    private storage: StorageService,
  ) {

      this.serverURL = this.getServerURL();
   }

  @ViewChild('feedbackform') feedbackform!: NgForm;

 public onSubmit(e): void { 

    var content = (<HTMLInputElement>document.getElementById('content')).value;
    const reqData: {
      id_users: string,
      comment: string,
    } = {
      id_users: this.storage.get('userId'),
      comment : content,
    };

    this.sendComment(reqData).subscribe(
      (resData) => {
        this.notification.notify('primary', this.i18n.t("Envoi du commentaire en cours !"));
        (<HTMLInputElement>document.getElementById('result')).style.display ="block";
      }
    );
  
  }



  public getActualServerURL(): string {
    return this.serverURL;
  }

  public getServerURL(login_str?: string): string {

    let url: string = '';

    if (login_str || this.storage.get('userLogin')) {
      url = this.isTestAccount(login_str)
        ? this.serverTestUrl
        : this.serverProdUrl;
    }

    return url;
  }

  public isTestAccount(login_str?: string): boolean {
    const login: string = login_str || this.storage.get('userLogin');
    return login.startsWith('test.');
  }

  public sendComment(reqData): Observable<any> {
    return this.http.post(`${this.serverURL}${COMMENT_URL}`, reqData);
  }

 
}
