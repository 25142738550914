// import * as moment from 'moment';
// import { Moment, fn, utc as moment } from 'moment/moment';
//import * as moment from 'moment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/core/services/authorization';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { ERROR_URL, SEND_GPS_URL, SERVER_PROD_URL } from '../server-url';
import { APP_VERSION } from '../version';
import { IsOnlineService } from './core/services/is-online.service';
import { LoaderService } from './core/services/loader.service';
import { MapLayersService } from './core/services/map';
import { SyncServerService } from './core/services/sync-server.service';
import { RoutingHistoryService } from './routing-history/services';
import { StorageService } from './core/services/storage.service';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GPSService, LatLng } from 'src/app/core/services/gps';
import { I18nService}  from '@i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;

  gpsStatus: any;
  gpsStatus$: Subscription = Subscription.EMPTY;
    

  public userAuthorized: boolean;

  public showOffline = true;

  public syncText = '';

  public serverURL: string;

  public syncInterval = 10; // 60 secondes
  public debugMode = true;
  public gpsDebug:string  = "";

  constructor(
    private auth: AuthorizationService,
    private sync: SyncService,
    private router: Router,
    private isOnlineService: IsOnlineService,
    private loaderService: LoaderService,
    private syncServerService: SyncServerService,
    private notification: NotificationService,
    private http: HttpClient,
    private mapLayerService: MapLayersService,
    private history: RoutingHistoryService,
    private storage: StorageService,
    private gps: GPSService,
    public i18n: I18nService,
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });

    this.getSyncInterval();
  }

  ngOnInit() {
    this.history.init();

/*    setInterval( ()=>{
      this.checkNetworkStatus();
    }, 4000);*/


    setInterval( ()=>{
      this.checkGpsStatus();
    }, 3000);
    

    if( !this.storage.get('gps_interval')){
      this.storage.set('gps_interval',30000);
    }


       setInterval( ()=>{
      this.sendGpsPosition();
    }, this.storage.get('gps_interval'));


  /*}
  else{
    setInterval( ()=>{
      this.sendGpsPosition();
    }, this.storage.get('gps_interval'));
  }*/



    this.loaderService.loaderAddText$.subscribe((text) => {
      this.syncText = text;
    });

    window.cordova
      ? (this.showOffline = this.isOnline())
      : (this.showOffline = true);

    if (!this.showOffline) {
      this.router.navigate(['/offline']);
    }

    this.userAuthorized = this.auth.isAuthorized();

    this.auth.userLoggedIn$.subscribe(() => {
      this.userAuthorized = true;

      // FIRST SYNC
      this.syncUpdates();

      this.getSyncInterval();
      console.log("interv"+this.syncInterval);
      this.sync.synchronizeWithInterval(1000 * this.syncInterval);
    });

    this.auth.userLoggedOut$.subscribe(() => (this.userAuthorized = false));

    this.sync.synchronizeWithInterval(1000 *  this.syncInterval);

    document.addEventListener('online', this.onOnline.bind(this), false);

    this.isOnlineService.showOfflineSubject.subscribe((state) => {
      this.showOffline = state;
    });

    this.syncFromServer();
  }

  public getSyncInterval(): void {
    //this.syncInterval;
/*    const interval: string = this.storage.get('syncInterval');
    console.log("interval from storage:"+interval);

    if (interval) {
      this.syncInterval = +interval;
    }*/
  }

  private syncFromServer(): void {
    const self: any = this;
    if (this.userAuthorized) {
      this.syncUpdates();
    } else {
      if (window.cordova) {
        const permissions = cordova.plugins.permissions;
        permissions.hasPermission(
          permissions.WRITE_EXTERNAL_STORAGE,
          (status) => {
            if (!status.hasPermission) {
              const errorCallback = function () {
                setTimeout(() => {
                  self.mapLayerService.setPermissionErrorState(true);
                }, 300);
                self.sendError('Error: app requires storage permission');
              };

              permissions.requestPermission(
                permissions.WRITE_EXTERNAL_STORAGE,
                () => {
                  if (!status.hasPermission) {
                    errorCallback();
                  } else {
                    self.mapLayerService.setPermissionErrorState(false);
                  }
                },
                errorCallback()
              );
            } else {
              self.mapLayerService.setPermissionErrorState(false);
            }
          },
          null
        );
      }
    }
  }

  public syncUpdates(): void {
    this.toggleLoader('show');
    this.syncServerService.updateObjectsFromServer().subscribe(
      (res) => {
        setTimeout(() => {
          this.toggleLoader('hide');
        }, 1000);
      },
      (err) => {
        setTimeout(() => {
          this.toggleLoader('hide');
        }, 1000);
        this.notification.notify('error', err);
      }
    );
  }

  private isOnline(): boolean {
    return window.navigator.connection && window.Connection
      ? window.navigator.connection.type !== window.Connection.NONE
      : false;
  }

  private onOnline(): void {
    if (this.isOnline()) {
      this.sync.synchronize().subscribe();
    }
  }

  public toggleLoader(action: string): void {
    const loader: any = document.querySelector('.synchronization-loader');

/*    if (loader) {
      loader.style.display = action === 'hide' ? 'none' : 'flex';
    }
   */
        //this.notification.notify('primary', "Synchronisation en cours");
  }

  public sendError(err: any): void {
    const data = {
      uuid: window.device && window.device.uuid,
      error: err,
      version: APP_VERSION,
    };

    this.http
      .post(`${this.serverURL}${ERROR_URL}`, data)
      .subscribe();
  }
     
  public sendGpsPosition(){
      this.gps.getCoords().subscribe({
        next: (coords) => {
          if( coords.latitude && coords.longitude ){
            const data = {
                  id_users: this.storage.get('userId'),
                  id_apps: this.storage.get('appId'),
                  lat: coords.latitude,
                  lng: coords.longitude,
                  acc: coords.accuracy,
            };
            this.http
                    .post(`${SERVER_PROD_URL}${SEND_GPS_URL}`, data)
                    .subscribe();
          }
        }
       });
  }


   public checkGpsStatus() {

    this.gps.getCoords().subscribe({
        next: (coords) => {
          if( coords.latitude && coords.longitude ){
            this.gpsStatus = true; 
          }
        },
        error: (error) => {
          this.notification.notify('error',"Connexion GPS non trouvée");
          this.gpsStatus = false; 
        }
     });
  }


   public checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
    if(!this.networkStatus){
        this.notification.notify('error',"Connexion internet non trouvée");
    }
  }
}
