import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BaesIntervention } from 'src/app/core/services/intervention';
import { HistoryIterator, HistoryIteratorItem, HistoryStorageService } from 'src/app/core/services/history-intervention';
import { BaesMapObject, MapObjectStorageService } from 'src/app/core/services/map-object';
import { RoutingHistoryService } from '../../routing-history/services';
import { StorageService } from '../../core/services/storage.service';
import { I18nService}  from '@i18n';

@Component({
  selector: '',
  templateUrl: './baes-history-intervention.component.html',
  styleUrls: ['./baes-history-intervention.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class BaesHistoryInterventionComponent implements OnInit {
  private historyIterator: HistoryIterator<BaesIntervention> | null = null;

  public history: HistoryIteratorItem<BaesIntervention> | null = null;

  public oId: string = '';

  public backButtonVisible: boolean;

  public object: any | undefined;

  public typeLib: any;

  public interventionId: string;

  constructor(
    private historyStorage: HistoryStorageService,
    private mapObjectStorage: MapObjectStorageService,
    private route: ActivatedRoute,
    private routerHistory: RoutingHistoryService,
    private storageService: StorageService,
    public i18n: I18nService,
  ) {}

  ngOnInit() {
    const objectId: string | null = this.route.snapshot.queryParamMap.get('objectId');
    this.typeLib = this.storageService.get('typeLib') || [];

    if(objectId != null) {
      this.object =
        <BaesMapObject>this.mapObjectStorage.getObject(objectId);

      if(this.object != null) {
        this.oId = this.object.id;
      }

      this.historyIterator = this.historyStorage.getHistory<BaesIntervention>(this.object);
      this.history = this.historyIterator.next();
      this.interventionId = this.getInterventionID();
    }

    this.backButtonVisible = this.route.snapshot.queryParamMap.get('back') === 'map' ? true : false;
  }

  public onNext(): void {
    if(this.historyIterator != null) {
      this.history = this.historyIterator.next();
    }
  }

  public onPrev(): void {
    if(this.historyIterator != null) {
      this.history = this.historyIterator.prev();
    }
  }

  public onBack(): void {
    this.routerHistory.back();
  }

  private getInterventionID(): string {
    let id: string = '';
    
    if (this.typeLib) {
      const typeLibId: string | undefined = this.object.id_object_type;
      const typeLib: any = this.typeLib.find((type:any) => {
        return type.id === typeLibId
      })
      id = typeLib ? typeLib.id_intervention : '';
    }
    return id;  
  }
}
