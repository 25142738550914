import { RequestService } from './../filter-service/request.service';
import {
  Inspection,
  InspectionQualityChecker,
} from 'src/app/core/services/inspection';
import { LatLng } from 'src/app/core/services/gps';
import { MapObject } from 'src/app/core/services/map-object';
import { MapState, MapElements } from 'src/app/core/services/map';
import { forEach } from '@angular/router/src/utils/collection';

type MapObjectWithInspection = [MapObject, Inspection | null];

interface InspectionMarker {
  inspection: Inspection | null;
  mapMarker: any;
  object: any;
}

interface Layers {
  [key: string]: any;
}

export interface MapOptions {
  id?: string;
  layers?: Layers;
  objects: MapObjectWithInspection[];
  onLayerAdded?: (key: string, layer: any) => void;
  onMapClick?: (e: any) => void;
  onMarkerClick?: (e: any) => void;
  onZoomEnd?: (e: any) => void;
  getPinIcon?: (e: any) => string;
  getPinColor?: (e: any) => string;
  getStatusOptions?: (e: any) => string;
  getHtmlView?: (e: any) => string;
  qualityChecker: InspectionQualityChecker;
  state: MapState;
  mapStyle?: string ;
  onStyleClick?: (e: any) => void;
}

export class Map {
  private currentLayer: string;

  private mapElementsVisibility: MapElements;


  private markerCluster: any;

  private locationMarker: any;

  private elem: HTMLElement;

  private map: any;

  private mapStyle : any;

  private inspectionMarkers: InspectionMarker[];

  private objects: MapObjectWithInspection[];

  private options: MapOptions;

  public layers: Layers;

  private popup: any = null;

  private qualityChecker: InspectionQualityChecker;

  public allObject: any[];

  public request: RequestService;

  constructor(options: MapOptions) {

    if(!options.mapStyle){
      options.mapStyle="streets-v12";
    }

    this.options = options;
    this.currentLayer = this.options.state.currentLayer;
    this.objects = options.objects;

    this.allObject = this.objects.map((test) => {
      return test[0];
    });

    this.mapElementsVisibility = Object.assign(
      {},
      this.options.state.elementsVisibility
    );

    this.qualityChecker = options.qualityChecker;
    this.inspectionMarkers = this.createInspectionMarkers();
    this.elem = document.getElementById(options.id || 'map')!;
    this.layers = this.options.layers || {};
    this.onMapClick = this.onMapClick.bind(this);
    this.getPinIcon = this.getPinIcon.bind(this);
    this.getPinColor= this.getPinColor.bind(this);
    this.getStatusOptions= this.getStatusOptions.bind(this);
    this.getHtmlView= this.getHtmlView.bind(this);
    this.onMapZoomEnd = this.onMapZoomEnd.bind(this);
    this.onMarkerClick = this.onMarkerClick.bind(this);
//    this.onStyleClick= this.onStyleClick.bind(document.querySelectorAll('#menu-map input'));
    this.onStyleClick= this.onStyleClick.bind(this);

    for (const key in this.layers) {
      if (this.layers.hasOwnProperty(key) && options.state.cache[key] != null) {
        this.layers[key] = options.state.cache[key];
      }
    }
    this.initMap();
    this.toggleMap(options.state.mapVisible);
    this.showLayer(options.state.currentLayer);
    this.updateMarkersVisibility();
  }

  private createInspectionMarkers(): InspectionMarker[] {
    return this.objects.map((item) => {
      return {
        inspection: item[1],
        mapMarker: this.createMapMarker(item[0], item[1]),
        object: item[0],
      };
    });
  }

  // *** MARKER OBJECT ON MAP
  private createMapMarker(
    mapObject: MapObject,
    inspection: Inspection | null
  ): any {
    const iconClass: string = this.getPinIcon(mapObject);
    const iconColor: string = this.getPinColor(mapObject);
    const statusOptions: string = this.getStatusOptions(mapObject);
    var status='white';
    var bg='white';
    var x=32;
    var y=32;



    type parsedTypeOptions = {
      key: string,
      color: string,
      border_color: string,
    }

    // np if used object_type.status_options
    if(mapObject && mapObject.status != null && statusOptions != null){
        let l = statusOptions.replace(/\n/g,'');
        const parsedStr = JSON.parse(l.replace(/'/g, '"')) as parsedTypeOptions[];
        let item1 = parsedStr.find(i => i.key === mapObject.status);
        status = item1 ? item1.border_color : "";
        bg = item1 ? item1.color : "";
    }
    else if (mapObject && mapObject.status != null) {
      if(mapObject.status == '1'){
          status = 'red';
          bg = iconColor;
      }
      else if(mapObject.status == '2'){
          status = 'green';
          bg = iconColor;
      }
      else if(mapObject.status == '3'){
          status = 'white';
          bg =iconColor;
      }
     else if(mapObject.status == '4'){
          status = 'green';
          bg = iconColor;
      }

      else{
          status = 'white';
          bg = iconColor;
      }
    }
    else{
      status = 'white';
      bg = iconColor;
    }

    const icon: any = L.divIcon({
        className: this.getMapPinCssClass(mapObject),
        html: `<div style="width: 30px; height: 30px; ">
          <div class="inspection-marker-shape" style="background-color:${bg}; border:4px solid ${status}" >
            <i class="${iconClass}" aria-hidden="true"></i>
          </div>
          <h3 style="text-align: center; font-size: 9px;"></h3>
	  <div class="inspection-marker-shape-arrow-container"><div class="inspection-marker-shape-arrow"></div></div>
        </div>
        `,
        iconSize: [x, y],
      }),
      marker: any = L.marker([mapObject.coords.lat, mapObject.coords.lng], {
        icon: icon,
      });

    marker.__mapObject = mapObject;
    marker.on('click', (e) => this.onMarkerClick(e));

    return marker;
  }

  private getGeoJsonFeatureColor(feature: any): string {
    return this.strToKmlColor(feature.properties.stroke)[0] ;
  }

  private getGeoJsonLayer(): any {
    return L.geoJson(null, {
      // filter: (feature) => feature.geometry.type === 'LineString',
      pointToLayer: function (feature, latlng) {
        if (
          feature.properties.style &&
          feature.properties.style.match(/<href>(.+?)<\/href>/)
        ) {
          let pin: any;
          const redIcon: any = L.icon({
            iconUrl: './assets/images/images/pin.png',
            iconSize: [25, 25],
            iconAnchor: [12, 55],
          });
          pin = L.marker(latlng, { icon: redIcon });
          if (feature.properties.name) {
            pin.bindPopup(feature.properties.name);
          }
          return pin;
        }
      },
      style: (feature) => ({ color: "#"+this.getGeoJsonFeatureColor(feature)+"AA",weight:2 }),
    });
  }

  private getMapMarkerCssClass(
    inspection: Inspection | null,
    type: string
  ): string {
    const color: string = this.qualityChecker.getColor(inspection);

    return `inspection-marker inspection-marker_${color} ${type}`;
  }

  private getMapPinCssClass(object: MapObject | null): string {
    const state: string = this.qualityChecker.getPinState(object);
    return `inspection-marker inspection-marker_${state}`;
  }

  private initMap(): void {
    ((options: any) => {
      options.iconRetinaUrl =
        options.iconUrl =
        options.shadowUrl =
          'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    })(L.Icon.Default.prototype.options);

    this.map = L.map(this.elem, { preferCanvas: true });
    //this.markerCluster = L.markerClusterGroup();

	// np custom marker cluster
    	var markersClusterCustomPlus = new L.MarkerClusterGroup({
          disableClusteringAtZoom: 16,
	iconCreateFunction: function(cluster) {
		var digits = (cluster.getChildCount()+'').length;
		return L.divIcon({
			html: cluster.getChildCount(),
			className: 'mycluster mycluster-'+digits,
			iconSize: null
		});
	}
	});
	this.markerCluster=markersClusterCustomPlus;

     L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
          accessToken: 'pk.eyJ1IjoiaDIweCIsImEiOiJjamtoenIxYjcxMDQwM3FxaDJqNnF2d3F4In0.RCPP1Wm4EO9IiAoGXkOBhg',
          attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
          tileSize: 512,
          format: 'jpg70',
          maxZoom: 25,
          zoomOffset: -1,
          id: 'mapbox/'+this.options.mapStyle,
        }
    ).addTo(this.map);

  const allStyles= Array.from(document.querySelectorAll('#menu-map input'));
  for (const sub of allStyles){
    const inputsub= document.getElementById(sub.id) as HTMLElement | null;
      if(inputsub){
        inputsub.onclick = (layer) => {
            this.onStyleClick(inputsub.id);

		        this.options.mapStyle=inputsub.id;
              L.tileLayer( 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
              {
                accessToken:
                'pk.eyJ1IjoiaDIweCIsImEiOiJjamtoenIxYjcxMDQwM3FxaDJqNnF2d3F4In0.RCPP1Wm4EO9IiAoGXkOBhg',
                attribution:
                '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
                tileSize: 512,
                maxZoom: 25,
                format: 'jpg70',
                zoomOffset: -1,
                id: 'mapbox/'+this.options.mapStyle,
              }
            ).addTo(this.map);
        }
      }
    }



/*  const allStyles= Array.from(document.querySelectorAll('#menu-map input'));

  for (const sub of allStyles){
	const inputsub= document.getElementById(sub.id ) as HTMLElement | null;
	if( inputsub){
	inputsub.onclick = (layer) => {
		this.options.mapStyle=inputsub.id;

		    L.tileLayer(
		      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
		      {
			accessToken:
			  'pk.eyJ1IjoiaDIweCIsImEiOiJjamtoenIxYjcxMDQwM3FxaDJqNnF2d3F4In0.RCPP1Wm4EO9IiAoGXkOBhg',
			attribution:
			  '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
			tileSize: 512,
			maxZoom: 25,
			format: 'jpg70',
			zoomOffset: -1,
			id: 'mapbox/'+this.options.mapStyle,
		      }
		    ).addTo(this.map);
		};
	}

  }
  */

//     const osm: any = new OSMBuildings(this.map).load('https://{s}.data.osmbuildings.org/0.2/anonymous/tile/{z}/{x}/{y}.json');

    this.map.addLayer(this.markerCluster);
    this.map
      .on('click', (e) => this.onMapClick(e))
      .on('zoomend', (e) => this.onMapZoomEnd(e));
  }

  private onMapClick(e: any): void {
    if (this.options.onMapClick != null) {
      this.options.onMapClick(e);
    }
  }

  private onMarkerClick(e: any): void {
    if (this.options.onMarkerClick != null) {
      this.options.onMarkerClick(e);
    }
  }

  private onStyleClick(e: any): void {
    if (this.options.onStyleClick!= null) {
      this.options.onStyleClick(e);
    }
  }



  private onMapZoomEnd(e: any): void {
    if (this.options.onZoomEnd != null) {
      this.options.onZoomEnd(e);
    }
  }

  private strToKmlColor(str?: string): [string, number] {
    // on court circuite cela pour gagner du temps d'exec
    return ['#000000', 1];
  /*   console.log("Couleur : "+str);
    let color = '',
      opacity = 1;

    str = str || '';

    if (str.substr(0, 1) === '#') {
      str = str.substr(1);
    }

    if (str.length === 6 || str.length === 3) {
      color = str;
    }

    if (str.length === 8) {
      opacity = parseInt(str.substr(0, 2), 16) / 255;
      color = '#' + str.substr(6) + str.substr(4, 2) + str.substr(2, 2);
    }
	console.log('KML COLOR:'+color);
	console.log('KML OPACTITY:'+opacity);
    return [color, isNaN(opacity) ? 1 : opacity];
*/
  }

  public updateMarkersVisibility(): void {
    const self = this;
    const markersToShow: InspectionMarker[] = [];
    const markersToHide: InspectionMarker[] = [];

    this.inspectionMarkers.forEach((m) => {
      isNeedToShow(m) ? markersToShow.push(m) : markersToHide.push(m);
    });

    markersToShow.forEach((m) => this.addMarker(m.mapMarker));
    markersToHide.forEach((m) => this.removeMarker(m.mapMarker));

    function isNeedToShow(marker: InspectionMarker): boolean {
      return self.mapElementsVisibility[marker.object.id_object_type];
      /* && self.currentLayer === `${marker.object.country}.$
      {marker.object.city}.${marker.object.building}.${marker.object.part}.${marker.object.floor}`; */
    }
  }

  public addMarker(marker: any): void {
    if (marker._latlng) {
      this.markerCluster.addLayer(marker);
    }
  }

  public closePopup(): void {
    this.map.closePopup(this.popup);
    this.popup = null;
  }

  public destroy(): void {
    this.map.remove();
  }

  public hideLayer(type: string): void {
    const layer: any = this.layers[type];
    if (
      layer != null &&
      typeof layer !== 'string' &&
      this.map.hasLayer(layer) === true
    ) {
      this.map.removeLayer(layer);
    }
  }

  public hideLayers(): void {
    Object.keys(this.layers).forEach((key) => {
      this.hideLayer(key);
    });
  }

  public hideMap(): void {
    this.elem.classList.add('hidden');
  }

  public openPopup(coords: LatLng, content: string | HTMLElement): void {
    this.popup = L.popup({
      closeButton: true ,
    	maxHeight: 600,
    	maxWidth: 320,
      keepInView: true,
      className: 'leaflet-popup-menu',
    })
      .setLatLng(coords)
      .setContent(content)
      .openOn(this.map);
  }

  public removeMarker(marker: any): void {
    this.markerCluster.removeLayer(marker);
  }

  public showLayer(type: string): void {
    const layer: any = this.layers[type];
    if (layer != null) {
      if (typeof layer === 'string') {
        this.layers[type] = omnivore
          .kml(layer, null, this.getGeoJsonLayer())
          .addTo(this.map);
   	   this.layers[type].setStyle({
            color: '#ff6e6e6e',
            weight: 4
          });
        if (this.options.onLayerAdded != null) {
          this.options.onLayerAdded(type, this.layers[type]);

        } // GeoJSON layer
      } else {
        if (this.map.hasLayer(layer) === false) {
          this.map.addLayer(layer);
        }
      }
    }
  }

  public showLayers(): void {
    Object.keys(this.layers).forEach((key) => this.showLayer(key));
  }

  public showLocation(coords: LatLng, zoom: number = 10): void {
    setTimeout(() => this.map.setView([coords.lat, coords.lng], zoom), 0);
  }

  public showMap(): void {
    this.elem.classList.remove('hidden');
  }

  public toggleLayer(show: { [key: string]: boolean }): void;
  public toggleLayer(show: boolean, type: string): void;
  public toggleLayer(show: any, type?: any): void {
    if (typeof show === 'object') {
      const keys: string[] = Object.keys(show);

      keys.forEach((key) => this.toggleLayer(show[key], key));
    } else {
      show === true ? this.showLayer(type) : this.hideLayer(type);
    }
  }

  public toggleLayers(show: boolean): void {
    show === true ? this.showLayers() : this.hideLayers();
  }

  public toggleMap(show: boolean): void {
    show === true ? this.showMap() : this.hideMap();
  }

  public togglePopup(coords: LatLng, content: string | HTMLElement): void {
    this.popup == null ? this.openPopup(coords, content) : this.closePopup();
  }

  public updateState(prop: { key: string; value: any }): void {
    switch (prop.key) {
      case 'currentLayer':
        this.currentLayer = prop.value;
        // this.hideLayers();
        this.showLayer(prop.value);
        this.updateMarkersVisibility();
        break;

      case 'currentLayerHide':
        this.currentLayer = prop.value;
        // this.hideLayers();
        this.hideLayer(prop.value);
        this.updateMarkersVisibility();
        break;

      case 'hideAllLayers':
        this.hideLayers();
        break;

      case 'zoom':
        break;

      case 'baes':
      case 'es':
        this.mapElementsVisibility[prop.key] = prop.value;
        this.updateMarkersVisibility();
        break;

      case 'mapVisible':
        this.toggleMap(prop.value);
        break;

      case 'layersVisible':
        prop.value ? this.showLayer(this.currentLayer) : this.hideLayers();
        break;

      default:
        this.mapElementsVisibility[prop.key] = prop.value;
        this.updateMarkersVisibility();
    }
  }
  public refreshMarkers(objects: [MapObject, Inspection | null][]): void {
    this.markerCluster.clearLayers();
    this.objects = objects;
    this.inspectionMarkers = this.createInspectionMarkers();

    this.updateMarkersVisibility();
  }

  public showCurrentLocation(coords: LatLng, setView: boolean = true): void {
    if (this.locationMarker) {
      this.locationMarker.setLatLng(coords);
    } else {
      const icon = L.icon({
        iconUrl: './assets/images/icons/location-marker.png',
        iconSize: [30, 30],
        className: 'location-pin',
      });

      L.HtmlIcon = L.Icon.extend({
        options: {
          /*
		html: (String) (required)
		iconAnchor: (Point)
		popupAnchor: (Point)
		*/
        },

        initialize: function (options) {
          L.Util.setOptions(this, options);
        },

        createIcon: function () {
          const div = document.createElement('div');
          div.innerHTML = this.options.html;
          return div;
        },

        createShadow: function () {
          return null;
        },
      });

      const HTMLIcon = L.HtmlIcon.extend({
        options: {
          html: '<div class="location-pin"></div>',
        },
      });

      const customHtmlIcon = new HTMLIcon();

      this.locationMarker = L.marker(coords, {
        icon: customHtmlIcon,
      });

      this.map.addLayer(this.locationMarker);
    }
    setView ? this.map.setView(coords, 18) : null;
  }

  public removeCurrentLocation(): void {
    /*if (this.locationMarker && false) {
      this.map.removeLayer(this.locationMarker);
      this.locationMarker = undefined;
    }*/
  }

  public getPinIcon(e: any): string {
    let iconClass = '';

    if (this.options.getPinIcon != null) {
      iconClass = this.options.getPinIcon(e);
    }

    return iconClass;
  }

  public getPinColor(e: any): string {
    let iconColor= '';

    if (this.options.getPinColor!= null) {
      iconColor= this.options.getPinColor(e);
    }

    return iconColor;
  }

 public getStatusOptions(e: any): string {
    let statusOptions= '';
    if (this.options.getStatusOptions!= null) {
      statusOptions= this.options.getStatusOptions(e);
    }
    return statusOptions;
  }

 public getHtmlView(e: any): string {
    let htmlView= '';
    if (this.options.getHtmlView!= null) {
      htmlView= this.options.getHtmlView(e);
    }
    return htmlView;
  }




}
