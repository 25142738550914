import { Component, OnInit } from '@angular/core';
import { I18nService}  from '@i18n';
import { forkJoin } from 'rxjs';
import { PaginationService } from '../../core/services/pagination.service';
import { FiltersService } from '../../core/services/filters.service';
import { NotificationService } from '../../core/services/notification.service';
import { FilterStructure } from '../../core/types/filter';
import { SyncServerService } from '../../core/services/sync-server.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class FiltersComponent implements OnInit {
  public filtersStructure: FilterStructure[] = [];

  public filtersData: any = {};

  public loading: boolean = false;

  constructor(
    private filtersService: FiltersService,
    private notification: NotificationService,
    private paginationService: PaginationService,
    private syncServerService: SyncServerService,
    public i18n: I18nService, 
  
  ) { }

  ngOnInit() {
    this.loading = true;
    forkJoin(
      this.filtersService.getFiltersStructure(), 
      this.filtersService.getFiltersData()
    ).subscribe({
      next: ([structure, data]) => {
        this.filtersStructure = structure;
        this.filtersData = data;
        this.loading = false;
      }
    });
  }

  public onSave(data: any): void {
    this.filtersService.sendFiltersData(data).subscribe(
      (data)=> {
        this.notification.notify('primary', this.i18n.t("Filtres enregistrés, synchronisation en cours ..."));
      },
      (err)=> {
        this.notification.notify('error',this.i18n.t("Erreur de serveur"));
      }
    );
    this.paginationService.clearPages();


      this.syncServerService.updateObjectsFromServer().subscribe(
      (res) => {
        console.log("synchronisation terminée");
      },
      (err) => {
         this.notification.notify('primary', this.i18n.t("Erreur de Synchronisation"));

      }
    );

  }
}
