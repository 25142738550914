import { Injectable } from '@angular/core';

import { MapObject, UserPosition } from './map-object';
import { StorageService as LocalStorageService } from '../storage.service';
import { BaesMapObject } from './baes/map-object';
import { Observable, Observer, Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AuthorizationService } from '../authorization/authorization.service';
import { FiltersService } from '../filters.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private marks: Map<string, any> = new Map<string, any>();

  private models: Map<string, any> = new Map<string, any>();

  private objects: Map<string, MapObject>;

  private objectsKey = 'mapObjects';

  private unsentObjects: Map<string, any>;

  private unsentObjectsKey = 'mapObjectsUnsent';

  public objectsUpdated = new Subject();

  public addedObjects  = new Map();

  constructor(
    private storage: LocalStorageService,
    private notification: NotificationService,
    private auth: AuthorizationService,
    private filterService: FiltersService
  ) {
    this.initData();
    this.auth.userLoggedIn$.subscribe((data) => this.initData());
    this.auth.userLoggedOut$.subscribe((data) => this.destroyData);
  }

  public destroyData(): void {
    this.objects = new Map([]);
    this.unsentObjects = new Map([]);

    this.marks = new Map([]);
    this.models = new Map([]);
    this.addedObjects = new Map();
  }

  public initData(): void {
    const objects: [string, MapObject][] =
      this.storage.get(this.objectsKey) || [];
    const unsentObjects: [string, any][] =
      this.storage.get(this.unsentObjectsKey) || [];
    this.addedObjects = new Map();

    // Old objects fix
    objects.concat(unsentObjects).forEach((item) => {
      const o: MapObject = item[1];

      // Fix for: "Can existant BAES be associated with RDC + Poudrette (not to loose data) ?"
      o.country = o.country || 'fra';
      o.city = o.city || 'lyon';
      o.building = o.building || 'poudrette';
      o.part = o.part || 'all';
      o.floor = o.floor || 'rdc';

      // Add two more fields: Puissance, Tension
      if (o.type === 'baes') {
        const baes: BaesMapObject = <BaesMapObject>o;

        // baes.lampPower = baes.lampPower || 0;
        // baes.tension = baes.tension || 0;
      }
    });

    this.objects = new Map(objects);
    this.unsentObjects = new Map(unsentObjects);

    this.marks = this.getProps('mark');
    this.models = this.getProps('model');
  }

  public refreshObjects(): void {
    const objects: [string, MapObject][] =
      this.storage.get(this.objectsKey) || [];
    const unsentObjects: [string, any][] =
      this.storage.get(this.unsentObjectsKey) || [];

    // Old objects fix
    objects.concat(unsentObjects).forEach((item) => {
      const o: MapObject = item[1];

      // Fix for: "Can existant BAES be associated with RDC + Poudrette (not to loose data) ?"
      o.country = o.country || 'fra';
      o.city = o.city || 'lyon';
      o.building = o.building || 'poudrette';
      o.part = o.part || 'all';
      o.floor = o.floor || 'rdc';

      // Add two more fields: Puissance, Tension
      if (o.type === 'baes') {
        const baes: BaesMapObject = <BaesMapObject>o;

        // baes.lampPower = baes.lampPower || 0;
        // baes.tension = baes.tension || 0;
      }
    });

    this.objects = new Map(objects);
    this.unsentObjects = new Map(unsentObjects);

    this.marks = this.getProps('mark');
    this.models = this.getProps('model');
  }

  private getId(object: MapObject | string): string {
    return typeof object === 'string' ? object : object.__id;
  }

  private getProps(prop: string): Map<string, any> {
    const result: Map<string, any> = new Map<string, any>();

    for (const object of this.objects.values()) {
      const val: string | undefined = (<any>object)[prop];

      if (typeof val === 'string') {
        result.set(val, '');
      }
    }

    return result;
  }

private saveObjects(): void {
    this.storage.set(this.objectsKey, [...this.objects]);
  }

  private clearObjects(): void {
    this.storage.set(this.objectsKey, '');
  }

  private saveUnsentObjects(): void {
    this.storage.set(this.unsentObjectsKey, [...this.unsentObjects]);
  }

  private updateMarksAndModels(object: MapObject): void {
    const obj: any = <any>object;

    if (obj.mark != null) {
      this.marks.set(obj.mark, '');
    }

    if (obj.model != null) {
      this.models.set(obj.model, '');
    }
  }

  public addObject(object: MapObject): void {

    let last_mod = "";
    let value = "";

    value = this.addedObjects.get(object.__id);   

    // si updated_at est null, on ne peut pas cacher

    if((value && object['updated_at'] && object['updated_at']==value) ){
      // en memoire
    }
    else{
      this.objects.set(object.__id, object);
      this.updateMarksAndModels(object);
      this.saveObjects();
      this.addedObjects.set(object.__id, object['updated_at']); 
    }
  }

  public addUnsentObject(object: MapObject | string): void {
    this.unsentObjects.set(this.getId(object), '');
    this.saveUnsentObjects();
  }

  // public getLastObject(): MapObject | undefined {
  //   return this.getAllObjects();
  // }

  public getObject(id: string): MapObject | undefined {
    return this.objects.get(id);
  }

  public getAllObjectsClean(): MapObject[] {
    const objects: MapObject[] = [...this.objects.values()];
    return objects;
  }

  public getAllObjects(
    type?: string,
    all: boolean = false
  ): Observable<MapObject[]> {
    const objects: MapObject[] = [...this.objects.values()];
    let data: MapObject[] = [];

    return Observable.create((observer: Observer<any>) => {
      data = objects.filter((object) => {
        const sameType: boolean = type ? object.id_object_type === type : true;
        return (
          sameType &&
          (all ? true : this.filterService.filter('object', object)) &&
          object.status !== 'deleted'
        );
      });

      observer.next(data);
      observer.complete();
    });
  }

  public getAllUnsentObjects(): MapObject[] {
    const keys: string[] = [...this.unsentObjects.keys()];

    return keys
      .map((key: string) => this.objects.get(key)!)
      .filter((object: MapObject | undefined) => !!object);
  }

  public getMarks(): string[] {
    return [...this.marks.keys()];
  }

  public getModels(): string[] {
    return [...this.models.keys()];
  }

  public removeObject(object: MapObject | string): void {
    if (this.objects.delete(this.getId(object)) === true) {
      this.saveObjects();
    }
  }

  public removeUnsentObject(object: MapObject | string): void {
    if (this.unsentObjects.delete(this.getId(object)) === true) {
      this.saveUnsentObjects();
    }
  }

  public updateObject(
    object: MapObject | string,
    params: Object
  ): MapObject | null {
    const id: string = this.getId(object),
      obj: MapObject | undefined = this.objects.get(id);

    if (obj != null) {
      const newObj: MapObject = Object.assign({}, obj, params);

      this.objects.set(id, newObj);
      this.updateMarksAndModels(newObj);
      this.saveObjects();

      return newObj;
    } else {
      return null;
    }
  }
}
