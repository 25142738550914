import { RequestService } from './../../core/services/filter-service/request.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable, observable, Observer, Subscription } from 'rxjs';

import { GPSService, LatLng } from 'src/app/core/services/gps';
import { HistoryStorageService } from 'src/app/core/services/history';
import {
  Inspection,
  InspectionQualityChecker,
  InspectionStorageService,
} from 'src/app/core/services/inspection';
import {
  MapObject,
  MapObjectDataTransferService,
  MapObjectStorageService,
} from 'src/app/core/services/map-object';
import { Map, MapState, MapStateService } from 'src/app/core/services/map';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/map-object/storage.service';
import { MapActionsService } from 'src/app/core/services/map/map-actions.service';

import { StorageService as LocalStorageService } from 'src/app/core/services/storage.service';
import { MapLayersService } from '../../core/services/map';
import { MatMenuItem } from '@angular/material';
import { PermissionService } from 'src/app/core/services/permission.service';
import { FiltersService } from '../../core/services/filters.service';
import { I18nService}  from '@i18n';

interface CurrentState {
  key: string;
  value: string;
}

interface MenuMap {
  name: string;
  permission: string | null;
  history:
    | {
        permission: string;
        controller: Function;
      }
    | any;
  controller: Function | null;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  public map: Map;

  private mapStateSubscription: Subscription;

  private removeKmlsLayersSubscription: Subscription;

  public findGeolocationSubscription: Subscription;

  private watchGeolocationSubscription: Subscription;

  private setMapViewSubscription: Subscription;

  private objectsUpdatedSubscription: Subscription;

  private markerInMove: any;

  private mapObjects: [MapObject, Inspection | null][];

  public mapHidden = false;

  public storageLayers: Object[];

  public layers: Object = {};

  public currentLayerState: CurrentState;

  public typeLib: any[];

  public object_types: any[];

  public watchGeolocationInterval: any;

  private watchLocationActive = false;

  public idObject: string;

  public GPSattempt: number;

  public GPSStatus: boolean;


  private prevLocationCoords: any = {
    latitude: 0,
    longitude: 0,
  };

  constructor(
    private gps: GPSService,
    private historyStorage: HistoryStorageService,
    private inspectionStorage: InspectionStorageService,
    private mapObjectDataTransfer: MapObjectDataTransferService,
    private mapObjectStorage: MapObjectStorageService,
    private mapService: MapStateService,
    private notification: NotificationService,
    private router: Router,
    private mapStorageService: StorageService,
    private mapActions: MapActionsService,
    private localStorageService: LocalStorageService,
    private layersService: MapLayersService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private requestService: RequestService,
    private filterService: FiltersService,
    public i18n: I18nService,
  ) {
    this.typeLib = this.localStorageService.get('typeLib') || [];
    this.object_types = this.localStorageService.get('object_types') || [];
    this.storageLayers = this.layersService.getLayers();
    this.currentLayerState = this.mapService.getCurrentState();
    this.layersService.updateLayers$.subscribe((layers) => {
      this.storageLayers = this.layersService.getLayers();
      this.currentLayerState = this.mapService.getCurrentState();
      this.layers = {};
      layers.forEach((layer: any) => {
        this.map.hideLayers();
        this.map.layers[layer.kmlName] = layer.url;
      });
    });
  }

  ngOnInit() {
    const mapState: MapState = this.mapService.getState();

    const mapStyle: string = this.localStorageService.get('mapStyle') || "streets-v12";

    this.GPSattempt = 0;

    this.getMapObjects().subscribe((data) => {
      this.mapObjects = data;
      if (this.storageLayers && this.storageLayers.length) {
        this.storageLayers.forEach((layer: any) => {
          this.layers[layer.kmlName] = layer.url;
        });
      }

      this.typeLib.forEach((type) => {
        mapState.elementsVisibility[type.id] = true;
      });


        //this.watchLocationActive = true;
     this.watchGeolocation();
     this.findGeolocation();




      this.map = new Map({
        id: 'map',
        layers: this.layers,
        objects: this.mapObjects,
        onLayerAdded: (type: string, layer: any) =>
          this.onMapLayerAdded(type, layer),
        onMapClick: (e) => this.onMapClick(e),
        onMarkerClick: (e) => this.onMarkerClick(e),
        onZoomEnd: (e) => this.onMapZoomEnd(e),
        getPinIcon: (e) => this.getPinIcon(e),
        getPinColor: (e) => this.getPinColor(e),
        getStatusOptions: (e) => this.getStatusOptions(e),
        getHtmlView: (e) => this.getHtmlView(e),
        qualityChecker: new InspectionQualityChecker(),
        state: mapState,
        mapStyle: mapStyle,
        onStyleClick: (e) => this.onStyleClick(e)
      });

      this.mapStateSubscription = this.mapService.state$.subscribe((prop) => {
        this.map.updateState(prop);
      });

      this.removeKmlsLayersSubscription = this.mapActions.removeKmls$.subscribe(
        (prop) => {
          this.map.hideLayers();
        }
      );

      this.showGeolocation();
      this.showCurrentLocation();

      if (
        this.currentLayerState.key.length &&
        this.currentLayerState.value.length
      ) {
        this.mapService.changeState(
          this.currentLayerState.key,
          this.currentLayerState.value
        );
      }
    });

    this.objectsUpdatedSubscription =
      this.mapStorageService.objectsUpdated.subscribe(() => {
        this.getMapObjects().subscribe((data) => {
          this.mapObjects = data;
          if(this.mapObjects && this.map){
            this.map.refreshMarkers(this.mapObjects);
          }
        });
      });

    this.findGeolocationSubscription =
      this.mapActions.findGeolocation$.subscribe(() => this.showGeolocation());

    this.watchGeolocationSubscription =
      this.mapActions.watchGeolocation$.subscribe(() =>
        this.watchGeolocation()
      );

    this.setMapViewSubscription = this.mapActions.setMapView$.subscribe(
      (coords: LatLng) => {
        this.map.showLocation(coords, 17);
      }
    );
  }

  ngOnDestroy() {
    this.findGeolocationSubscription.unsubscribe();
    this.setMapViewSubscription.unsubscribe();
    this.mapStateSubscription.unsubscribe();
    this.objectsUpdatedSubscription.unsubscribe();
    this.watchGeolocationSubscription.unsubscribe();
    this.map.destroy();
  }

  private createMapPopupMenu(latlng: LatLng): HTMLElement {

    const elem = document.createElement('ul');


    if ( !this.permissionService.hasPermission('can_add')) {
      return elem;
    }

    /*NP : If can_edit and has only 1 object, edit without displaying menu*/
    if ( this.permissionService.hasPermission('can_edit')) {
      if(Object.keys(this.object_types).length==1){

         setTimeout(() => {
            this.map.closePopup();
            this.onMenuBaes(latlng, this.typeLib[0])
          }, 0);

        return elem;
      }
    }

    elem.className = 'leaflet-popup-menu-list';

    elem.innerHTML +='<center><b style="font-size:21px;">'+this.i18n.t("Ajouter un élément")+'</b></center><br/><ul class="center">';

     Object.keys(this.object_types).forEach((key) => {
      elem.innerHTML +=
          '<li style="display:inline;"><button class="addElement" style="text-align:left;width:80%;border-radius: 4px ;border:1px solid lightgray; ;font-size:14px;padding:6px;margin-top:16px;"><i  style="margin-right:14px;padding:5px;background-color:'+this.object_types[key].bg_color+'!important;" class="'+this.object_types[key].fa+'">&nbsp;</i>' +
         this.object_types[key].title
          '</button></li>';
     })

	    elem.innerHTML += '</ul>';
      const items = elem.querySelectorAll('li');

      for (let i = 0; i < items.length; i++) {
        this.typeLib[i] && items[i]
          ? items[i].addEventListener('click', () =>
              this.onMenuBaes(latlng, this.typeLib[i])
            )
          : null;
    }
    return elem;
  }

  private getPopupMenuTitle(marker: any): any {
    const typeLibId: string = marker.__mapObject.id_object_type;
    const typeLib: any | null = this.typeLib.find((type: any) => {
      return type.id === typeLibId;
    });

    return {
      title: typeLib ? typeLib.title : '',
      itemId: typeLibId,
    };
  }

  // MARKER CODE
  private createMarkerPopupMenu(latlng: LatLng, marker: any): HTMLElement {

//    console.log('ON MAP CLICK', e);
    const item = this.getPopupMenuTitle(marker);
    const menuMapIndexes: number[] = [];
    const elem: HTMLElement = document.createElement('ul'),
      objectId: string = marker.__mapObject.__id,
      type: string = marker.__mapObject.type;



    if ( this.permissionService.hasPermission('can_action')
          && !this.permissionService.hasPermission('can_edit')
          && !this.permissionService.hasPermission('can_viewhistory')
          && !this.permissionService.hasPermission('can_control')
          && !this.permissionService.hasPermission('can_delete')
          && !this.permissionService.hasPermission('can_move')

    ) {
          setTimeout(() => {
            this.map.closePopup();
            this.onMenuIntervention(type, objectId, item.title);
          }, 0);


    }
    else if ( !this.permissionService.hasPermission('can_action')
          && !this.permissionService.hasPermission('can_viewhistory')
          && !this.permissionService.hasPermission('can_control')
          && !this.permissionService.hasPermission('can_delete')
          && !this.permissionService.hasPermission('can_move')
          && this.permissionService.hasPermission('can_edit')

    ) {
       setTimeout(() => {
            this.map.closePopup();

          this.onMenuEdit(type, objectId, item.title);
          }, 0);

    }


    const menuMap: MenuMap[] = [
    
      {
        name: 'edit',
        permission: 'can_edit',
        history: null,
        controller: () => {
          this.onMenuEdit(type, objectId, item.title);
        },
      },
      {
        name: 'controle',
        permission: 'can_control',
        history: {
        },
        controller: () => {
          this.onMenuControl(type, objectId, item.title);
        },
      },
     {
        name: 'controle_history',
        permission: 'can_viewhistory',
        history: {
        },
        controller: () => {
            this.onMenuHistoryInspection(type, objectId, item.title);
        },
      },



     {
        name: 'intervention',
        permission: 'can_action',
        history: {
        },
        controller: () => {
          this.onMenuIntervention(type, objectId, item.title);
        },
      },
      {
        name: 'intervention_history',
        permission: 'can_viewhistory',
        history: {
        },
        controller: () => {
            this.onMenuHistoryIntervention(type, objectId, item.title);
        },
      },

      {
        name: 'replace',
        permission: 'can_move',
        history: null,
        controller: () => {
          this.onMenuRelocate(marker, latlng);
        },
      },
      {
        name: 'direction',
        permission: null,
        history: null,
        controller: () => {
          setTimeout(() => {
            this.gps.getCoords().subscribe({
              next: (coords) => {
              /*  console.log(
                  'Localisation OK : ' +
                    coords.latitude +
                    ',' +
                    coords.longitude
                );*/
                // tslint:disable-next-line:max-line-length
                window.open(
                  `https://www.google.com/maps/dir/?api=1&origin=${+coords.latitude},${+coords.longitude}&destination=${
                    latlng.lat
                  },${latlng.lng}`,
                  '_blank'
                );
              },
              error: (error) => {
                console.error(error);
              },
            });
          }, 0);
        },
      },
      {
        name: 'delete',
        permission: 'can_delete',
        history: null,
        controller: () => {
          this.onMenuDelete(marker);
        },
      },
    ];

    elem.className = 'leaflet-popup-menu-list';

        elem.innerHTML += `<div style="text-align: center;font-size:20px; text-transform: uppercase;">
          <b>${this.i18n.t(item.title)}</b> <br/>
          <!--<b style="text-align: center;font-size:10px;">[id : ]</b>-->
        </div>`;
       // menuMapIndexes.push(index);

        let html = (this.object_types[marker.__mapObject.id_object_type].html_view) ? (this.object_types[marker.__mapObject.id_object_type].html_view) : '';
        elem.innerHTML += this.parseHtmlView( '<div class="html_view">'+html+'</div>', marker.__mapObject);
       // menuMapIndexes.push(index);


    menuMap.forEach((menuItem: any, index: number) => {



      if (
        menuItem.permission
          ? this.permissionService.hasPermission(menuItem.permission)
          : true
      ) {



        switch (menuItem.name) {
          case 'edit':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-edit btn waves-effect waves-light action">' +
              ' <i class="material-icons left">edit</i>'+this.i18n.t("Modifier") +
              '</a>' +
              '</li>';
            break;

          case 'controle':
            elem.innerHTML += `<li>
                  <a class="btn-control btn waves-effect waves-light action">
                    <i class="material-icons left">check_circle</i>`+this.i18n.t("Contrôler")+`
                  </a>
                </li>`;
            break;

           case 'controle_history':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-history btn waves-effect waves-light action">' +
              ' <i class="material-icons left">history</i>'+this.i18n.t("Historique contrôle")+'</a>' +
              '</li>';
            break;



          case 'intervention':
            elem.innerHTML += `<li>
                  <a class="btn-control btn waves-effect waves-light action">
                    <i class="material-icons left">build</i>`+this.i18n.t("Créer une intervention")+`
                  </a>
                </li>`;
            break;


       
          case 'intervention_history':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-history btn waves-effect waves-light action">' +
              ' <i class="material-icons left">history</i>'+this.i18n.t("Historique Interventions")+'</a>' +
              '</li>';
            break;

          case 'replace':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-move btn waves-effect waves-light action">' +
              ' <i class="material-icons left">room</i>'+this.i18n.t("Déplacer")+'</a>' +
              '</li>';
            break;

          case 'delete':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-delete btn waves-effect waves-light action">' +
              '<i class="material-icons left">delete</i>'+this.i18n.t("Supprimer")+'</a>' +
              '</li>';
            break;

          case 'direction':
            elem.innerHTML +=
              '<li>' +
              '<a class="btn-move btn waves-effect waves-light action">' +
              ' <i class="material-icons left">explore</i>'+this.i18n.t("S’y rendre")+'</a>' +
              '</li>';
            break;
        }
        menuMapIndexes.push(index);
      }
    });
  //}

    const items: any = elem.querySelectorAll('li');

    // tslint:disable-next-line:no-shadowed-variable
    items.forEach((item: any, index: number) => {
      const mapIndex: number = menuMapIndexes[index];
      if (menuMap[mapIndex].controller !== null) {
        // tslint:disable-next-line:no-shadowed-variable
        const elem: HTMLElement | null = item.querySelector('.action');

        this.addPopupItemHandler(elem, menuMap[mapIndex].controller);
      }

    
    });
    return elem;
  }

  private addPopupItemHandler(
    elem: HTMLElement | null,
    controller: Function | null
  ): void {
    if (elem && controller) {
      elem.addEventListener('click', () => controller());
    }
  }

  private getLastInspectionCoords(): LatLng | null {
    const inspection: Inspection | undefined =
      this.inspectionStorage.getLastInspection();

    let coords: LatLng | null = null;

    if (inspection != null) {
      const object: MapObject | undefined = this.mapObjectStorage.getObject(
        inspection.objectId
      );

      if (object != null) {
        coords = object.coords;
      }
    }

    return coords;
  }

  // private getLastObjectCoords(): LatLng | null {
  //   const object: MapObject | undefined = this.mapObjectStorage.getLastObject();

  //   return object != null ? object.coords : null;
  // }

  private onMapClick(e): void {

    if ( this.permissionService.hasPermission('can_add') || this.permissionService.hasPermission('can_edit')) {
      if (this.markerInMove != null) {
        this.relocateMarker(this.markerInMove, e.latlng);
        this.markerInMove.setOpacity(1);
        this.markerInMove = null;
      } else {
        this.map.togglePopup(e.latlng, this.createMapPopupMenu(e.latlng));
      }
    }
  }

  private onStyleClick(e): void {
    this.localStorageService.set('mapStyle',e);
  }

  private getMapObjects(): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.mapObjectStorage.getAllObjects().subscribe((data: MapObject[]) => {
        const f: [MapObject, Inspection | null][] = data
          .filter((o: any) => {
            return o.status !== 'deleted' && this.hasCoords(o);
          })
          .map<[MapObject, Inspection | null]>((o) => {
            return [o, this.historyStorage.getLastInspection(o)];
          });

        observer.next(f);
        observer.complete();
      });
    });
    // return this.mapObjectStorage.getAllObjects()
    //   .filter((o) => {
    //     return o.status !== 'deleted' && this.filterService.filter('objects', o);
    //   })
    //   .map<[MapObject, Inspection | null]>((o) => {
    //     return [o, this.historyStorage.getLastInspection(o)];
    //   });
  }

  private hasCoords(object: MapObject): boolean {
    return Boolean(object.coords.lat) && Boolean(object.coords.lng);
  }

  private onMarkerClick(e): void {
//    console.log('ON OBJECT CLICK ', e);

    this.map.openPopup(
      e.latlng,
      this.createMarkerPopupMenu(e.latlng, e.sourceTarget)
    );
  }

/*  private onMarkerClick(e): void {
    console.log('ON OBJECT CLICK ', e);

    this.requestService.getObjectId(e.sourceTarget.__mapObject.__id).subscribe(
      (data) => {
        this.idObject = data[0].id_object;

        this.map.openPopup(
          e.latlng,
          this.createMarkerPopupMenu(e.latlng, e.sourceTarget)
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }*/

  private onMapLayerAdded(type: string, layer: any): void {
    this.mapService.addLayerToCache(type, layer);
  }

  private onMapZoomEnd(e: any): void {
    this.mapService.changeState('zoom', e.target.getZoom());
  }

  private onMenuEdit(type: string, objectId: string, title: string): void {
    // setTimeout для фикса бага клика по карте при повторном посещении страницы.
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-object-editor`], {
        queryParams: { objectId: objectId },
      });
    }, 0);
  }

  private onMenuControl(type: string, objectId: string, title: string): void {
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-inspection`], {
        queryParams: { objectId: objectId },
      });
    }, 0);
  }

  private onMenuIntervention(
    type: string,
    objectId: string,
    title: string
  ): void {
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-intervention`], {
        queryParams: { objectId: objectId },
      });
    }, 0);
  }

  private onMenuDelete(marker: any): void {
    const mapObject: MapObject | null = this.mapObjectStorage.updateObject(
      marker.__mapObject,
      { status: 'deleted' }
    );

    if (mapObject != null) {
      this.mapObjectDataTransfer.send(mapObject).subscribe();
    }

    this.map.removeMarker(marker);
    this.map.closePopup();
  }

  private onMenuHistory(type: string, objectId: string, title: string): void {
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-history`], {
        queryParams: { objectId: objectId, back: 'map' },
      });
    }, 0);
  }

  private onMenuHistoryIntervention(
    type: string,
    objectId: string,
    title: string
  ): void {
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-history-intervention`], {
        queryParams: { objectId: objectId, back: 'map' },
      });
    }, 0);
  }

  private onMenuHistoryInspection(
    type: string,
    objectId: string,
    title: string
  ): void {
    setTimeout(() => {
      this.map.closePopup();
      this.router.navigate([`/${type}-history-inspection`], {
        queryParams: { objectId: objectId, back: 'map' },
      });
    }, 0);
  }



  private onMenuRelocate(marker: any, coords: LatLng): void {
    // Если уже имеется маркер ожидающий перемещения.
    if (this.markerInMove != null) {
      this.markerInMove.setOpacity(1);
    }

    this.markerInMove = marker;
    this.markerInMove.__oldCoords = coords;
    this.markerInMove.setOpacity(0.5);
    this.map.closePopup();
    this.notification.notify(
      'primary',
      this.i18n.t("Merci de choisir un nouvel emplacement")
    );
  }

  private onMenuBaes(coords: LatLng, typeLib: any): void {
    setTimeout(() => {
      this.router.navigate(['/baes-object-editor'], {
        queryParams: {
          coords: [coords.lat, coords.lng].join(','),
          typeLibId: typeLib.id,
          typeLibTitle: typeLib.title,
        },
      });
    }, 0);
  }

  private relocateMarker(marker: any, coords: LatLng): void {
    marker.setLatLng(coords);

    const mapObject: MapObject | null = this.mapObjectStorage.updateObject(
      this.markerInMove.__mapObject,
      { coords: coords }
    );

    if (mapObject != null) {
      this.mapObjectDataTransfer.send(mapObject).subscribe();
    }
  }

  private showCurrentLocation(): void {
    setTimeout(() => {
      this.gps.getCoords().subscribe({
        next: (coords) => {
          /*this.map.showLocation({
            lat: coords.latitude,
            lng: coords.longitude,
          });*/
          /*console.log(
            'Localisation OK :' + coords.latitude + ',' + coords.longitude
          );*/
        },
        error: (error) => {
          console.error(error);
          //this.map.showLocation({ lat: 46.495, lng: 2.207 }, 7);
        },
      });
    }, 0);
  }

  private showGeolocation(): void {

    if (this.watchLocationActive) {
      this.findGeolocation();
    } else {
      this.findGeolocation();
      this.watchGeolocation();
    }
  }

  public findGeolocationAndPlaceMap():void{
  }
  public findGeolocation(): void {

/*      this.notification.notify(
            'primary',
            this.i18n.t("Recherche signal GPS en cours")
          );
*/
    setTimeout(() => {
      this.gps.getCoords().subscribe({
        next: (coords) => {
          this.prevLocationCoords.latitude = coords.latitude;
          this.prevLocationCoords.longitude = coords.longitude;
          this.map.showCurrentLocation({
            lat: coords.latitude,
            lng: coords.longitude,

          });

	  var RoundedAccuracy, RoundedAltitude;
	  var GeoInfo;

	   this.setGPSStatus(true);

	   GeoInfo='';


    this.map.showCurrentLocation({
          lat: coords.latitude,
          lng: coords.longitude,

    });

	  if(coords.accuracy){
		RoundedAccuracy = Math.floor(coords.accuracy);
		GeoInfo = GeoInfo + 'Precision: '+RoundedAccuracy+'m';
	  }
	  if(coords.altitude){
		RoundedAltitude= Math.floor(coords.altitude);
		GeoInfo = GeoInfo + '-Altitude: '+RoundedAltitude+'m';
	  }

	/*  this.notification.notify(
	    'primary',
	    GeoInfo
	  );
*/
        },
        error: (error) => {
          //this.map.removeCurrentLocation();

      	   this.setGPSStatus(false);


/*          this.notification.notify(
            'error',
            'Erreur lors de la géolocalisation, merci de patienter ou activez votre GPS'
          );*/
          //window.clearInterval(this.watchGeolocationInterval);
        },
      });
    }, 0);


  }

  private watchGeolocation(): void {
    this.watchGeolocationInterval = setInterval(() => {
      this.gps.getCoords().subscribe({
        next: (coords) => {
          // tslint:disable-next-line:max-line-length
          if (
            this.layersService.calcCrow(
              coords.latitude,
              coords.longitude,
              this.prevLocationCoords.latitude,
              this.prevLocationCoords.longitude
            ) >= 0.01
          ) {
	          this.findGeolocation();
            this.watchLocationActive = true;
	          this.setGPSStatus(true);
            //this.resetPrevLocation();
            this.map.showCurrentLocation(
              { lat: coords.latitude, lng: coords.longitude },
              false
            );

    	    this.showGeolocation();
            this.mapActions.updateKmlsVisibility();
            setTimeout(() => {
              this.mapActions.updateKmlByGeolocation();
            }, 90000);
          }
          this.prevLocationCoords.latitude = coords.latitude;
          this.prevLocationCoords.longitude = coords.longitude;
        },
        error: (error) => {
          this.watchLocationActive = false;
	        this.GPSattempt=this.GPSattempt+1;
	        if(this.GPSattempt>3){
          	//clearInterval(this.watchGeolocationInterval);
	  	      this.GPSattempt=0;
		        this.setGPSStatus(false);
            //		this.findGeolocationSubscription.unsubscribe();

             // si pas de GPS trouvé, on place à paris
        /*    this.map.showCurrentLocation({
              lat: 48.866666,
              lng: 2.333333

            });
		 */
          this.notification.notify(
          'primary',
          this.i18n.t("Pas de GPS trouvé")
          );

	  }
        },
      });
    }, 60000);
  }

  public resetPrevLocation(): void {
    this.prevLocationCoords = {
      latitude: 0,
      longitude: 0,
    };
  }

  public getPinIcon(object: any): string {
    let iconClass = '';

    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type: any) => {
        return type.id === typeLibId;
      });
      iconClass = typeLib ? typeLib.fa : '';
    }
    return iconClass;
  }

  public getHtmlView(object: any): string {
    let htmlView= '';

    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type: any) => {
        return type.id === typeLibId;
      });
      htmlView= typeLib ? typeLib.html_view: '';
    }
    return htmlView;
  }

  public getStatusOptions(object: any): string {
    let statusOptions= '';
    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type: any) => {
        return type.id === typeLibId;
      });
      statusOptions= typeLib ? typeLib.status_options: '';
    }
    return statusOptions;
  }




  public getPinColor(object: any): string {
    let iconColor= '';

    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type: any) => {
        return type.id === typeLibId;
      });
      iconColor= typeLib ? typeLib.bg_color: '';
    }
    return iconColor;
  }

  public setGPSStatus(status: boolean){

	  this.GPSStatus=status;
	  let button = document.getElementById("geo-button") as HTMLInputElement
	  if(undefined == button){
	     return false;
	  }
	  if(this.GPSStatus==false){
	   button.classList.add('error');
	  }else{
	     button.classList.remove('error');
	  }
  }


  public parseHtmlView(html:string, object: any ):string{ 
  // pour chaque variable dans html_view {{}} remplacer par sa valeur dans object 
    let before = html;
    Object.keys(object).forEach(key => {
      if(object[key] && object[key].length > 0){
        html = html.replace(new RegExp(`{{object.${key}}}`, 'g'),  object[key]  );
        
      }
    });

    if(before == html) return '';
    return html;
  }

}
