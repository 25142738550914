import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, NgZone, SimpleChanges, OnChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { BehaviorSubject, Observable, Subject, fromEvent } from 'rxjs';

@Directive({
  selector: '[appCarousel]',
  exportAs: 'appCarousel',
  host: {
    '[attr.readonly]': 'true'
  }
})
export class CarouselDirective implements OnInit, OnDestroy, OnChanges {
  @Input()
  public config: any;

  @Output('pageChanged')
  public pageChangedEmitter: EventEmitter<number>;

  @Output('initialized')
  public initializedEmitter: EventEmitter<boolean>;

  private jqElem: any;

  public pageNo: number = 1;

  //public dateChanged$: Observable<string>;

  constructor(
    @Inject(DOCUMENT)
    private DOC: any,
    private elem: ElementRef,
    private zone: NgZone
  ) {
    this.pageChangedEmitter = new EventEmitter<number>();
    this.initializedEmitter = new EventEmitter<boolean>();
  }

  ngOnInit() {
    const self = this;

    jQuery(this.elem.nativeElement).on('init', ()=> {
      self.initializedEmitter.emit(true);
    })

    setTimeout(() => {
      this.jqElem = jQuery(this.elem.nativeElement).slick(this.config);
    }, 100);

    // this.jqElem.on('init afterChange', function(event:any, slick:any, currentSlide:number, nextSlide:number){
    //   const i: number = (currentSlide ? currentSlide : 0) + 1;
    //   self.pageChangedEmitter.emit(i);
    // }); 
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  ngOnDestroy() {
    this.jqElem.slick('unslick');
  }
}
