import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, from, of, throwError } from 'rxjs';
import {
  catchError,
  concatAll,
  map,
  last,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Customer } from './customer';
import { CustomersService } from './customers.service';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { StorageService } from '../storage.service';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService {
  public serverURL: string;

  constructor(
    private appConfig: AppConfigService,
    private customers: CustomersService,
    private fileUpload: FileUploadService,
    private http: HttpClient,
    private localStorage: StorageService,
    private auth: AuthorizationService
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });
  }

  private _send(customer: Customer): Observable<any> {
    const send$: Observable<any> = this.http.post(
      `${this.serverURL}/api/sync`,
      {
        app_id: this.localStorage.get('appId'),
        user_id: this.localStorage.get('userId'),
        customers: [customer],
      }
    );

    return from([
      this.uploadImages(customer).pipe(last(), skip(1)),
      send$,
    ]).pipe(concatAll());
  }

  private uploadFiles(files: string[]): Observable<any> {
    const observables: Observable<any>[] = files.map((file) =>
      this.fileUpload.upload(file)
    );

    return from(observables).pipe(concatAll());
  }

  private uploadImages(customer: Customer): Observable<any> {
    if (customer.images.length && !customer.imagesServer) {
      const imagesServer: string[] = [];

      return this.uploadFiles(customer.images).pipe(
        tap({
          next: (url) => imagesServer.push(url),
          complete: () => (customer.imagesServer = imagesServer),
        })
      );
    } else {
      return of(null);
    }
  }

  public sendCustomer(customer: Customer): Observable<any> {
    return of(null).pipe(
      map(() => {
        try {
          this.customers.addCustomer(customer);

          return true;
        } catch (error) {
          return false;
        }
      }),
      switchMap((saved: boolean) => {
        return this._send(customer).pipe(
          map(() => true),
          catchError((error) => (!saved ? throwError(error) : of(false))),
          tap(
            (sent: boolean) => sent && this.customers.removeCustomer(customer)
          )
        );
      })
    );
  }

  public synchronize(): Observable<any> {
    const customers: Customer[] = this.customers.getCustomers();

    if (customers.length) {
      const observables: Observable<any>[] = [];

      customers.forEach((customer) => {
        const send$: Observable<any> = this._send(customer).pipe(
          tap({
            complete: () => this.customers.removeCustomer(customer),
          })
        );

        observables.push(send$);
      });

      return from(observables).pipe(concatAll());
    } else {
      return of(null);
    }
  }
}
