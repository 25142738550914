import { Injectable } from '@angular/core';

import { Inspection } from './inspection';
import { StorageService as LocalStorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private inspections: Map<string, Inspection>;

  private inspectionsKey: string = 'inspections';

  private unsentInspections: Map<string, any>;

  private unsentInspectionsKey: string = 'inspectionsUnsent';

  constructor(
    private storage: LocalStorageService
  ) {
    const
      inspections: [string, Inspection][] | null = this.storage.get(this.inspectionsKey),
      unsentInspections: [string, any][] | null = this.storage.get(this.unsentInspectionsKey);

    this.inspections =
      inspections == null ? new Map<string, Inspection>() : new Map(inspections);
    this.unsentInspections =
      unsentInspections == null ? new Map<string, any>() : new Map(unsentInspections);
  }

  public refreshInspections(): void {
    const
      inspections: [string, Inspection][] | null = this.storage.get(this.inspectionsKey),
      unsentInspections: [string, any][] | null = this.storage.get(this.unsentInspectionsKey);

    this.inspections =
      inspections == null ? new Map<string, Inspection>() : new Map(inspections);
    this.unsentInspections =
      unsentInspections == null ? new Map<string, any>() : new Map(unsentInspections);
  }

  private getId(inspection: Inspection | string): string {
    return typeof inspection === 'string' ? inspection : inspection.__id;
  }

  private saveInspections(): void {
    this.storage.set(this.inspectionsKey, [...this.inspections]);
  }

  private saveUnsentInspections(): void {
    this.storage.set(this.unsentInspectionsKey, [...this.unsentInspections]);
  }

  public addInspection(inspection: Inspection): void {
    this.inspections.set(inspection.__id, inspection);
    this.saveInspections();
  }

  public addUnsentInspection(inspection: Inspection | string): void {
    this.unsentInspections.set(this.getId(inspection), '');
    this.saveUnsentInspections();
  }

  public getInspection(id: string): Inspection | undefined {
    return this.inspections.get(id);
  }

  public getAllInspections(): Inspection[] {
    return [...this.inspections.values()];
  }

  public getAllUnsentInspections(): Inspection[] {
    const keys: string[] = [...this.unsentInspections.keys()];

    return keys
      .map((key: string) => this.inspections.get(key)!)
      .filter((inspection: Inspection | undefined) => !!inspection);
  }

  public getLastInspection(): Inspection | undefined {
    return this.getAllInspections()[this.inspections.size - 1];
  }

  public removeInspection(inspection: Inspection | string): void {
    if(this.inspections.delete(this.getId(inspection)) === true) {
      this.saveInspections();
    }
  }

  public removeUnsentInspection(inspection: Inspection | string): void {
    if(this.unsentInspections.delete(this.getId(inspection)) === true) {
      this.saveUnsentInspections();
    }
  }

  public updateInspection(inspection: Inspection | string, params: Object): Inspection | null {
    const
      id: string = this.getId(inspection),
      int: Inspection | undefined = this.inspections.get(id);

    if(int != null) {
      const newInsp: Inspection = Object.assign({}, int, params);

      this.inspections.set(id, newInsp);
      this.saveInspections();

      return newInsp;
    }
    else {
      return null;
    }
  }
}
