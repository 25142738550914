import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FORM_URL } from '../../../server-url';
import { AuthorizationService } from './authorization/authorization.service';
import { I18nService } from '@i18n';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  public serverURL: string;

  constructor(private http: HttpClient, private auth: AuthorizationService,public i18n: I18nService) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });
  }

  public getFormStructure(
    type: string,
    form: string = 'object'
  ): Observable<any> {

    return this.http
      .get(`${this.serverURL}${FORM_URL}?object_type=${type}&form_name=${form}`)
      .pipe(
        tap((data) => {
          return this.normalizeFormData(data);
        })
      );
  }

  public normalizeFormData(data: any): any {
    data.sort((a, b) => a.order_num - b.order_num);

    return data;
  }
}
