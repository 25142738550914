import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit, OnChanges, OnDestroy {
  @Input('checked')
  public defaultState: boolean | null = null;

  @Input()
  public readonly: boolean = false;

  @Input()
  public triple: boolean = false;

  @Output('checkedChange')
  public stateChanged = new EventEmitter<boolean | null>();

  @HostBinding('style.pointerEvents')
  public get getPointerEvents() {
    return this.readonly === true ? 'none' : 'auto';
  }

  @HostListener('click', [])
  public onClick() {
    this.switchState();
  }

  private hm: any;

  private stateIndex: number;

  private states: (boolean | null)[];

  public state: boolean | null;

  constructor(
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.states = this.getStates();
    this.state = this.getDefaultState();
    this.stateIndex = this.states.indexOf(this.state);

    this.initSwipe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.checked) {
      const
        curValue: any = changes.checked.currentValue,
        isValidValue: boolean = this.isValidValue(curValue);

      if(!isValidValue) {
        this.state = this.triple ? null : false;
      }
      else {
        this.state = curValue;
      }
    } else if (changes.defaultState) {
      const
        curValue: any = changes.defaultState.currentValue,
        isValidValue: boolean = this.isValidValue(curValue);

      if(!isValidValue) {
        this.state = this.triple ? null : false;
      }
      else {
        this.state = curValue;
      }
    }
  }

  ngOnDestroy() {
    this.hm.destroy();
  }

  private getDefaultState(): boolean | null {
    if(this.isValidValue(this.defaultState)) {
      return this.defaultState;
    }
    else {
      return this.triple ? null : false;
    }
  }

  private getStates(): (boolean | null)[] {
    return this.triple ? [false, null, true] : [false, true];
  }

  private initSwipe(): void {
    this.hm = new Hammer.Manager(this.element.nativeElement);

    this.hm.add(new Hammer.Swipe({
      direction: Hammer.DIRECTION_HORIZONTAL,
      velocity:	0.1
    }));

    this.hm.on('swipeleft', () => this.switchState('left'));
    this.hm.on('swiperight', () => this.switchState('right'));
  }

  private isValidValue(value: any): boolean {
    if(this.triple) {
      return value === true || value === false || value === null;
    }
    else {
      return value === true || value === false;
    }
  }

  private switchState(direction?: 'left' | 'right'): void {
    if(direction === 'left') {
      if(this.stateIndex > 0) {
        this.stateIndex--;
      }
    }
    else if(direction === 'right') {
      if(this.stateIndex < this.states.length - 1) {
        this.stateIndex++;
      }
    }
    else {
      this.stateIndex = (this.stateIndex + 1) % this.states.length;
    }

    this.state = this.states[this.stateIndex];
    this.stateChanged.emit(this.state);
  }

  public getClass(): string {
    if(this.state === true) {
      return 'checked';
    }
    else if(this.state === false) {
      return 'unchecked';
    }
    else if(this.state === null) {
      return 'undefined';
    }
    else {
      return '';
    }
  }
}
