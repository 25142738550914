import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmService } from 'src/app/core/services/confirm/confirm.service';
import { BaesFormData, BaesIntervention, BaesInterventionFactoryService, InterventionDataTransferService, InterventionStorageService } from 'src/app/core/services/intervention';
import { HistoryStorageService } from 'src/app/core/services/history-intervention';
import { BaesMapObject } from 'src/app/core/services/map-object';
import { StorageService } from 'src/app/core/services/map-object/storage.service';
import { RoutingHistoryService } from 'src/app/routing-history';
import { I18nService} from '@i18n';


@Component({
  selector: '',
  templateUrl: './baes-intervention.component.html',
  styleUrls: ['./baes-intervention.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class BaesInterventionComponent implements OnInit {

  public object: BaesMapObject;

  public objectId: string;

  public backButtonRoute: string | null;

  constructor(
    private confirm: ConfirmService,
    private history: HistoryStorageService,
    private interventionFactory: BaesInterventionFactoryService,
    private interventionDataTransfer: InterventionDataTransferService,
    private interventionStorage: InterventionStorageService,
    private objectStorage: StorageService,
    private route: ActivatedRoute,
    private navhistory: RoutingHistoryService,
    public  i18n: I18nService,
    private router: Router
  ) {}

  ngOnInit() {
    this.objectId = this.route.snapshot.queryParamMap.get('objectId')!;
    this.object = this.objectStorage.getObject(this.objectId) || <any>{};
    this.backButtonRoute = this.route.snapshot.queryParamMap.get('back');
  }

  private saveIntervention(data: BaesFormData): void {
    const intervention: any = this.interventionFactory.create(data, this.objectId);
    // const interventionRequest: BaesIntervention = this.interventionFactory.createRequest(data, this.objectId);

    this.interventionStorage.addIntervention(intervention.storage);
    this.history.addIntervention(intervention.storage);
    this.interventionDataTransfer.send(intervention.request).subscribe();
  }

  public onSubmitted(data: BaesFormData): void {
    const isCompleteReplacement: boolean = data.operation === 'Remplacement bloc complet';
    const confirmText: string = 'Voulez-vous continuer?';

      if(isCompleteReplacement) {
        this.saveIntervention(data);

          this.router.navigate(['/baes-object-editor'], {
            queryParams: {
              objectId: this.objectId,
              backToMap: true
            }
          });
      }
      else {
          this.saveIntervention(data);
          this.router.navigate(['/map']);
      }
  }
 public onBack(): void {
    this.navhistory.back();
  }


}
