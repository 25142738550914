import { Component, OnInit } from '@angular/core';
import { Info } from "../../core/globals";
import { I18nService } from '@i18n';
import { GPSService, LatLng } from 'src/app/core/services/gps';

@Component({
  selector: '',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  host: {
    '[className]': "'app-page'"
  }
})

export class AboutComponent implements OnInit {

  public info: any = Info;

  constructor(
    public i18n: I18nService,
    private gps: GPSService,
  ) { }

  ngOnInit() {
    this.getGpsDebug();
  }

  public getGpsDebug(){
    setInterval( ()=>{
        this.gps.getCoords().subscribe({
        next: (coords) => {
          if( coords.latitude && coords.longitude ){
            const debug = document.getElementById("debug") as HTMLElement | null;
            if(debug){
		          debug.innerHTML = "<br><h1>Gps information</h1> "+Date.now()+"<br><b>Précision</b>: "+coords.accuracy+" m<br><b>Lattitude</b>: "+coords.latitude+"<br><b>Longitude</b>: "+coords.longitude;
            }
          }
        }
     });

    }, 0);
  }
  


}
