import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, QueryList } from '@angular/core';
import { BaesIntervention } from 'src/app/core/services/intervention';
import { FormDataService } from '../../core/services/form-data.service';
import { NotificationService } from '../../core/services/notification.service';
import { CameraService } from '../../core/services/camera.service';
import { StorageService } from '../../core/services/storage.service';
import { I18nService}  from '@i18n';

@Component({
  selector: 'app-baes-history-intervention',
  templateUrl: './baes-history-intervention.component.html',
  styleUrls: ['./baes-history-intervention.component.scss']
})
export class BaesHistoryInterventionComponent implements OnInit {
  @Input()
  public hasNext: boolean = false;

  @Input()
  public hasPrev: boolean = false;

  @Input()
  public objectId: string = '';

  @Input()
  public intervention: BaesIntervention;

  @Input()
  public object: any;

  @Input()
  public interventionId: any;

  @Output()
  public next: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public prev: EventEmitter<void> = new EventEmitter<void>();

  public formSelects: any = [];

  public formStructure: any;

  public formTextAreas: any = [];

  private imagesLimit: number;

  public images: string[] = [];

  public activeImageName: string;

  public allFormStructure: any = [];

  public typeLibId: string;


  public get imagesLimitReached(): boolean {
    return this.images.length >= this.imagesLimit;
  }

  constructor(
    private formDataService: FormDataService,
    private host: ElementRef,
    private notification: NotificationService,
    private camera: CameraService,
    public i18n: I18nService,
    private storageService: StorageService,
  ) {}

  ngOnInit() {
    this.getFormStructure();

  }

  ngAfterViewInit() {
    setTimeout(() => M.updateTextFields(), 0);
  }

  ngAfterViewChecked() {
    this.formSelects = this.host.nativeElement.querySelectorAll('select');
    this.formTextAreas = this.host.nativeElement.querySelectorAll('textarea');
    M.updateTextFields();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.intervention) {
      this.updateForm();
    }
  }

  public createImagesData(): void {   
    this.formStructure.forEach(el => {
      if (el.input_type === 'image') {
        this.intervention[el.name] = this.intervention[el.name] !== undefined ? this.intervention[el.name] : [];
      }
    });
  }

  public updateForm(): void {
    setTimeout(() => {
      this.updateSelectsValues();
      this.resizeTextAreas(); 
    }, 100);   
  }

  public updateSelectsValues(): void {
    if (this.formSelects) {
      this.formSelects.forEach((select: any) => {
        select.M_FormSelect._setValueToInput();
      })
    }
  }

  public resizeTextAreas(): void {
    if (this.formTextAreas) {
      this.formTextAreas.forEach((textarea: any) => {
        M.textareaAutoResize(textarea);
      })
    }
  }

  public getFormStructure(): void {
    this.typeLibId = this.getObjectTypeId();
    this.getStructure();
  }


 public getStructure(){
    this.allFormStructure = this.storageService.get('allFormStructure');
    let formType = 'intervention';
	console.log('getStructure '+formType);
    if(!this.allFormStructure){
        this.allFormStructure = [];
    }
    if(this.allFormStructure && this.allFormStructure[formType] && this.allFormStructure[formType][this.typeLibId]){
	console.log('cache hit objectStructure intervention');
        this.formStructure = this.allFormStructure[formType][this.typeLibId];
    }
    else{
	console.log("Erreur: impossible de trouver le cache object structure intervention"); 
/*        this.formDataService.getFormStructure(this.typeLibId, 'add').subscribe(
        (data) => {
          this.formStructure = data;
          this.allFormStructure[formType][this.typeLibId]=data;
        });*/
    }
 }


  private getObjectTypeId(): string {
    return this.object.id_object_type ? this.object.id_object_type : '';   
  }

  public onNext(): void {
    this.next.emit();
    M.updateTextFields()
            console.log(this.intervention);
  } 

  public onPrev(): void {
    this.prev.emit();
    M.updateTextFields()
  }

  public isImagesObject(data: string | string[]): boolean {
    return typeof data === 'object' ? true : false;
  }

  public getFieldLib(obj,key){
    const d: any = this.allFormStructure['intervention'][this.getObjectTypeId()].find((type: any) => {
      return type.name === key;
    });
    if(d && d['json_values']){
      const l: any = d['json_values'].find((type: any) => {
        return type.key=== obj[key];
      });
      if(l && l['lib']){
        return l['lib'];
      }
    } 
    return obj[key];
  }

 public getFieldColor(obj,key){
    const d: any = this.allFormStructure['add'][obj['id_object_type']].find((type: any) => {
      return type.name === key;
    });
    if(d && d['json_values']){
      const l: any = d['json_values'].find((type: any) => {
        return type.key=== obj[key];
      });
      if(l && l['color']){
        return "#"+l['color'];
      }
    } 
    return 'lightgray'
  }


}
