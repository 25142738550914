//import { Component, OnInit } from '@angular/core';
//
import { NgModule } from '@angular/core';
import { Component, OnDestroy, Renderer2 } from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';

@Component({
  selector: '',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    '[className]': "'app-page'"
  }
})

@NgModule({
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
  ]
})
export class LoginComponent implements OnDestroy{

//constructor() { }

//  ngOnInit() {
//  }
    constructor(private renderer: Renderer2) {
    //this.renderer.addClass(document.body, 'login');
   }

  ngOnDestroy() {
   // this.renderer.removeClass(document.body, 'login');
  }

}
