import { Injectable } from '@angular/core';

import { BaesMapObject } from './map-object';
import { BaesMapObjectFormData } from './form-data';
import { LatLng } from 'src/app/core/services/gps';
import { UtilsService } from 'src/app/core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class MapObjectFactoryService {
  constructor(
    private utils: UtilsService
  ) {}

  public create(
    data: BaesMapObjectFormData,
    coords: LatLng | string,
    country: string,
    city: string,
    building: string,
    part: string,
    floor: string
  ): BaesMapObject {
    if(typeof coords === 'string') {
      const [lat, lng] = coords.split(',');

      coords = {
        lat: +lat,
        lng: +lng
      };
    }
    return {
      ...data,
      __id: this.utils.getUniqueId(),
      country,
      city,
      building,
      part,
      coords: coords,
      floor,
      type: 'baes'
    };
  }
}

