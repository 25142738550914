import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';

import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Subscription } from 'rxjs';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchWidgetComponent implements OnInit, OnDestroy {
  @Input()
  public maxLength: number | string;

  @Output()
  public search = new EventEmitter<string>();

  @ViewChild('search')
  private searchBox: ElementRef;

  public listChangedSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService
  ) {
    this.listChangedSubscription = this.sidebarService.inspectionListChanged.subscribe(()=> {
      this.searchBox.nativeElement.value = '';
    });
  }

  ngOnInit() {
    if(this.maxLength == null) {
      this.maxLength = 999;
    }

    fromEvent(this.searchBox.nativeElement, 'input')
      .pipe(
        map((e: KeyboardEvent) => (<HTMLInputElement>e.target).value),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((val) => this.search.emit(val));
  }

  ngOnDestroy() {
    this.listChangedSubscription.unsubscribe();
  }
}
