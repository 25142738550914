
import * as moment from 'moment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'

// import { HistoryStorageService } from '../../core/services/history';
import { BaesMapObject, BaesMapObjectWithLastInspection, MapObjectStorageService } from '../../core/services/map-object';

import { StorageService } from 'src/app/core/services/map-object/storage.service';
import { Subscription } from 'rxjs';
import { SidebarService } from '../../core/services/sidebar.service';
import { stringify } from 'querystring';
import { BaesIntervention } from '../../core/services/intervention';
import { HistoryIterator, HistoryIteratorItem, HistoryStorageService } from 'src/app/core/services/history-intervention';



@Component({
  selector: '',
  templateUrl: './baes-intervention-list.component.html',
  styleUrls: ['./baes-intervention-list.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class BaesInterventionListComponent implements OnInit {

  public filteredInterventions: BaesMapObjectWithLastInspection[];

  public typeLibId: string;

  public title: string;

  private interventionListChangedSubscription: Subscription;

  public sortInc: boolean = false;
  public sortDueInc: boolean = false;

  public interventions: any = [];

  public objects: any = [];

  private interventionsUpdatedSubscription: Subscription;

  constructor(
    private historyStorage: HistoryStorageService,
    private mapObjectStorage: MapObjectStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private mapStorageService: StorageService,
    private sidebarService: SidebarService,
  ) {
    this.interventionListChangedSubscription = this.sidebarService.interventionListChanged.subscribe(
      () => {
        this.getObjects();
      }
    )

    this.interventionsUpdatedSubscription = this.mapStorageService.objectsUpdated.subscribe(()=> {
      this.getObjects();
    });
  }

  ngOnInit() {
    this.getObjects();

    this.sidebarService.interventionListChanged.subscribe(()=> {
      setTimeout(() => {
        this.getObjects();
      }, 100);
    }); 
  }

  ngOnDestroy() {
    this.interventionListChangedSubscription.unsubscribe();
    this.interventionsUpdatedSubscription.unsubscribe();
  }

  public getObjects() {
    this.typeLibId = this.route.snapshot.queryParamMap.get('typeLibId') || '';
    this.title = this.route.snapshot.queryParamMap.get('typeLibTitle') || '';
    this.mapObjectStorage.getAllObjects(this.typeLibId, true).subscribe(
      (data)=> {
        const objects: BaesMapObject[] = <BaesMapObject[]>data;

        //this.sortByDates();
        this.objects = objects;
        this.getInterventions(objects); 
      }
    );
    
  }

  public getInterventions(objects: any): void {
    let interventions: any = [];
    objects.forEach((o: any)=> {
      const history: any = this.historyStorage.getHistory<BaesIntervention>(o); 
      if (history.items && history.items.length) {
        history.items.forEach((item) => {
          item.object = o;
        });

        interventions = [...interventions, ...history.items]
      }
      
    })
    this.interventions = this.filteredInterventions = interventions;
  }

  public onEdit(object: BaesMapObjectWithLastInspection): void {
    this.router.navigate(['/baes-object-editor'], {
      queryParams: {objectId: object.__id}
    });
  }

  public onHistory(object: BaesMapObjectWithLastInspection): void {
    this.router.navigate(['/baes-history'], {
      queryParams: {objectId: object.__id}
    });
  }

  public onInterventionHistory(object: BaesMapObjectWithLastInspection): void {
    this.router.navigate(['/baes-history-intervention'], {
      queryParams: {objectId: object.__id}
    });
  }

  public onInterventionClick(object: BaesMapObjectWithLastInspection): void {
    this.router.navigate(['/baes-intervention'], {
      queryParams: {objectId: object.__id}
    });
  }

  public onControlClick(object: BaesMapObjectWithLastInspection): void {
    this.router.navigate(['/baes-inspection'], {
      queryParams: {objectId: object.__id}
    });
  }

  // public onSearch(value: string): void {
  //   this.filteredInterventions = this.objects.filter(
  //     (object) => object.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
  //   );
  // }

  public sortByDates(): void {
    this.sortInc = !this.sortInc;

    const sorted: any = this.interventions.sort((a: any,b: any)=> {
      if (this.sortInc) {
        return moment(a.created_at).valueOf() - moment(b.created_at).valueOf();
      } else {
        return moment(b.created_at).valueOf() - moment(a.created_at).valueOf();
      }
    });
    this.filteredInterventions = JSON.parse(JSON.stringify(sorted));
  }

  public sortByDueDates(): void {
    this.sortDueInc = !this.sortDueInc;

    const sorted: any = this.interventions.sort((a: any,b: any)=> {
      if (this.sortDueInc) {
        return moment(a.due_date).valueOf() - moment(b.due_date).valueOf();
      } else {
        return moment(b.due_date).valueOf() - moment(a.due_date).valueOf();
      }
    });
    this.filteredInterventions = JSON.parse(JSON.stringify(sorted));
  }

}
