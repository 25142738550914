import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaesFormData, BaesInspection, BaesInspectionFactoryService, InspectionDataTransferService, InspectionStorageService } from 'src/app/core/services/inspection';
import { HistoryStorageService } from 'src/app/core/services/history-inspection';
import { BaesMapObject } from 'src/app/core/services/map-object';
import { StorageService } from 'src/app/core/services/map-object/storage.service';
import { I18nService} from '@i18n';
import { RoutingHistoryService } from 'src/app/routing-history';

@Component({
  selector: '',
  templateUrl: './baes-inspection.component.html',
  styleUrls: ['./baes-inspection.component.scss'],
  host: {
    '[className]': '"app-page"'
  }
})
export class BaesInspectionComponent implements OnInit {

  public object: BaesMapObject;

  public objectId: string;

  constructor(
    private history: HistoryStorageService,
    private inspectionFactory: BaesInspectionFactoryService,
    private inspectionDataTransfer: InspectionDataTransferService,
    private inspectionStorage: InspectionStorageService,
    private objectStorage: StorageService,
    private route: ActivatedRoute,
    public  i18n: I18nService,
    private router: Router,
    private navhistory: RoutingHistoryService,
  ) {}

  ngOnInit() {
    this.objectId = this.route.snapshot.queryParamMap.get('objectId')!;
    this.object = this.objectStorage.getObject(this.objectId) || <any>{};
  }

  private saveInspection(data: BaesFormData): void {
    const inspection: any = this.inspectionFactory.create(data, this.objectId);
    // const inspectionRequest: BaesInspection = this.inspectionFactory.createRequest(data, this.objectId);

    this.inspectionStorage.addInspection(inspection.storage);
    this.history.addInspection(inspection.storage);
    this.inspectionDataTransfer.send(inspection.request).subscribe();
  }

  public onSubmitted(data: BaesFormData): void {
    const isCompleteReplacement: boolean = data.operation === 'Remplacement bloc complet';

      if(isCompleteReplacement) {
        this.saveInspection(data);

          this.router.navigate(['/baes-object-editor'], {
            queryParams: {
              objectId: this.objectId,
              backToMap: true
            }
          });
      }
      else {
          this.saveInspection(data);
          this.router.navigate(['/map']);
      }
  }

  public onBack(): void {
    this.navhistory.back();
  }


}
