import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../core/services/storage.service';
import { SyncServerService } from '../../core/services/sync-server.service';

@Component({
  selector: 'app-synchronization-info',
  templateUrl: './synchronization-info.component.html',
  styleUrls: ['./synchronization-info.component.scss']
})
export class SynchronizationInfoComponent implements OnInit {
  public syncInfo: any;

  public dateFormat: string = 'd/MM/yyyy H:mm:ss';

  constructor(
    private storageService: StorageService,
    private syncService: SyncServerService
  ) { 
    this.syncService.syncFinished.subscribe(
      ()=> this.syncInfo = this.storageService.get('syncinfo')
    )
  }

  ngOnInit() {
    this.syncInfo = this.storageService.get('syncinfo');
  }
}
