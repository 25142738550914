import { Component, OnInit } from '@angular/core';
import {I18nService} from '@i18n';

@Component({
  selector: '',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  host: {
    '[className]': "'app-page'"
  }
})

export class AccountComponent implements OnInit {

  constructor(
    public i18n: I18nService, 
  ) { }

  ngOnInit() {
  }

}
