import { Directive, HostListener } from '@angular/core';

import { RoutingHistoryService } from '../services';

@Directive({
  selector: '[appHistoryBack]'
})
export class HistoryBackDirective {
  @HostListener('click')
  private onClick(): void {
    this.history.back();
  }

  constructor(
    private history: RoutingHistoryService
  ) {}
}
