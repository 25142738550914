import { Injectable } from '@angular/core';

import { BaesFormData } from './form-data';
import { BaesInspection } from './inspection';
import { UtilsService } from 'src/app/core/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class FactoryService {
  constructor(
    private utils: UtilsService
  ) {}

  public create(data: BaesFormData, objectId: string): any {
    const __id: string = this.utils.getUniqueId();
    return {
      storage: {
        ...data,
        __id: __id,
        date: Date.now(),
        objectId: objectId,
        type: 'baes'
      },
      request: {
        __id: __id,
        date: Date.now(),
        objectId: objectId,
        type: 'baes',
        form_data: data
      }
    };
  }

  // public createRequest(data: BaesFormData, objectId: string): any {
  //   return {
  //     __id: this.utils.getUniqueId(),
  //     date: Date.now(),
  //     objectId: objectId,
  //     type: 'baes',
  //     form_data: data
  //   };
  // }
}
