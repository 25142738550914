import { Injectable } from '@angular/core';

import { HistoryItem } from './history';
import { HistoryIterator } from './iterator';
import { Intervention, InterventionStorageService } from '../intervention';
import { MapObject } from '../map-object';
import { StorageService as LocalStorageService } from '../storage.service';
import { FiltersService } from '../filters.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private history: Map<string, HistoryItem>;

  private key = 'history_intervention';

  constructor(
    private interventionStorage: InterventionStorageService,
    private localStorage: LocalStorageService,
    private filterService: FiltersService
  ) {
    const history: [string, HistoryItem][] | null = this.localStorage.get(
      this.key
    );

    this.history =
      history == null ? new Map<string, HistoryItem>() : new Map(history);
  }

  private getId(object: { __id: string } | string): string {
    return typeof object === 'string' ? object : object.__id;
  }

  private getInterventions(interventionIds: string[]): Intervention[] {
    return interventionIds
      .map((id: string) => this.interventionStorage.getIntervention(id)!)
      .filter((intervention: Intervention | undefined) => !!intervention)
      .reverse();
  }

  private getObjectHistory(object: MapObject | string): HistoryItem | null {
    const id: string = this.getId(object),
      history: HistoryItem | undefined = this.history.get(id);
    return history || null;
  }

  private save(): void {
    this.localStorage.set(this.key, [...this.history]);
  }

  public addIntervention(intervention: Intervention): void {
    let historyItem: HistoryItem | undefined = this.history.get(
      intervention.objectId
    );

    if (historyItem == null) {
      historyItem = new HistoryItem(intervention.objectId);
    }

    historyItem.interventions.push(intervention.__id);
    this.history.set(intervention.objectId, historyItem);
    this.save();
  }

  public updateIntervention(id: string, interventionsId: string[]): void {
    let historyItem: HistoryItem | undefined = this.history.get(id);
    let flag: boolean = true;
    if (historyItem == null) {
      historyItem = new HistoryItem(id);
    }

    for (let i in interventionsId) {
      if (historyItem.interventions.length === 0) {
        historyItem.interventions.push(interventionsId[i]);
        this.history.set(id, historyItem);
        this.save();
      } else {
        for (let a in historyItem.interventions) {
          if (historyItem.interventions[a] == interventionsId[i]) {
            flag = false;
            break;
          }
        }
        if (flag) {
          historyItem.interventions.push(interventionsId[i]);
          this.history.set(id, historyItem);
          this.save();
        }
      }
    }
  }

  public getHistory<T extends Intervention>(object: any): HistoryIterator<T> {
    let history: HistoryItem | null = this.getObjectHistory(object),
      interventions: T[] =
        history != null
          ? <T[]>this.getInterventions(history.interventions)
          : [];
    interventions = interventions.filter((inspection) => {
      return this.filterService.filter(
        'intervention',
        inspection,
        object.id_object_type
      );
    });
    return new HistoryIterator<T>(interventions);
  }

  public getLastIntervention(object: MapObject | string): Intervention | null {
    const history: HistoryItem | null = this.getObjectHistory(object);

    let result: Intervention | null = null;
    if (history != null && history.interventions.length > 0) {
      const id: string =
          history.interventions[history.interventions.length - 1],
        intervention: Intervention | undefined =
          this.interventionStorage.getIntervention(id);

      result = intervention || null;
    }

    return result;
  }
}
