export function enableMocks() {
  if(!window.navigator.camera) {
    window.navigator.camera = {
      PictureSourceType: true,
      DestinationType: true,
      getPicture(onSuccess) {
        if(onSuccess) {
          onSuccess('iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAARVBMVEW9v77///+6vLv7+/vh4+K6u7u+wL/m5ubb3dz4+PjS1NO9wcC7vLvNz87w8PDm6OfIyMjd397x8/LQ0tHr6+vDw8PR0NGKav19AAAEvUlEQVR4nO2ca3ezKhBGYRS8BJWa2P//Uw+Y2nrBNObVyJw++0PXamNsdsCRGRAhAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAG7gcxtlmXxwnGIkhnf0BDofoYEs69UvU7r8bpQwZUgdAd04UFL4JbZ4dRuP4qE5VJNvKw2lPc7wQlYlMjleUpTpJ0dh36DkSS3TGvYOofo+g66j6DMPL5V1N6BqxOsWQyncJSpmfEmwof59hqk4YxF3eakji/2+o32743l56Rhv+NcO02p2PuAxzcgmGCTA6Pvj6GkTX2Ax3P318hjvXMCYDijgMA/TFlgv1ndWIbQGfh6Gzc5efUsZl7BsFmRhqQbpK67qts9Iaobc48jAUOv8uAiSZ3RSNWBgqO8kgi3KLIgdDssVYUCYyV8+XzjgYzgS94oZWjN+QVKDIUVgTOtUI4+Ku9mE3ckP/cYIlgNt62czXl5Wy+a3I/G/xG6pwKbV61Ihkb/6YWnAwpCooKNN1QxLp/Zjanyx+w3HyM6IOGroeStQ1wzE8DBsZJAlfiH4K6/b9LfAwXCsXr4Qaco0+1F+ZG65ch+rz5xAmhiu9NGyolR6FXiaGa5Em3EvV+GRMDNfuFmFDPR7h8TAUog1O3NiloaapDhfDcDk1PGojNRmkMzFUoZF3YlUo0sy6NAdD/7Lo5tmTn68OCs4CLxNDNxCrFhmwu+ICtRrS0wOZGPoI0i/V+A44RUk6mAHP4y4XQ5/FilElKu1W1v9oSrkYkrV65kC6TOu2rZu8Wy1gaGp5GGrtAkbb2LmjIX3RD1dvdQkTw/tY1PfGyWH98r7LA8PJHAwDQynbQOn3UY1tMYSN2DC7/1JUoXuC596UY93LOPWN33C4cRd2RdFfjtNLsh/B8jP0y7RC7yDqqsrH2x/JyzLQsDCUzawR+6XE1+bukjRlp/RwxDLPYmEor7Ply0Sf45F4kXVDOy5Xj/EwLGb3DD0bt8jkY2jDRTWAh6FsRpmgMbaWi9W27T2VUouKDhNDaYelJlqpxTTUvZ0rpYRR85sFG8PbkAsqE8gUe5LKKGMWmTIXQznMpBmxupg4ca2oF1M4bAxvbsztcyizUljsFa1aNjAXw0T24VSv1RW/bLRe1OS4GEr5QS6pp2UvnJBxNiyEu2OYXD58KCNZzhXzMZSf7mawGHUuFDkbZkap+VjmCRgZFp165aEMRoayVNkLT0ZxMsx+vQq5GxYvrXbnZPjaw3usDF8ChjCEIQxhCEMYwhCGMIQhDGEIw5gNhd7TMM5nZtbWdm8li9XwlzmY5ykjNdTuUy2mc1+hFpEaen6ZZ3qKrzUOkRoSpf+4tVLRDEs04jPsP5mmzlbXl3f7uFbdsFA6QsPhz/+wE+D4rfEa7nb6qA1f3u5jfPrYDP18dr/Bx3RTmo18vbv/EZlhuf/mnp9xGSbF/sRleDB/Yr82GB4ADHc2PGNXwZ2ywWcog0/1Hc/Kutj9Kbqt+2jtw/u6aX7StuX9XiTH77SbyJs4Zd9yLX62WzmW9KSt5+9papW1yaEUWXVvwFMijUdp3R2I1sNjG6cZAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH+P/wBnRXQQvKFTsAAAAABJRU5ErkJggg==');
        }
      }
    };
  }

  if(!window.imageSaver) {
    window.imageSaver = {
      saveBase64Image(options, onSuccess) {
        if(onSuccess) {
          onSuccess('assets/images/tech.jpg');
        }
      }
    };
  }

  if(!window.FileUploadOptions) {
    window.FileUploadOptions = class FileUploadOptions {};
  }

  if(!window.FileTransfer) {
    window.FileTransfer = class FileTransfer {
      public upload(
        filePath: string,
        url: string,
        onSuccess: Function,
        onError: Function,
        options: any
      ): void {
        setTimeout(() => onSuccess({
          response: '{"status": "' + filePath.slice(filePath.lastIndexOf('/') + 1) + '"}'
        }), 500);
      }
    };
  }
}
