import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';

@Injectable()
export class RoutingHistoryService {
  private history: string[] = [];

  constructor(
    private router: Router
  ) {}

  public getLastUrl(): string | null {
    return this.history.length > 0
      ? this.history[this.history.length - 1]
      : null;
  }

  public back(): boolean {
    if(this.history.length > 1) {
      this.history.pop();
      this.router.navigateByUrl(this.getLastUrl()!);

      return true;
    }

    return false;
  }

  public init(): void {
    this.router.events.subscribe((e: RouterEvent) => {
      if(e instanceof NavigationEnd) {
        const replace: boolean = (this.router as any).navigations.value.extras.replaceUrl;
        const url: string = this.router.url;

        if(this.history.length === 0) {
          this.history.push(url);

          return;
        }

        if(replace) {
          if(this.history.length > 1) {
            const prevUrl: string = this.history[this.history.length - 2];

            if(url !== prevUrl) {
              this.history[this.history.length - 1] = url;
            }
            // Дублирование двух последних записей (урлов) в истории.
            // Это признак того, что скорее всего
            // был переход назад средствами браузера (window.history.back()).
            // И раз был переход назад, то нужно просто удалить последнюю запись.
            else {
              this.history.pop();
            }
          }
          else {
            this.history[this.history.length - 1] = url;
          }
        }
        else {
          const lastUrl: string = this.getLastUrl()!;

          // Для избежания дублирования записей в истории.
          // При использовании RoutingHistoryService.back()
          // происходит переход к предыдущей записи (this.router.navigateByUrl(...)).
          // Во время перехода возникнет событие NavigationEnd в
          // обработчике которого в историю будет добавлен url на который был
          // призведен переход, что и приведет к дублированию.
          //
          // history = [A, B];
          // RoutingHistoryService.back();
          //   history.pop(); // [A]
          //   router.navigateByUrl(A);
          // NavigationEndHandler
          //   history.push(A); // [A, A]
          if(url !== lastUrl) {
            this.history.push(url);
          }
        }
      }
    });
  }
}
