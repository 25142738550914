import { Component, EventEmitter, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';

import { TreeService } from '../tree.service';

@Component({
  selector: 'app-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss']
})
export class TreeNodeComponent implements OnInit {
  @Input()
  public title: string = '';

  @Output()
  public changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public children: TreeNodeComponent[] = [];

  public checked: boolean = true;

  constructor(
    @Optional() @SkipSelf()
    public parent: TreeNodeComponent,
    private treeService: TreeService
  ) {}

  ngOnInit() {
    if(this.parent != null) {
      this.parent.children.push(this);
    }
  }

  public onChange(value: boolean): void {
    this.checked = value;
    this.treeService.updateNodes(this);
    this.changed.emit(value);
  }
}
