import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Inspection, InspectionQualityChecker } from '../../core/services/inspection';
import { MapObjectWithLastInspection, MapObject } from '../../core/services/map-object';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { StorageService } from 'src/app/core/services/map-object/storage.service';
import { StorageService as LocalStorageService } from 'src/app/core/services/storage.service';
import { HistoryStorageService } from '../../core/services/history';
import { RoutingHistoryModule } from '../../routing-history/routing-history.module';
import { RoutingHistoryService } from '../../routing-history/services';
import { StorageService as ObjectService } from '../../core/services/map-object/storage.service';
import { PaginationService } from 'src/app/core/services/pagination.service';
import { UserService } from '../../core/services/user.service';
import { I18nService}  from '@i18n';

@Component({
  selector: 'app-intervention-list',
  templateUrl: './intervention-list.component.html',
  styleUrls: ['./intervention-list.component.scss']
})
export class InterventionListComponent implements OnInit {
  @ViewChild(MatPaginator)
  private paginator: MatPaginator;

  @HostListener('window:click', ['$event'])
  public onClick(event: any) {
    if (!event.target.closest('.inspection__buttons') && !event.target.closest('.opener')) {
      this.actionIndex = null;
    }
  }
  @Input() 
  public typeName: string = '';

  @Input()
  public typeLibId: string;

  @Input() 
  public interventions: any[];

  @Input() 
  public objects: any[];

  @Output()
  public edit: EventEmitter<MapObjectWithLastInspection> = new EventEmitter<MapObjectWithLastInspection>();

  @Output()
  public history: EventEmitter<MapObjectWithLastInspection> = new EventEmitter<MapObjectWithLastInspection>();

  @Output()
  public interventionHistory: EventEmitter<MapObject> = new EventEmitter<MapObject>();

  @Output()
  public intervention: EventEmitter<MapObject> = new EventEmitter<MapObject>();

  @Output()
  public control: EventEmitter<MapObject> = new EventEmitter<MapObject>();

  private qualityChecker: InspectionQualityChecker = new InspectionQualityChecker();

  public readonly pageSize: number = 10;

  public currentInterventions: any[];

  public typeLib: any;

  public actionIndex: number | null = null;

  public galleryObject: any;

  public galleryVisible: boolean = false;

  public slickConfig: any = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: true,
    prevArrow: '<div class="slick-prev"><i class="material-icons">keyboard_arrow_left</i></div>',
    nextArrow: '<div class="slick-next"><i class="material-icons">keyboard_arrow_right</i></div>'
  }

  public allFormStructure: any = [];

  public list: any = {
    '1': 'first',
    '2': 'second',
  }
  

  public usersEmails: any = {};

  public displayInList: any = [];

  constructor(
    private appConfing: AppConfigService, 
    private storageServiceMap: StorageService,
    private localStorageService: LocalStorageService,
    private routesHistory: RoutingHistoryService,
    private objectsService: ObjectService,
    private paginationService: PaginationService,
    private userService: UserService,
    public i18n: I18nService,
    private storageService: StorageService,
  ) {
    this.typeLib = this.localStorageService.get('typeLib') || [];
    this.usersEmails = this.userService.getUsersEmails();
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.interventions) {
      //this.currentInterventions = changes.objects.currentValue.slice(this.paginator._pageIndex, this.pageSize);
      const page: number | null = this.paginationService.getPage(`intervention_${this.typeLibId}`);
      if (page !== null) { 
        this.onPageChanged(page);
        this.paginator._pageIndex = page;
      } else {
        this.onPageChanged(0);
        this.paginator._pageIndex = 0;
      }
    }
  }

  ngOnInit() {
    this.currentInterventions = this.interventions;
    this.displayInList = this.getDisplayInList();
    this.allFormStructure = this.localStorageService.get('allFormStructure');
  }

  private canDisplay (key: string): boolean {
    return this.displayInList.indexOf(key) !== -1;
  }

  public getDisplayInList(): any {
    const list: any = this.typeLib.find((t: any)=> t.id === this.typeLibId);
    let fList: any = [];
    if (list && list.display_in_list && list.display_in_list.intervention) {
      for(let i in list.display_in_list.intervention) {
        if (list.display_in_list.intervention[i] === true) {
          fList.push(i)
        }
      }
    }
    return fList;
  }

  public getClass(intervention: any): string {
    const object: any = this.objectsService.getObject(intervention.objectId);

    return this.qualityChecker.getPinState(object);
  }

  public onEditClicked(object: MapObjectWithLastInspection): void {
    this.edit.next(object);
  }

  public onHistoryClicked(object: MapObjectWithLastInspection): void {
    this.history.next(object);  
  }

  public onInterventionHistoryClicked(object: MapObjectWithLastInspection): void {
    this.interventionHistory.next(object); 
  }

  public onInterventionClicked(object: MapObjectWithLastInspection): void {
    this.intervention.next(object);
  }

  public onControlClicked(object: MapObjectWithLastInspection): void {
    this.control.next(object);
  }

  public onPageChanged(page: number): void {
    this.paginationService.setPage(`intervention_${this.typeLibId}`, page);
    this.currentInterventions = this.interventions.slice(
      page* this.pageSize,
      (page+ 1) * this.pageSize
    );
  }

  public getPinIcon(object: any): string {
    let iconClass: string = '';
    
    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type:any) => {
        return type.id === typeLibId
      })
      iconClass = typeLib ? typeLib.fa : '';
    }
    return iconClass;  
  }

  public getPinColor(object: any): string {
//  console.log('intervention-list.component.ts');
    let iconColor: string = '';
    
    if (this.typeLib) {
      const typeLibId: string | undefined = object.id_object_type;
      const typeLib: any = this.typeLib.find((type:any) => {
        return type.id === typeLibId
      })
      iconColor= typeLib ? typeLib.bg_color: '';
    }
    return iconColor;  
  }


  public onOpenerClick(index: number): void {
    if (this.actionIndex === index) {
      this.actionIndex = null;
    } else {
      this.actionIndex = index;
    }
  }

  public hasImages(object: any): boolean {
    return object.image && typeof object.image === 'object' && object.image.length
  }

  public onGalleryClick(object: any): void {
    this.galleryObject = object;
    this.galleryVisible = true;
  }

  public closeGallery(): void {
    this.galleryVisible = false;
  }

  public getUserEmail(id: string): string {
    return this.usersEmails[id] || '';
  }

  public getLib(el){
    console.log("content"+el);
    return el;
  }
}
