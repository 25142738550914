import { Injectable } from '@angular/core';

import { Customer } from './customer';
import { StorageService } from 'src/app/core/services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private customers: Customer[];

  private readonly storageKey: string = 'customers';

  constructor(private storage: StorageService) {
    this.customers = this.storage.get(this.storageKey) || [];
  }

  public addCustomer(customer: Customer): void {
    this.customers.push(customer);
    this.storage.set(this.storageKey, this.customers);
  }

  public getCustomer(id: number): Customer | null {
    return this.customers.find((c) => c.id_users === id) || null;
  }

  public getCustomers(): Customer[] {
    return this.customers;
  }

  public removeCustomer(customer: Customer | number): void {
    const index: number =
      typeof customer === 'number'
        ? customer
        : this.customers.indexOf(customer);

    if (index >= 0) {
      this.customers.splice(index, 1);
      this.storage.set(this.storageKey, this.customers);
    }
  }

  public removeCustomers(): void {
    this.customers = [];
    this.storage.set(this.storageKey, this.customers);
  }
}
