
import * as moment from 'moment';
import { JsonValue } from './../types/form-field';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FILTERS_URL, FILTERS_USER_URL } from '../../../server-url';
import {
  FilterStructure,
  FilterType,
  FilterTypeObject,
  DEFAULT_FILTER_FIELDS,
  FilterUserRequest,
} from '../types/filter';
import { FormField } from '../types/form-field';
import { AuthorizationService } from './authorization';
import { MapObject } from './map-object';
import { StorageService } from './storage.service';

import { RequestService } from './filter-service/request.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  public serverURL: string;

  private filtersData: any;

  private lsKey = 'filters';

  private userId = '';

  private appId = '';

  public typeLib: any;

  public object_types: any;

  public defaultFiltersFields: FormField[] = DEFAULT_FILTER_FIELDS;

  public tab: JsonValue[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthorizationService,
    private request: RequestService,
    private notification: NotificationService,
    private storageService: StorageService
  ) {
    this.serverURL = this.auth.getActualServerURL();
    // this.serverURL = https://api.kartes.io

    this.typeLib = this.storageService.get('typeLib') || [];
    this.object_types = this.storageService.get('object_types') || [];

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
      this.typeLib = this.storageService.get('typeLib') || [];
      this.object_types = this.storageService.get('object_types') || [];
      this.filtersData = this.storageService.get(this.lsKey) || {};
    });
    this.auth.userLoggedOut$.subscribe(() => {
      this.clearFilters();
      this.typeLib = [];
      this.object_types = [];
    });
    this.filtersData = this.storageService.get(this.lsKey) || {};
  }

  public getFiltersData(): Observable<any> {
    return of(this.filtersData);
  }

  public setFiltersData(filters: any): void {
    this.storageService.set(this.lsKey, filters);
    this.filtersData = filters;
  }

  public sendFiltersData(filters: any): Observable<any> {
    this.setFiltersData(filters);

    this.userId = this.storageService.get('userId');
    const reqData: any = {
      id_user: this.userId,
      filter: filters,
    };
    return this.http.post(`${this.serverURL}${FILTERS_USER_URL}`, reqData, {
      responseType: 'text',
    });
  }

  public clearFilters(): void {
    this.storageService.set(this.lsKey, {});
    this.filtersData = {};
  }

  public getFiltersStructure(): Observable<any> {
    this.appId = this.storageService.get('appId');
    this.userId = this.storageService.get('userId');

    // np if id_users not pushed
    if(!this.defaultFiltersFields.find((i: { name: string; }) => i.name === 'id_users')){
      this.request
        .getOwnerEmail(this.storageService.get('appId'))
        .then((data) => {
          this.defaultFiltersFields.push({
            id_object_type_fields: '1',
            id_object_type: '1',
            order_num: -1,
            name: 'id_users',
            lib: 'Propriétaire',
            input_type: 'select',
            created_at: '22-02-2020',
            mandatory: 1,
            placeholder: 'Propriétaire',
            info: 'more info',
            form_name: 'add',
            updated_at: '23-02-2020',
            json_values: data,
          });
        });
    }
     // np if due_date not pushed
    if(!this.defaultFiltersFields.find((i: { name: string; }) => i.name === 'due_date')){
          this.defaultFiltersFields.push({
            id_object_type_fields: '1',
            id_object_type: '1',
            order_num: 0,
            name: 'due_date',
            lib: 'Échéance',
            input_type: 'date',
            created_at: '22-02-2020',
            mandatory: 1,
            placeholder: 'Propriétaire',
            info: 'more info',
            form_name: 'add',
            updated_at: '23-02-2020',
          });
    }
    return this.http
      .get(
        `${this.serverURL}${FILTERS_URL}?app_id=${this.appId}&user_id=${this.userId}`
      )
      .pipe(
        tap((data) => {
          // data ====>  (object | inspection | intervention)
          return this.normalizeFormData(data);
        })
      );
  }

  public normalizeFormData(data: any): any {
    this.addDefaultFields(data);
    data.forEach((obj: any) => {
      if (obj.objects) {
        obj.objects.forEach((item: any) => {

          let doublon = false;
          if (item.fields) {
            item.fields.sort((a, b) => a.order_num - b.order_num);
          }
        });
      }
    });

    return data;
  }

  public addDefaultFields(filters: FilterStructure[]): any {
    this.typeLib.forEach((lib) => {
      filters.forEach((filter) => {
        const object: FilterTypeObject | undefined = filter.objects.find(
          (o: FilterTypeObject) => o.id_object_type === lib.id
        );
        if (object) {
          object.fields = [...object.fields, ...this.defaultFiltersFields];
        } else {
          this.addFilterTypeDefaultObject(filter.objects, lib.id);
        }
      });
    });
    return filters;
  }

  public addFilterTypeDefaultObject(
    objects: FilterTypeObject[],
    libId: number
  ): FilterTypeObject[] {
    const typeObject: FilterTypeObject = {
      id_object_type: libId.toString(),
      fields: this.defaultFiltersFields,
    };

    objects.push(typeObject);
    return objects;
  }

  public filter(type: FilterType, object: any, objectTypeId?: string): boolean {
    let result = true;
    const filter: Object | undefined = this.filtersData[type]
      ? this.filtersData[type][objectTypeId || object.id_object_type]
      : undefined;

    if (filter && Object.keys(filter).length) {
      if (filter['lastDays']) {
        if (object.created_at) {
          result = moment(object.created_at)
            .startOf('day')
            .isBetween(
              moment().startOf('day').add(-filter['lastDays'], 'day'),
              moment().startOf('day')
            );
        } else {
          result = false;
        }
      }  else if (filter['due_date'] ) {
        if (object.due_date) {
          filter['due_date'] = moment(filter['due_date']).format("YYYY-MM-DD"); 
          result = (object.due_date== moment(filter['due_date']).format("YYYY-MM-DD"));
        } else {
          result = false;
        }
      } 

      if (result === false) {
        return false;
      }

      for (const key in filter) {
        if (key !== 'startDate' && key !== 'endDate' && key !== 'lastDays' && key !== 'due_date') {
          if (filter[key] && filter[key].length) {
            if (object[key] && object[key].length) {
              if (typeof filter[key] === 'string') {
                if (object[key].toLowerCase() === filter[key].toLowerCase()) {
                  result = true;
                } else {
                  result = false;
                  break;
                }
              } else if (typeof filter[key] === 'object') {
                if (filter[key].indexOf(object[key].toLowerCase()) !== -1) {
                  result = true;
                } else {
                  result = false;
                  break;
                }
              }
            } else {
              result = false;
              break;
            }
          } else {
            result = true;
          }
        }
      }
    } else {
      result = true;
    }
    return result;
  }
}
