import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { AuthorizationService } from 'src/app/core/services/authorization';
import { CameraService } from 'src/app/core/services/camera.service';
import { ConfirmService } from 'src/app/core/services/confirm/confirm.service';
import { Customer, CustomersDataTransferService } from 'src/app/core/services/customers';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { GoogleGeocodeService } from '../../core/services/google-geocode.service';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

interface FormAddress {
  name: string,
  coords: {
    lat: number,
    lon: number
  }
}

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent implements OnInit {
  @ViewChild("placeSelect") 
  public placeSelect : ElementRef;
  
  private imagesLimit: number;

  private userId: number;

  public get imagesLimitReached(): boolean {
    return this.images.length >= this.imagesLimit;
  }

  public images: string[];

  public message: string;

  public priority: number;

  public processing: boolean;

  public serviceId: string;

  public typeLib: any;

  public typeLibId: number;

  public formattedaddress: any = " "; 

  public places: any = [];

  public place: string = '';

  public placesNames: string[] = [];

  public filteredPlaces:string[] = [];

  public address: any;

  public formAddress: FormAddress | undefined;

  constructor(
    private auth: AuthorizationService,
    private camera: CameraService,
    private confirm: ConfirmService,
    private dataTransfer: CustomersDataTransferService,
    private router: Router,
    private notification: NotificationService,
    private storageService: StorageService,
    private googleGeocodeservice: GoogleGeocodeService
  ) {}

  ngOnInit() {
    this.images = [];
    this.imagesLimit = 10;
    this.priority = 0;
    this.processing = false;
    this.userId = this.auth.getUserId() as number;
    this.typeLib = this.storageService.get('typeLib') || [];
  }

  private filterPlaces(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredPlaces = this.places.filter(option => {
      return option.formatted_address.toLowerCase().includes(filterValue)
    });
  }

  public AddressChange(address: any) { 
    //setting address from API to local variable 
     this.formattedaddress=address.formatted_address 
  } 

  private saveData(): Observable<any> {
    const customer: Customer = {
      content: this.message,
      id_services: this.serviceId,
      id_users:  this.userId,
      images: this.images, 
      priority: this.priority,
      id_object_type: this.typeLibId,
      address: this.formAddress ? this.formAddress.name : undefined,
      lat: this.formAddress ? this.formAddress.coords.lat : undefined,
      lon: this.formAddress ? this.formAddress.coords.lon : undefined,
    };

    return this.dataTransfer.sendCustomer(customer);
  }

  public addImage(imgSrc: string): void {
    if(!this.imagesLimitReached) {
      this.images.push(imgSrc);
    }
  }

  public addImageFromCamera(): void {
    this.camera
      .getPictureFromCamera()
      .then((src) => this.addImage(src))
      .catch(window.alert);
  }

  public addImageFromGallery(): void {
    this.camera
      .getPictureFromGallery()
      .then((src) => this.addImage(src))
      .catch(window.alert);
  }

  public onSubmit(): void {
  /*    this.confirm.open('Voulez-vous continuer?', (confirmed) => {
  if(confirmed) {*/
      this.processing = true;

      this.saveData().subscribe(
        () => {
          this.processing = false;

          this.router.navigate(['/report-end']);
        },
        (err) => {
          this.processing = false;

          console.error(err);

          this.notification.notify('error', 'Failed to save data!');
        }
      );
	/*      }
	});*/
  }

  public removeImage(index: number): void {
    this.images.splice(index, 1);
  }

  public searchLocations(): void {
    this.googleGeocodeservice.getPlacesByCoords().subscribe(
      (data)=> {
        this.convertPlacesGpsData(data.results);
      },
      (error)=> {
        this.notification.notify('error', 'Erreur lors de la géolocalisation');
      }
    )
  }

  public convertPlacesGpsData(data: any): void {
    this.places = [];

    this.setPlaceValue(data);
  }

  public setPlaceValue(places): void {
    this.place = places[0] ? `${places[0].formatted_address}` : '';

    this.googleGeocodeservice.changeGpsCoords(this.place);
  }

  public getAddress(place: any | undefined) { 
    if (place !== undefined) {
      this.formAddress = {
        name: place.formatted_address,
        coords: {
          lat: +place.geometry.location.lat(),
          lon: +place.geometry.location.lng()
        }
      };
    } else {
      this.formAddress = undefined;
    }
  }
}

