import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public notify(
    type: 'primary' | 'error',
    message: string,
    duration = 1000
  ): void {
    this.snackBar.open(message, '', {
      panelClass: type === 'error' ? 'mat-snack-bar-error' : '',
      duration: duration,
    });
  }

  public wait(
    message: string,
  ): void {
    this.snackBar.open(message, '', {
      panelClass: 'mat-snack-bar-error' ,
    });
  }
}
