import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[appCollapsible]',
  exportAs: 'appCollapsible'
})
export class CollapsibleDirective implements OnInit, OnDestroy {
  public instance: any;

  constructor(
    private elem: ElementRef
  ) {}

  ngOnInit() {
    this.instance = M.Collapsible.init(this.elem.nativeElement);
  }

  ngOnDestroy() {
    this.instance.destroy();
  }
}
