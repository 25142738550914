import { Injectable, } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, from, of, throwError } from 'rxjs';
import { catchError, concatAll, map, switchMap, tap } from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Intervention } from './intervention';
import { StorageService } from './storage.service';
import { StorageService as LocalStorageService } from '../storage.service';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService { 
  public serverURL: string;

  constructor(
    private appConfig: AppConfigService,
    private http: HttpClient,
    private storage: StorageService,
    private localStorage: LocalStorageService,
    private auth: AuthorizationService
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(
      ()=> {
        this.serverURL = this.auth.getActualServerURL();
      }
    )
  }

  private _send(intervention: Intervention): Observable<any> {
    return this.http.post(`${this.serverURL}/api/sync`, {
      app_id: this.localStorage.get('appId'),
      user_id: this.localStorage.get('userId'),
      interventions: {
        [intervention.type]: [intervention]
      }
    });
  }

  public send(intervention: Intervention): Observable<any> {
    return of(null).pipe(
      map(() => {
        try {
          this.storage.addUnsentIntervention(intervention);

          return true;
        }
        catch(error) {
          return false;
        }
      }),
      switchMap((saved: boolean) => {
        return this._send(intervention).pipe(
          map(() => true),
          catchError((error) => !saved ? throwError(error) : of(false)),
          tap((sent: boolean) => sent && this.storage.removeUnsentIntervention(intervention))
        );
      })
    );
  }

  public synchronize(): Observable<any> {
    const interventions: Intervention[] = this.storage.getAllUnsentInterventions();

    if(interventions.length > 0) {
      const observables: Observable<any>[] = interventions.map((intervention: Intervention) => {
        return this._send(intervention).pipe(tap({
          complete: () => this.storage.removeUnsentIntervention(intervention)
        }));
      });

      return from(observables).pipe(concatAll());
    }
    else {
      return of(null);
    }
  }
}
