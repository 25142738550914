import { Injectable } from '@angular/core';

import { Observable, from, of } from 'rxjs';
import { mergeAll, retry } from 'rxjs/operators';

import { CustomersDataTransferService } from './customers';
import { InspectionDataTransferService } from 'src/app/core/services/inspection';
import { MapObjectDataTransferService } from 'src/app/core/services/map-object';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private timerId: any;

  constructor(
    private customersDataTransfer: CustomersDataTransferService,
    private inspectionDataTransfer: InspectionDataTransferService,
    private mapObjectDataTransferService: MapObjectDataTransferService
  ) {}

  public synchronize(): Observable<any> {
     return from([
      this.customersDataTransfer.synchronize(),
      this.inspectionDataTransfer.synchronize(),
      this.mapObjectDataTransferService.synchronize()
    ]).pipe(mergeAll(), retry(1));
  }

  public synchronizeWithInterval(ms: number): void {
    clearInterval(this.timerId);
    this.timerId = setInterval(() => this.synchronize().subscribe(), ms);
  }
}
