import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class UuidInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.body) {
      const
        newBody = {
          ...req.body,
          uuid: window.device && window.device.uuid
        },
        newReq = req.clone({body: newBody});

      return next.handle(newReq);
    }
    else {
      return next.handle(req);
    }
  }
}
