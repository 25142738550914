import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  public inspectionListChanged: Subject<void> = new Subject<void>();

  public interventionListChanged: Subject<void> = new Subject<void>();


  constructor() { }
}
