
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableMocks } from './mocks';
import { ERROR_URL, UPDATE_URL, SERVER_TEST_URL, SERVER_PROD_URL } from './server-url';
import { getStorageInfo, mergeStorageInfo, setStorageInfo } from './storage-info';
import { APP_VERSION } from './version';
//import { LOCALE_ID, NgModule } from '@angular/core';
import {MAT_DATE_LOCALE} from '@angular/material';



if(!window.device) {
    window.device = {
      uuid: 123
    };
}

const loader: HTMLElement | null = document.querySelector('.loading');
let updatesVersion;
let kmlLayers: Object[] = [];
let uploadedKmls = 0;
let totalKmls = 0;


if(environment.production) {
  enableProdMode();
}
else {
  enableMocks();
}

const serverTestUrl: string = SERVER_TEST_URL;
const serverProdUrl: string = SERVER_PROD_URL;

const login: string = getStorageInfo('userLogin');

const mapStyle: string = getStorageInfo('mapStyle');

const serverURL: string = getServerURL(login);

function getServerURL(login_str?: string): string {
	let url: string = serverProdUrl;

	if (login_str) {
		url = isTestAccount(login_str) ? serverTestUrl : serverProdUrl;
	}

	return url;
}

function isTestAccount(login_str?: string): boolean {
	return login.startsWith("test");
}

const deviceReady: Promise<void> = new Promise((resolve) => {
	isCordova() ? document.addEventListener('deviceready', onDeviceReady, false) : resolve();

	function onDeviceReady(): void {
		checkForAppUpdate(function() {
			resolve();
		});


	}
});

deviceReady.then(() => {
	//platformBrowserDynamic().bootstrapModule(AppModule{ providers: {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}}).catch(err => console.log(err));
	platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
});

function isCordova(): boolean {
	return Boolean(window.cordova);
}

function checkForAppUpdate(resolve) {
	let getData = (callback) => {

		let xhr = new XMLHttpRequest();
		xhr.open('GET', `${serverURL}${UPDATE_URL}`, false);

		xhr.onload = () => {
			let status = xhr.status;

			if (status == 200) {
				callback(null, xhr.responseText);
			} else {
				callback(status);
			}
		};

		xhr.send();
	};

	getData((err, data) => {
		if (err != null) {
			alert('Response error: ' +  err);
		} else {
			// updatesVersion = JSON.parse(data);
			// if (APP_VERSION !== updatesVersion.version) {
			// 	showLoader('Updating application <b class="dot">.</b><b class="dot">.</b><b class="dot">.</b>');

			// 	updatesVersion.lib.length ? setStorageInfo('lib', updatesVersion.lib): null;
			// 	updatesVersion.logo.length ? setStorageInfo('logo', updatesVersion.logo) : null;
			// 	updatesVersion.file.length ? updateApp(function() {
			// 		resolve();
			// 	}) : null;
			// } else {
			// 	resolve();
			// }
			resolve();
		}
	});
}

function setKmlSyncInfo() {
	const info = {
		kml: {
			date: new Date(),
			elements: totalKmls,
		}
	}
	mergeStorageInfo('syncinfo', info)
}

function changePageInfo() {
	if (getStorageInfo('logo').length) {
		const fav: HTMLElement | null = document.head.querySelector('link[rel="icon"]');
		fav ? fav.setAttribute('href', getStorageInfo('logo')) : null;
	}

	if (getStorageInfo('lib').length) {
		document.title = getStorageInfo('lib');
	}
}

function changeMapStyle(style: string){
  setStorageInfo('mapStyle',style);
}

function showLoader(text: string) {
	if (loader) {
		const textHolder: HTMLElement | null = loader.querySelector('.loading-inner');
		textHolder ? textHolder.innerHTML = text : null
		loader.style.display = 'flex';
	}
}

function hideLoader() {
	if (loader) {
		loader.style.opacity = '0';
		setTimeout(() => {
			loader.style.display = 'none';
			loader.innerHTML = '';
		}, 2000);
	}
}

function removePopup(e) {
	e.target.closest('.app-popup').remove();
}

function showPopup(html: string) {
	let popup: string = '<div class="app-popup">' +
		'<div class="app-popup-inner">' +
			'<button type="button" class="close" onclick="this.parentNode.parentNode.remove()">×</button>' +
			'<div class="app-popup-content">' + html + '</div>' +
		'</div>' +

	'</div>';
	setTimeout(() => {
		document.body.innerHTML += popup;
	}, 500);

}

function updateApp(callback) {
	hideLoader();
	window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
	const permissions = cordova.plugins.permissions;
	permissions.hasPermission(permissions.WRITE_EXTERNAL_STORAGE, function (status) {
		if(!status.hasPermission) {
			let errorCallback = function () {
				sendError("Error: app requires storage permission");
				hideLoader();
			};

			permissions.requestPermission(permissions.WRITE_EXTERNAL_STORAGE,
				function (status) {
					if(!status.hasPermission) {
						errorCallback();
					}
					else {
						downloadFile(fileSystem);
					}
				},
				errorCallback()
			);
		} else {
			downloadFile(fileSystem);
		}
	}, null);
	let downloadFile = function (fileSystem) {
		const localPath = 'file:///storage/emulated/0/Download/keo-baes-update.apk';

		let fileTransfer = new FileTransfer();
		fileTransfer.download(updatesVersion.file, localPath, function (entry) {
			setStorageInfo('kml', []);
			callback();
			window.cordova.plugins.FileOpener.openFile('file:///storage/emulated/0/Download/keo-baes-update.apk', function () {
				hideLoader();
				showPopup(updatesVersion.successHtml);
			}, function (err) {
				errorHandler(err);
				console.log('error');

			});
			}, function (err) {
				errorHandler(err)
				console.log('error');
			});
		};
	}, function (evt) {
		errorHandler(evt.target.error)
	});

}

function errorHandler(evt) {
	sendError(evt);
	hideLoader();
	showPopup(updatesVersion.errorHtml);
}

function sendError(err) {
	let xhr = new XMLHttpRequest();
	const date: any = Date.now();

	let data = JSON.stringify({
		uuid: window.device && window.device.uuid,
		error: err,
		version: APP_VERSION
	});

	xhr.open("POST", `${serverURL}${ERROR_URL}` + '?t=' + date)

	xhr.send(data);
}
