import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public emails: any = {};

  public lsEmailsKey: string = 'emails';

  constructor(
    private storage: StorageService
  ) { }

  public getUsersEmails(): any {
    return this.storage.get(this.lsEmailsKey);
  }
}
