import { Injectable } from '@angular/core';

import { AuthorizationService } from 'src/app/core/services/authorization/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly imagePlaceholderUrl: string =
    'http://wp-keo-baes-dev.mes-serveurs.net/assets/placeholder.jpg';

  constructor(private auth: AuthorizationService) {}

  public getImagePlaceholderUrl(): string {
    return this.imagePlaceholderUrl;
  }

  public getColorValue(color: string): string {
    if (color === 'green') {
      return '#0da6b3';
    } else if (color === 'red') {
      return '#ef5350';
    } else {
      return '#ffa726';
    }
  }
}
