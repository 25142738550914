import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizationGuard } from 'src/app/core/services/authorization';

import { AboutComponent } from './pages/about/about.component';
import { AccountComponent } from './pages/account/account.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { BaesInspectionListComponent } from './pages/baes-inspection-list/baes-inspection-list.component';
import { BaesInterventionListComponent } from './pages/baes-intervention-list/baes-intervention-list.component';
import { BaesInspectionComponent } from './pages/baes-inspection/baes-inspection.component';
import { BaesObjectEditorComponent } from './pages/baes-object-editor/baes-object-editor.component';
import { LoginComponent } from './pages/login/login.component';
import { MapComponent } from './pages/map/map.component';
import { ReportEndComponent } from './pages/report-end/report-end.component';
import { ReportingComponent } from './pages/reporting/reporting.component';
import { OfflineComponent } from './pages/offline/offline.component';
import { InterventionGuard } from './core/services/intervention';
import { InspectionGuard } from './core/services/inspection';
import { BaesInterventionComponent } from './pages/baes-intervention/baes-intervention.component';
import { BaesHistoryInterventionComponent } from './pages/baes-history-intervention/baes-history-intervention.component';
import { BaesHistoryInspectionComponent} from './pages/baes-history-inspection/baes-history-inspection.component';
import { PermissionGuard } from './core/services/permission.guard';
import { FiltersComponent } from './pages/filters/filters.component';

const routes: Routes = [
  {
    path: 'offline',
    component: OfflineComponent,
  },
  {
    path: '',
    canActivateChild: [AuthorizationGuard, PermissionGuard],
    children: [
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: 'account',
        component: AccountComponent,
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
      },

      {
        path: 'baes-history-inspection',
        component: BaesHistoryInspectionComponent,
        data: {
          scope: 'can_viewhistory',
        },
      },
      {
        path: 'baes-history-intervention',
        component: BaesHistoryInterventionComponent,
      },
      {
        path: 'baes-inspection',
        canActivate: [InspectionGuard],
        component: BaesInspectionComponent,
        data: {
          scope: 'can_control',
        },
      },
      {
        path: 'baes-intervention',
        canActivate: [InterventionGuard],
        component: BaesInterventionComponent,
        data: {
          scope: 'can_action',
        },
      },
      {
        path: 'baes-inspection-list',
        component: BaesInspectionListComponent,
        data: {
          scope: 'can_listing',
        },
      },
      {
        path: 'baes-intervention-list',
        component: BaesInterventionListComponent,
        data: {
          scope: 'can_listing',
        },
      },
      {
        path: 'baes-object-editor',
        component: BaesObjectEditorComponent,
        // data: {
        //   scope: 'can_edit'
        // }
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'map',
        component: MapComponent,
        data: {
          scope: 'can_showmap',
        },
      },
      {
        path: 'reporting',
        component: ReportingComponent,
      },
      {
        path: 'report-end',
        component: ReportEndComponent,
      },
      {
        path: 'filters',
        component: FiltersComponent,
      },
      {
        path: '',
        redirectTo: '/map',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '/about',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
