import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { ERROR_URL } from '../../../../server-url';
import { APP_VERSION } from '../../../../version';
import { AuthorizationService } from '../authorization/authorization.service';
import { LoaderService } from '../loader.service';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class MapLayersService {
  public layers: any;

  public kmlStorage: any[];

  private updateLayers: Subject<any>;

  public updateLayers$: Observable<any>;

  private permissionError: Subject<boolean>;

  public permissionError$: Observable<boolean>;

  public kmlLayers: Object[] = [];

  public uploadedKmls = 0;

  public totalKmls = 0;

  public updatedKmls: number;

  public firstLoad = false;

  public serverURL: string;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    public loaderService: LoaderService,
    private auth: AuthorizationService
  ) {
    this.serverURL = this.auth.getActualServerURL();

    this.auth.userLoggedIn$.subscribe(() => {
      this.serverURL = this.auth.getActualServerURL();
    });

    this.updateLayers = new Subject<any>();
    this.updateLayers$ = this.updateLayers.asObservable();

    this.permissionError = new Subject<boolean>();
    this.permissionError$ = this.permissionError.asObservable();
  }

  public getLayers(): any {
    return this.storageService.get('kml');
  }

  public synchronizeLayers(data: any): Observable<any> {
    const self = this;
    this.totalKmls = 0;
    this.uploadedKmls = 0;
    this.updatedKmls = 0;
    this.kmlLayers = [];
    this.kmlStorage = this.getLayers() || [];

    return Observable.create((observer: Observer<any>) => {
      this.storageService.set('userLogo', data.logo);
      if (data.kml.length) {
        let loader = false;
        let update = true;

        this.kmlLayers = this.storageService.get('kml') || [];
        if (this.kmlStorage !== null && this.kmlStorage.length) {
          data.kml.forEach((kml) => {
            const i: any | undefined = this.kmlStorage.find(
              (el: any) => el.kmlName === kml.kmlName
            );

            if (i === undefined) {
              this.totalKmls++;
            } else {
              if (i.version !== kml.version) {
                this.totalKmls++;
              }
            }
          });

          if (this.totalKmls) {
            data.kml.forEach((kml) => {
              update = true;
              this.kmlStorage.forEach((storageKml) => {
                if (storageKml.kmlName === kml.kmlName) {
                  if (storageKml.version === kml.version) {
                    update = false;
                  }
                }
              });

              if (this.totalKmls === this.uploadedKmls) {
                observer.next(0);
                observer.complete();
              }

              if (update) {
                this.updateKml(
                  kml,
                  function () {
                    self
                      .checkDeletedKml(self.kmlLayers, data.kml)
                      .subscribe((data) => {
                        self.storageService.set('kml', data);
                        self.updateLayers.next(data);
                        observer.next(data);
                        observer.complete();
                      });
                  },
                  function () {
                    observer.error('Erreur de synchronisation kml');
                    observer.complete();
                  }
                );
              }
            });
          } else {
            self.checkDeletedKml(self.kmlLayers, data.kml).subscribe((data) => {
              self.storageService.set('kml', data);
              self.updateLayers.next(data);
              observer.next(data);
              observer.complete();
            });
          }
        } else {
          this.totalKmls = data.kml.length;
          data.kml.forEach((kml) => {
            this.updateKml(
              kml,
              function () {
                self.storageService.set('kml', self.kmlLayers);
                self.updateLayers.next(self.kmlLayers);
                observer.next(self.updatedKmls);
                observer.complete();
              },
              function () {
                observer.error('Erreur de synchronisation kml');
                observer.complete();
              }
            );
          });
        }
      } else {
        self.storageService.set('kml', []);
        self.updateLayers.next([]);
        observer.next([]);
        observer.complete();
      }
    });
  }

  public setPermissionErrorState(state: boolean): void {
    this.permissionError.next(state);
  }

  public updateKml(kml: any, success: any, error: any): void {
    const self: any = this;
    if (window.cordova) {
      window.requestFileSystem(
        LocalFileSystem.PERSISTENT,
        0,
        (fileSystem: any) => {
          const downloadKml = function (fileSystem) {
            const directoryEntry: any = fileSystem.root;
            const folderName = 'KeoBaes';

            directoryEntry.getDirectory(
              folderName,
              {
                create: true,
                exclusive: false,
              },
              onDirectorySuccess,
              onDirectoryFail
            );

            function onDirectorySuccess(parent) {
              let createNew = true;
              const fileTransfer: any = new FileTransfer();

              const fileName: string = kml.url.split('/');
              const path: string =
                'cdvfile://localhost/temporary/' +
                fileName[fileName.length - 1]; // parent.nativeURL + fileName[fileName.length - 1];
              self.loaderService.setSubscription(
                'Téléchargement de KML [' + kml.kmlName + ']'
              );

              fileTransfer.download(
                kml.url,
                path,
                function (entry) {
                  const kmlObj: {
                    name: string;
                    kmlName: string;
                    url: string;
                    fullPath: string;
                    coords: {
                      lat: string;
                      lng: string;
                    };
                    floor: string;
                    version: string;
                  } = {
                    name: kml.name,
                    kmlName: kml.kmlName,
                    url: entry.toInternalURL(), // entry.nativeURL,
                    fullPath: kml.url,
                    coords: kml.coords,
                    floor: kml.floor,
                    version: kml.version,
                  };

                  if (self.kmlStorage !== null && self.kmlStorage.length) {
                    self.kmlLayers.forEach((k: any) => {
                      if (k.kmlName === kml.kmlName) {
                        createNew = false;
                        Object.assign(k, kmlObj);
                      }
                    });
                  }

                  if (createNew) {
                    self.kmlLayers.push(kmlObj);
                  }

                  self.uploadedKmls++;

                  if (self.uploadedKmls === self.totalKmls) {
                    success();
                  }
                },
                function (err) {
                  // self.loaderService.hideLoader();
                  self.sendError(err);
                  error();
                }
              );
            }
          };

          function onDirectoryFail(error) {
            // self.loaderService.hideLoader();
            error();
            alert('Unable to create new directory: ' + error.code);
          }
          downloadKml(fileSystem);
        },
        function (evt) {
          // self.loaderService.hideLoader();
          error();
          self.sendError(evt.target.error);
        }
      );
    } else {
      this.loaderService.setSubscription(
        'Téléchargement de KML [' + kml.kmlName + ']'
      );
      let createNew = true;
      const kmlObj: {
        name: string;
        kmlName: string;
        url: string;
        fullPath: string;
        coords: {
          lat: string;
          lng: string;
        };
        floor: string;
        version: string;
      } = {
        name: kml.name,
        kmlName: kml.kmlName,
        url: kml.url,
        fullPath: kml.url,
        coords: kml.coords,
        floor: kml.floor,
        version: kml.version,
      };

      if (this.kmlStorage !== null && this.kmlStorage.length) {
        this.kmlLayers.forEach((k: any) => {
          if (k.kmlName === kml.kmlName) {
            createNew = false;
            Object.assign(k, kmlObj);
          }
        });
      }

      if (createNew) {
        this.kmlLayers.push(kmlObj);
      }
      this.uploadedKmls++;
      if (this.uploadedKmls === this.totalKmls) {
        success();
      }
    }
  }

  public sendError(err: any): void {
    const data = {
      uuid: window.device && window.device.uuid,
      error: err,
      version: APP_VERSION,
    };

    this.http
      .post(`${this.serverURL}${ERROR_URL}?t= + Date.now()`, data)
      .subscribe();
  }

  public checkDeletedKml(storage: any, income: any): Observable<any> {
    let kmlStorageUpdated: any = [];
    const incomeKmlNames: string[] = income.map((kml) => {
      return kml.kmlName;
    });
    return Observable.create((observer: Observer<any>) => {
      kmlStorageUpdated = storage.filter((sKml) => {
        return incomeKmlNames.includes(sKml.kmlName);
      });
      observer.next(kmlStorageUpdated);
      observer.complete();
    });
  }

  public calcCrow(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number {
    const R = 6371; // Radius of the earth in km
    const dLat: number = this.deg2rad(lat2 - lat1);
    const dLon: number = this.deg2rad(lon2 - lon1);
    const a: number =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d: number = R * c; // Distance in km
    return d;
  }

  public deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }
}
