import { StorageService } from "./app/core/services/storage.service";

const namespace: string = "KEOBAES";

export interface AnyObject {
  [key: string]: any;
}

export function setStorageInfo(key: string, val?: any): void{
    let
        ns: string,
        source: {};

    if(typeof key === 'string') {
      source = {};
      source[key] = val;
      ns = getNS(namespace);
    }
    else {
      source = key;
      ns = getNS(val);
    }

    for(let k in source) {
      if(source.hasOwnProperty(k)) {
        window.localStorage.setItem(ns + k, JSON.stringify(source[k]));
      }
    }
}

export function getStorageInfo(key: string | string[]): any | any[] {
    
    const ns: string = getNS(namespace);

    let
      result: any[] = [],
      singleResult: boolean = false;

    if(typeof key === 'string') {
      singleResult = true;
      key = [key];
    }

    result = key.map((k) => {
      let result: any;

      try {
        const value: string | null = window.localStorage.getItem(ns + k);

        result = typeof value === 'string' ? JSON.parse(value) : value;
      }
      catch(e) {
        result = null;
      }

      return result;
    });
    return singleResult ? result[0] : result;
}

export function mergeStorageInfo(key: string, val?: any): void{
  let
    ns: string,
    source: AnyObject;

  if(typeof key === 'string') {
    source = {};
    source[key] = val;
    ns = getNS(namespace);
  }
  else {
    source = key;
    ns = getNS(val);
  }

  for(let k in source) {
    if(source.hasOwnProperty(k)) {
      let
        storageKey: string = ns + k,
        s: any = source[k],
        t: any;

      try {
        t = JSON.parse(window.localStorage.getItem(storageKey) as string);
      }
      catch(e) {}

      if(
        t != null && typeof t === 'object' &&
        s != null && typeof s === 'object'
      ) {
        Object.assign(t, s);
        window.localStorage.setItem(storageKey, JSON.stringify(t));
      }
      else {
        window.localStorage.setItem(storageKey, JSON.stringify(s));
      }
    }
  }
}

function getNS(namespace?: string): string {
    let ns: string = namespace == null ? this.namespace : namespace;

    if(ns !== '') {
      ns += '.';
    }

    return ns;
}