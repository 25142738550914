import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subscriber } from 'rxjs';

import { GPSCoords } from './coords';

interface RequestQueueItem {
  request: Observable<any>;
  subscriber: Subscriber<any>;
}

@Injectable({
  providedIn: 'root'
})
export class GPSService {
  /**
   * Задержка перед выполнением запроса (ms).
   */
  private requestDelay: number;

  private requestInProcess: boolean;

  /**
   * https://operations.osmfoundation.org/policies/nominatim/
   * No heavy uses (an absolute maximum of 1 request per second).
   *
   * Для уменьшения частоты запросов используется эта очередь.
   */
  private requestQueue: RequestQueueItem[];

  constructor(
    private http: HttpClient
  ) {
    this.requestDelay = 1500;
    this.requestInProcess = false;
    this.requestQueue = [];
  }

  private makeRequest(): void {
    const item: RequestQueueItem | undefined = this.requestQueue.shift();

    if(item) {
      this.requestInProcess = true;
      item.request.subscribe({
        next: (response) => {
          item.subscriber.next(response);
          this.nextRequest();
        },
        error: (error) => {
          item.subscriber.error(error);
          this.nextRequest();
        }
      });
    }
    else {
      this.requestInProcess = false;
    }
  }

  private nextRequest(): void {
    setTimeout(() => this.makeRequest(), this.requestDelay);
  }

  public getAddress(coords: GPSCoords): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.requestQueue.push({
        request: this.http.get('https://nominatim.openstreetmap.org/reverse', {
          params: {
            format: 'jsonv2',
            lat: String(coords.latitude),
            lng: String(coords.longitude)
          }
        }),
        subscriber: subscriber
      });

      if(this.requestInProcess === false) {
        this.makeRequest();
      }
    });
  }

  public getCoords(): Observable<GPSCoords> {
    return new Observable<GPSCoords>((subscriber) => {
      if(navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => subscriber.next(position.coords),
          (error) => subscriber.error(error),
          {
            timeout: 30000,
            enableHighAccuracy: true
          }
        );
      }
      else {
        subscriber.error('Il semble que votre appareil ne dispose pas de GPS!');
      }
    });
  }

  public getCoordsByAddress(address: string): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.requestQueue.push({
        request: this.http.get(' https://nominatim.openstreetmap.org', {
          params: {
            addressdetails: '1',
            format: 'json',
            limit: '1',
            q: address
          }
        }),
        subscriber: subscriber
      });

      if(this.requestInProcess === false) {
        this.makeRequest();
      }
    });
  }
}
