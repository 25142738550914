import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanActivateChild {
  constructor(
    private auth: AuthorizationService,
    private router: Router
  ) { }

  private checkLogin(url: string): boolean {
    if(this.auth.isAuthorized()) {
      return url === '/login' ? false : true;
    }
    else {
      if(url === '/login') {
        return true;
      }
      else {
        this.router.navigate(['/login']); 

        return false;
      }
    }
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
