import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/core/services/authorization';
import { GPSService, LatLng } from 'src/app/core/services/gps';
import { MapStateService } from 'src/app/core/services/map';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService as LocalStorageService } from 'src/app/core/services/storage.service';
import { KmlRadius } from '../../core/globals';
import { MapActionsService, MapLayersService } from '../../core/services/map';
import { SidebarService } from '../../core/services/sidebar.service';
import { SyncServerService } from '../../core/services/sync-server.service';
import { interval, Subscription } from 'rxjs';
import { I18nService}  from '@i18n';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav')
  private navEl: ElementRef;

  private navInstance: any;

  public layers: Object[];

  public sortLayers: any;

  public initActiveLayer: string;

  public labels: string[] = [];

  public filteredByRadiusLayers: any;

  public firstLoad = true;

  public checkboxes: any;

  private updateKmlsLocationSubscription: Subscription;

  private activeChekboxes: any;

  public typeLib: Object[] | undefined;

  public updatePosition = true;



  constructor(
    private auth: AuthorizationService,
    private gps: GPSService,
    private mapService: MapStateService,
    private router: Router,
    private zone: NgZone,
    private syncServerService: SyncServerService,
    public i18n: I18nService,
    private notification: NotificationService,
    private localStorageService: LocalStorageService,
    private layersService: MapLayersService,
    private mapActionsService: MapActionsService,
    private sidebarService: SidebarService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.sortLayers = {};
    this.filteredByRadiusLayers = [];
    this.layers = this.layersService.getLayers() || [];
    this.typeLib = this.localStorageService.get('typeLib') || [];

    
    this.layersService.updateLayers$.subscribe((layers) => {
      for (const kml in this.layers) {
        this.changeMapState(
          'currentLayerHide',
          this.layers[kml]['kmlName'],
          this.layers[kml]['coords']
        );
      }
      this.setInactiveCheckboxes();
      this.layers = layers;
      this.setLayers();
    });

    this.mapActionsService.updateKmls$.subscribe(() => {
      for (const kml in this.layers) {
        this.changeMapState(
          'currentLayerHide',
          this.layers[kml]['kmlName'],
          this.layers[kml]['coords']
        );
      }
      this.setInactiveCheckboxes();
      this.setLayers();
    });

    this.setLayers();
    this.router.events
      .pipe(filter((e) => e instanceof NavigationStart))
      .subscribe(() => this.navInstance.close());

    // AUTO - SYNC
    const source = interval(400000);

    this.updateKmlsLocationSubscription = source.subscribe(() => {
      this.synchronize();
    });

    this.mapActionsService.updateKmlsLocation$.subscribe(() => {
      this.gps.getCoords().subscribe({
        next: (coords) => {
          this.updatePosition = false;
          if (!this.firstLoad) {
            this.mapActionsService.removeKmlsLayers();
            this.setInactiveCheckboxes();
          }

          this.filterKmlByRadius(coords);
          this.setFilteredByRadiusKmls();
          setTimeout(() => {
            this.setActiveCheckboxes();
          }, 100);
          this.updatePosition = true;
        },
        error: () => {},
      });
    });
  }

  ngOnDestroy() {
    this.navInstance.close();
    this.updateKmlsLocationSubscription.unsubscribe();
    this.navInstance.destroy();
  }

  ngAfterViewInit() {
    this.initNav();
    this.mapActionsService.locate();

    const elems = document.querySelectorAll('.collapsible');
    if (elems.length > 0) {
      const instances = M.Collapsible.init(elems);
    }
    this.cd.detectChanges();
  }

  public setCheckboxActivity(name: string): void {
    this.activeChekboxes.push(name);
  }

  public setLayers(): void {
    if (this.layers && this.layers.length) {
      this.sortKmlLayers();
    } else {
      this.labels = [];
      this.sortLayers = {};
    }
    this.firstLoad = false;
  }

  public filterKmlByRadius(coords): void {
    this.filteredByRadiusLayers = this.layers.filter((layer: any) => {
      return (
        this.layersService.calcCrow(
          coords.latitude,
          coords.longitude,
          layer.coords.lat,
          layer.coords.lng
        ) <= KmlRadius
      );
    });
  }

  public setFilteredByRadiusKmls(): void {
    this.filteredByRadiusLayers.forEach((kml) => {
      this.changeMapState('currentLayer', kml.kmlName, kml.coords);
    });
  }

  public sortKmlLayers(): void {
    this.labels = [];
    this.sortLayers = {};
    this.sortLayers = this.layers.reduce(function (r: any, a: any) {
      r[a.fullPath.split('/').splice(-2, 1)] =
        r[a.fullPath.split('/').splice(-2, 1)] || [];
      r[a.fullPath.split('/').splice(-2, 1)].push(a);
      return r;
    }, Object.create(null));

    this.labels = Object.keys(this.sortLayers);
    this.labels.sort();

    // Set init active kml
    if (this.sortLayers.length && this.labels.length) {
      this.changeMapState('currentLayer', this.initActiveLayer, null);
    }
  }

  public getCurrentLogin() {
    return this.auth.getCurrentLogin();
  }

  public setActiveCheckboxes(): void {
    this.filteredByRadiusLayers.forEach((kml) => {
      const checkbox: any = document.querySelector(
        '.list-kml input[name="' + kml.kmlName + '"]'
      );

      checkbox.checked = true;
    });
  }

  public setInactiveCheckboxes(): void {
    const checkboxes: any = document.querySelectorAll(
      '.list-kml input[type="checkbox"]'
    );

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    });
  }

  private initNav(): void {
    this.zone.runOutsideAngular(() => {
      this.navInstance = M.Sidenav.init(this.navEl.nativeElement, {
        inDuration: 1,
        outDuration: 1,
        draggable: true,
        edge: 'right',
        onOpenStart: (el) => document.body.classList.add('nav-active'),
        onCloseStart: (el) => document.body.classList.remove('nav-active'),
      });
    });
  }

  public changeMapState(key: string, value: any, coords: LatLng | null): void {
    this.mapService.setCurrentState(key, value);
    if (this.router.url.indexOf('/map') !== -1) {
      this.mapService.changeState(key, value);
      if (key === 'layersVisible' && value) {
        const activeChekboxes: any = document.querySelectorAll(
          '.list-kml input:checked'
        );
        if (activeChekboxes) {
          activeChekboxes.forEach((checkbox) => {
            this.mapService.changeState(
              'currentLayer',
              checkbox.getAttribute('name')
            );
          });
        }
      }
      if(coords !== null && key !== 'currentLayerHide'){
 	this.updatePosition ?  this.mapActionsService.setMapViewCoords(coords) : null;
      }
      /*coords !== null && key !== 'currentLayerHide' && this.updatePosition
        ? this.mapActionsService.setMapViewCoords(coords)
        : null;*/
    }
  }

  public closeNav(): void {
    this.navInstance.close();
  }

  public logout(): void {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  public synchronize(): void { 

          this.updatePosition = false;
//    this.closeNav();
    //this.toggleLoader('show');

     this.notification.notify('primary', "Synchronisation en cours");

//    this.notification.notify('primary', "Synchronisation en cours");
    this.syncServerService.updateObjectsFromServer().subscribe(
      (res) => {
  //   	this.notification.notify('primary', "Synchronisation terminée");
         // this.toggleLoader('hide');
       // console.log('sync done');
      },
      (err) => {
        console.log('sync error');
	console.log(err);

     	this.notification.notify('primary', "Erreur de Synchronisation");
        
      }
    );
  }

  public toggleLoader(action: string): void {
   // const loader: any = document.querySelector('.synchronization-loader');


     //   this.notification.notify('primary', "Synchronisation en cours");

/*    if (loader) {
      if (action === 'show') {
        loader.style.display = 'flex';
        //        loader.classList.add('transparent');
      } else {
        loader.style.display = 'none';
        //        loader.classList.remove('transparent');
      }
    }*/
  }

  public backToMap(): void {
    this.closeNav();
    //this.synchronize();

    if (this.router.url.indexOf('map') === -1) {
     // this.mapActionsService.locate();
      this.router.navigate(['/map']);
    } else {
     // this.mapActionsService.locate();
    }
  }

  public onInspectionListClick(): void {
    this.sidebarService.inspectionListChanged.next();
    this.closeNav();
  }

  public onInterventionListClick(): void {
    this.sidebarService.interventionListChanged.next();
    this.closeNav();
  }
}
