import { Injectable } from '@angular/core';
import { RoutingHistoryService } from '../../routing-history/services';
import { AuthorizationService } from './authorization/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  public pages: any = {};

  constructor(
    private routesHistory: RoutingHistoryService,
    private auth: AuthorizationService
  ) {
    this.auth.userLoggedOut$.subscribe(()=> this.clearPages());
   }

  public getPage(name: string): number | null {
    return this.pages[name] ? this.pages[name] : null;
  }

  public setPage(name: string, page: number): void {
    this.pages[name] = page;
  }

  public clearPages(): void {
    this.pages = {};
  }
}
