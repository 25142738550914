import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Directive({
  selector: '[appPositiveNumber]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PositiveNumberValidator,
      multi: true
    }
  ]
})
export class PositiveNumberValidator implements Validator {
  constructor() {}

  public validate(control: AbstractControl): ValidationErrors | null {
    let
      errors: ValidationErrors = {'positiveNumber': {value: control.value}},
      val: any = control.value;

    if(val == null || val === '') {
      return null;
    }
    else {
      return (val = +val), val && val > 0 ? null : errors;
    }
  }
}
